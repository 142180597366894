import React, { useEffect, useMemo, useState } from 'react'
import { Grid, InputAdornment, Theme } from '@mui/material'
import moment from 'moment'

/**
 * Components
 */
import FaCard from './_components/ActivityScheduleCard'
import MuiDatePicker from '../../_components/MuiDatePicker'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { generateStyle } from '../../_utils/DefaultStyle'
import DragableHorizontal from '../../_components/DragableHorizontal'
import LoadingScreen from '../../_components/LoadingScreen'
import TextFieldND from '../../_components/_newDesignComponents/_form/TextFieldND'
import { Search } from '@mui/icons-material'

/**
 * Icons
 */

export interface IActivity {
    activity: string
    apartment_name: string | React.ReactNode
    client_name: string
    created_by_name: string
    id: number
    notes: string
    time: string
    unit_code: string
}
export interface IData {
    activities: IActivity[]
    id: number
    name: string
    photo: string
    phone_number?: string
}

type RequestType = {
    label: string;
    value: string;
    key: string;
    [key: string]: any;
}

export const initialRequestType: RequestType[] = [
    { label: "Fixing and Cleaning", value: 'fixing_cleaning', key: 'fixing_cleaning' },
    { label: "Foto Unit", value: 'photo', key: 'photo' },
    { label: "Paket Perawatan Essensial", value: 'essential_pack', key: 'essential_pack' },
    { label: "Handover Checkin", value: "9", key: '9' },
    { label: "Handover Checkout", value: '2', key: '2' },
    { label: "QC Checkin", value: 'quality-control', key: 'quality-control' },
    { label: "QC listing", value: "quality-control-listing", key: 'quality-control-listing' },
    { label: "Maintenance", value: "3", key: '3' }
]

interface IState {
    search: string
    date: Date | null
}

export default function FaTeamSchedule() {
    const { Root, classes } = useStyles()
    const [state, setState] = useState<IState>({ search: '', date: new Date() })
    const [isLoading, setIsLoading] = useState(false)
    const [schedules, setSchedules] = useState<IData[]>([])

    const filteredItems = useMemo(() => {
        const inputValue = state.search.trim().toLowerCase()
        // Remove special chars
        const removeSpecialChar = inputValue.replace(/[^0-9a-zA-Z']+/gm, ' ');
        // Remove duplicate whitespace
        const escapedValue = removeSpecialChar.replace(/\s{2,}/gm, ' ');

        return schedules
            .filter((item: IData) => {
                if (item.name.toLowerCase().includes(escapedValue)) {
                    return true
                } else if (item.activities.filter(item => item.unit_code ? item.unit_code.toLowerCase().includes(escapedValue) : false).length) {
                    return true
                }

                return false
            })
    }, [state.search, schedules])

    const fetchData = (date: Date) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/schedule-overview`, {
            params: { date: moment(date).format('YYYY-MM-DD') }
        })
            .then(res => res.data)
            .then(res => res ? setSchedules(res) : null)
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target

        setState(prev => ({
            ...prev,
            search: value,
        }))
    }

    const onDateChange = (value: Date | null) => {
        setState(prev => ({
            ...prev,
            date: value,
        }))
    }

    useEffect(() => {
        if (state.date) {
            fetchData(state.date)
        }
    }, [state.date])

    return (
        <Root>
            <div className={classes.root}>
                <LoadingScreen open={isLoading} fullScreen />
                <div className='header'>
                    <Grid container>
                        <Grid item xs={6.5} display='flex' alignItems='center'>
                            {/* <Assignment htmlColor='#484848' sx={{ mr: 1.5 }} />
                        <Typography variant='h6' color='#484848'>
                            Activity Schedule
                        </Typography> */}
                        </Grid>
                        <Grid item xs={3} sx={{ pr: 2.5 }}>
                            <TextFieldND
                                value={state.search}
                                onChange={onSearchChange}
                                fullWidth
                                placeholder='Cari nama / Kode unit'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start' sx={{ fontSize: 17 }}>
                                            <Search htmlColor='#484848' fontSize='medium' />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <MuiDatePicker
                                onChange={onDateChange}
                                value={state.date}
                                newDesign
                            />
                        </Grid>
                    </Grid>
                </div>
                <DragableHorizontal className='content'>
                    {
                        filteredItems.length ?
                            filteredItems.map((data) => (
                                <FaCard key={data.id} data={data} />
                            ))
                            : isLoading ? <></> :
                            <div style={{margin: 20}}>
                                Tidak ada Schedule Hari ini
                            </div>
                    }
                </DragableHorizontal>
            </div>
        </Root >
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        margin: -24,
        height: 'calc(100vh - 64px)',
        // backgroundColor: '#F5F7FB',
        '& > .header': {
            // height: 55,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            display: 'flex',
            justifyContent: 'center',
        },
        '& > .content': {
            height: 'calc(100vh - 64px - 62px)',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            overflowY: 'hidden',
            overflowX: 'auto',
        },
    }

}), "Activity_Schedule")
