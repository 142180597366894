import React, { useState, useEffect, useContext } from 'react';
import { Chip, IconButton, TextField, Theme, Tooltip } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import { IChats } from '../../_components/WhatsappPreview';
import { IColumn } from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import WhatsappPreviewModal from '../../_components/_modal/WhatsappPreviewModal';
import ClosingModalDetailSell from '../_closing/_components/ClosingModalDetailSell';

/**
 * Icons
*/
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Check, Edit, Visibility } from '@mui/icons-material';

/**
 * Utils
*/
import { generateStyle } from '../../_utils/DefaultStyle';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns';
import DefaultAxios from '../../_utils/DefaultAxios';
import { initialChatsData } from '../_enquiry/EnquiryList';
import { generalErrorHandler, numberToCurrency, renderToastSuccess } from '../../_utils/Helper';


interface IProps {
    id?: string
}
interface IParams extends Record<string, string | undefined> {
    id?: string
}

const ClosingSellList = (props: IProps) => {
    const { Root } = useStyles()
    const permissions = useContext(PermissionContext)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams<IParams>();
    const [modalCode, setModalCode] = useState("");
    const [reDraw, setReDraw] = useState(0)
    const [selectedNotesRow, setSelectedNotesRow] = useState<null | any>(null)
    const [chatsData, setChatsData] = useState<{
        chats: IChats[],
        code: string | null,
        created_at: string
    }>(initialChatsData)

    useEffect(() => {
        if (params.code) {
            setModalCode(params.code);
        } else {
            setModalCode("");
        }
    }, [params.code]);

    const getColorCode = (status: string) => {
        switch (Number(status)) {
            case 0:
                return 'rgba(179, 173, 173, 0.87)';
            case 1:
                return 'black';
            case 2:
                return '#009be5';
            case 3:
                return 'red';
            case 4:
                return 'green';
            default:
                return '';
        }
    }

    const handleWhatsappPreviewOwner = (row: any) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead-owner`, { params: { id: row.enquiry_id } })
            .then(res => res.data)
            .then(res => {
                setChatsData({
                    chats: res,
                    code: row.code,
                    created_at: row.created_at
                })
            })
            .catch(err => generalErrorHandler(err))
    }

    const onNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setSelectedNotesRow((prev: any) => ({
            ...prev,
            admin_flipper_notes: value,
        }))
    }

    const saveNewNotes = (rows: any, setRows: any) => {
        if (!selectedNotesRow) return;

        const data = {
            id: selectedNotesRow.id,
            type: "closing",
            admin_flipper_notes: selectedNotesRow.admin_flipper_notes,
        }

        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/admin-flipper/notes`, data)
            .then(res => res.data)
            .then(() => {
                setSelectedNotesRow(null)

                renderToastSuccess('Berhasil mengubah notes')
                const newRows = rows.map((element: any) => {
                    if (+element.id === +data.id) element['notes'] = data.admin_flipper_notes;
                    return element
                })
                setReDraw(prev => prev + 1)
                setRows(newRows);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const columns: IColumn[] = [
        {
            name: 'first_payment_date',
            label: 'Tanggal Pembayaran Pertama',
            filterable: true,
            sortable: true,
            type: 'date',
            render: (data: string, row: any) => {
                if (data) {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{format(new Date(data), 'dd MMM yyyy')}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{format(new Date(data), 'dd MMM yyyy')}</div>);
                    }
                } else {
                    return "-";
                }
            }
        },
        ...(
            permissions['whatsapp.manage'] ?
                [{
                    name: 'enquiry_id',
                    label: 'Whatsapp Preview',
                    type: 'string',
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <>
                                    {row.unit_code &&
                                        <Tooltip title="Whatsapp Owner">
                                            <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreviewOwner(row)} color='primary'>
                                                <WhatsAppIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ),
        {
            name: 'code',
            label: 'Kode Transaksi',
            filterable: true,
            sortable: true,
            type: 'string',
            render: (data: string, row: any) => {
                if (data) {
                    return (
                        <Chip
                            variant="outlined"
                            label={row.code} onClick={() => {
                                setModalCode(row.code);
                                window.history.pushState('', '', `/closing/${row.code}`)
                            }}
                        />
                    )
                } else {
                    return "-";
                }
            }
        },
        {
            name: 'unit_code',
            label: 'Kode Unit',
            filterable: true,
            type: 'string',
            render: (data: string) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{data}</span>
                        <IconButton
                            href={`${process.env.REACT_APP_MAIN_URL}/${data}`}
                            target='_blank'
                            color='primary'
                        >
                            <Visibility />
                        </IconButton>
                    </div>
                );
            },
        },
        {
            name: 'apartment_name',
            label: 'Apartemen',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'unit_detail',
            label: 'Unit Detail',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'furnished',
            label: 'Furnish',
            filterable: true,
            type: 'string',
            options: [
                { key: '', value: 'All' },
                { key: 'full', value: 'Full Furnished' },
                { key: 'semi', value: 'Semi Furnished' },
                { key: 'non', value: 'Non Furnished' },
            ],
            render: (data: string) => {
                switch (data) {
                    case 'non':
                        return 'Non Furnished';
                    case 'semi':
                        return 'Semi Furnished';
                    case 'full':
                        return 'Full Furnished';
                    default:
                        return '';
                }
            }
        },
        {
            name: 'view_direction',
            label: 'View',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'unit_area',
            label: 'Unit Area',
            filterable: true,
            type: 'number',
            render: (data: number) => data || '-',
        },
        {
            name: 'area_name',
            label: 'Area',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'is_include_maintenance',
            label: 'Include Maintenance',
            filterable: true,
            sortable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                },
            ],
            render: (data: string, row: any) => {
                return data ? 'Yes' : 'No';
            }
        },
        {
            name: 'gmv_transaction',
            label: 'GMV',
            filterable: true,
            sortable: true,
            type: 'number',
            render: (data: string, row: any) => {
                if ([0, 1].includes(row.status)) {
                    return (<div style={{ color: getColorCode(row.status) }}>Rp. 0 ({numberToCurrency(data, 'Rp')})</div>);
                } else if (row.status === 3) {
                    return (<div style={{ color: getColorCode(row.status) }}><del>Rp. 0 ({numberToCurrency(data, 'Rp')})</del></div>);
                } else {
                    return (<div style={{ color: getColorCode(row.status) }}>{numberToCurrency(data, 'Rp')}</div>);
                }
            }
        },
        {
            name: 'start_date',
            label: 'Start Date',
            type: 'date',
            filterable: true,
            // defaultOption: `${format(dateStart, 'yyyy-MM-dd')} - ${format(dateEnd, 'yyyy-MM-dd')}`,
            render: (data: any, row: any) => {
                return format(new Date(data), 'dd MMM yyyy')
            }
        },
        {
            name: 'end_date',
            label: 'End Date',
            type: 'date',
            filterable: true,
            // defaultOption: `${format(dateStart, 'yyyy-MM-dd')} - ${format(dateEnd, 'yyyy-MM-dd')}`,
            render: (data: any, row: any) => {
                return format(new Date(data), 'dd MMM yyyy')
            }
        },
        {
            name: 'duration',
            label: 'Durasi',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'admin_flipper_notes',
            label: 'Remarks',
            filterable: true,
            columnStyle: {
                minWidth: 200,
            },
            render: (data: string, row: any, rows: any, index: number, setRows: any) => {
                return (
                    selectedNotesRow?.id === row.id ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                value={selectedNotesRow?.admin_flipper_notes || ''}
                                onChange={onNotesChange}
                                disabled={isLoading}
                                size='small'
                                variant='standard'
                                style={{
                                    maxWidth: 'calc(100% - 30px)',
                                }}
                                multiline
                            />
                            <IconButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveNewNotes(rows, setRows);
                                }}
                                size='small'
                                disabled={isLoading}
                            >
                                <Check fontSize='small' color='success' />
                            </IconButton>
                        </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {data || '-'}
                            </span>
                            {permissions['enquiry-buy.manage'] &&
                                <IconButton
                                    onClick={() => setSelectedNotesRow(row)}
                                    size='small'
                                    style={{ verticalAlign: 'top' }}
                                >
                                    <Edit fontSize='small' color='primary' />
                                </IconButton>
                            }
                        </div>
                );
            }
        },
    ]

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <DataTable
                reDraw={reDraw}
                url={`${process.env.REACT_APP_API_URL}/admin-flipper/closing`}
                columns={columns}
            />
            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(initialChatsData)
                }}
            />
            {modalCode !== "" ?
                <ClosingModalDetailSell code={modalCode}
                    open={modalCode !== ""}
                    permissions={permissions}
                    onClose={() => {
                        navigate(`/closing-sell`)
                        setModalCode("")
                        window.history.pushState('', '', `/closing-sell`)
                    }} /> :
                null
            }
        </Root>
    );
}

const useStyles = generateStyle((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
}), "ClosingSellList")

export default ClosingSellList;