import React, { useEffect, useReducer } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import TextArea from '../../../../_components/_form/TextArea';
import ButtonWithLoading from '../../../../_components/_form/ButtonWithLoading';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess } from '../../../../_utils/Helper';

interface IProps {
    id: number;
    open: boolean;
    isAvailable: number;
    onClose: Function;
    callBackSubmitSuccess?: Function;
    setIsLoading: Function;
    isLoading?: boolean
}

interface IAvailableState {
    date: Date | null;
    comment: string;
    endOfAvailability: boolean;
}

interface IAction {
    name: string;
    value: any;
    type: string;
}

const initialAvailableState: IAvailableState = {
    date: null,
    comment: '',
    endOfAvailability: false
}

const SwitchAvailableSellModal = ({ id, open, onClose, setIsLoading, callBackSubmitSuccess, isLoading }: IProps) => {
    const availableReducer = (state: IAvailableState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialAvailableState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IAvailableState };
        }

        return { ...state };
    };

    const [availableState, setAvailableState] = useReducer(availableReducer, initialAvailableState);

    const handleAvailableSubmit = () => {
        if (!availableState.comment) {
            Swal.fire({
                title: 'Validasi Error',
                text: "Silahkan isi komentar",
                icon: 'warning',
            });
            return;
        }
        setIsLoading(true);

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/switch-active-sell`, {
            id: id,
            comment: availableState.comment
        })
            .then((res: any) => {
                console.log(res, 'ini res');
                
                if (typeof callBackSubmitSuccess !== "undefined") {
                    callBackSubmitSuccess();
                }
                renderToastSuccess('Status Available Sell berhasil dirubah');
                onClose();
            })
            .catch((err: any) => {
                console.log(err, 'ini err');
                
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    useEffect(() => {
        return () => {
            setAvailableState({ name: '', value: null, type: 'RESET_ITEM' })
        }
    }, [open])

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>Isi Alasan</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <TextArea
                            label="Comment"
                            name="comment"
                            value={availableState.comment}
                            onChange={(e) => setAvailableState({ name: 'comment', value: e.target.value, type: 'SET_ITEM' })}
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWithLoading onClick={handleAvailableSubmit} color="primary" isLoading={isLoading}>
                    Submit
                </ButtonWithLoading>
            </DialogActions>
        </Dialog>

    )
}

export default SwitchAvailableSellModal;