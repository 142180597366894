import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Tabs,
    Tab,
    Theme,
    Typography,
    Paper,
    Grid,
    Tooltip,
    IconButton,
    Chip,
} from '@mui/material';
import Swal from 'sweetalert2';
import { Link as LinkDom, useNavigate } from 'react-router-dom';

/**
 * Components
 */
import ModalDetail from '../../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../../_components/_modalDetail/ModalSection';
import LoadingScreen from '../../../_components/LoadingScreen';
import NewCommentList from '../../../_components/NewCommentList';
import FileList from './FileList';
import TerminList from './TerminList';
import BankTable from '../../../_components/_dataTable/BankTable';

/**
 * Modals
 */
import HandOverCheckOutModal from './Modal/HandOverCheckOutModal';
import HandoverCheckinModal from './Modal/HandoverCheckInModal';
import VerifyClosingModal from './Modal/VerifyClosingModal';
import CompleteClosingModal from './Modal/CompleteClosingModal';
import QualityControlModal from './Modal/QualityControlModal';
import ChangeFirstPaymentDateModal from './ChangeFirstPaymentDateModal';
import DepositModal from './DepositModal';
import CustomerModal from '../../../_components/_modal/CustomerModal';
import OwnerModal from '../../../_components/_modal/OwnerModal';
import SendInquiryModal from '../../../_components/_modal/SendInquiryModal';
import ResetStatusModal from './ResetStatusModal';
import CancelClosingModal from './Modal/CancelClosingModal';
// import ContractStatusModal from './Modal/ContractStatusModal';
import UploadOwnerDocumentModal from './UploadOwnerDocumentModal';
import RevertClosingModal from './RevertClosingModal';
import ChangePicModal from './ChangePicModal';
import ClosingModalEdit from './ClosingModalEdit'
import HandoverList from './HandoverList';
import LogList from './LogList';
import LaporanCheckInList from './LaporanCheckInList';
import TicketSystemList from '../../_ticketSystem/TicketSystemList';
import { IMTO } from './MTOForm';
import PartialDocusignForm from './Modal/PartialDocusignForm';
import UnitActivityListModal from './Modal/UnitActivityListModal';
import TenatEditModal from './Modal/TenatEditModal';
import LandlordEditModal from './Modal/LandlordEditModal';
import DepositListModal from './Modal/DepositListModal';
import LogBroadcastList from './LogBroadcastList';
import NoteModal from './Modal/NoteModal';
import QiscusTemplateModal from './Modal/QiscusTemplateModal';
import AddCustomerModal from './Modal/AddCustomerModal';
import DeleteClosingModal from './Modal/DeleteClosingModal';
import ImagePreview from '../../../_components/ImagePreview';
import WhatsappPreviewModal from '../../../_components/_modal/WhatsappPreviewModal';
import ClosingContractModal from './Modal/ClosingContractModal';
import OrderJHSList from './OrderJHSList';
import GeneralAutocompleteModal from '../../../_components/_modal/GeneralAutocompleteModal';
import CheckoutReasonModal from './Modal/CheckoutReasonModal';

/**
 * Icons
 */
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChangeEnquiryModal from './Modal/ChangeEnquiryModal';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, numberToCurrency, dateFormat, renderWarningButton, renderSuccessButton } from '../../../_utils/Helper';
import TransactionList from './_transactionList/TransactionList';
import DepositTransactionList from './_transactionList/DepositTransactionList';
import { IChats } from '../../../_components/WhatsappPreview';
import ChangeAgentModal from './Modal/ChangeAgentModal';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    open: boolean;
    permissions: any;
    code: string;
    onClose: Function;
}

interface IDigitalContractData {
    is_english: boolean,
    custom_owner_email: string,
    custom_owner_deposit: number,
    custom_owner_deposit_percentage: number,
    custom_customer_email: string,
    custom_customer_deposit: number,
    is_contract_custom_termin: boolean,
    is_doc_owner_custom: boolean,
    is_doc_customer_custom: boolean,
    contract_custom_termins: [],
    owner_custom_doc_url: string,
    customer_custom_doc_url: string
}

interface ITimeline {
    datetime: string,
    comment: string
}

interface IApartment {
    id: number,
    assigned_property_manager_id: number,
    name: string,
    slug: string,
    code: string,
    area: string,
    area_slug: string,
    district_id: string,
    district: string,
    highlight: string,
    total_unit: number,
    floor: number,
    tower: number,
    developer: string,
    building_finished: string,
    highlight_sell: string,
    highlight_sell_en: string,
    certificate?: string,
    highlight_en: string,
    address: string,
    latitude: string,
    longitude: string,
    price_start: string,
    unit_count: number,
    star_count: number,
    lowest_studio_price_per_month: number,
    lowest_one_bedroom_price_per_month: number,
    lowest_two_bedrooms_price_per_month: number,
    lowest_three_bedrooms_price_per_month: number,
    is_featured: number,
    is_priority: number,
    is_active: number,
    is_handled_by_jendela: number,
    created_at: string,
    updated_at: string,
    created_by: string,
    modified_by: string,
    meta_title: string,
    meta_title_en: string,
    meta_title_sell: string,
    meta_title_sell_en: string,
    meta_keyword: string,
    meta_description: string,
    meta_description_en: string,
    meta_description_sell: string,
    meta_description_sell_en: string,
    is_free_sc: number,
    is_deposit_manual: number,
    service_charge: number,
    deposit_charge_studio: number,
    deposit_charge_1br: number,
    deposit_charge_2br: number,
    deposit_charge_3br: number,
    star_price_list: string,
    is_rented_guarantee: number,
    paragraph: string,
    paragraph_2: string,
    paragraph_en: string,
    paragraph_2_en: string
}

interface INewContract {
    id: number,
    closing_id: string,
    type: string,
    token: string,
    signature: string,
    latitude: string,
    longitude: string,
    is_signed_email_sent: number,
    signed_at: string,
    expired_at: string,
    created_at: string,
    updated_at: string
}

export interface IUnit {
    id: number,
    is_for_cobroke_closing: number,
    name: string,
    slug: string,
    code: string,
    highlight: string,
    highlight_sell: string,
    apartment_id: number,
    apartment_name: string,
    furnished: string,
    view_direction: string,
    unit_area: number,
    tower: string,
    display_price: number,
    assigned_to_advisor_at: string,
    last_reported_by_advisor_at: string,
    basic_price_1: number,
    basic_price_3: number,
    basic_price_6: number,
    basic_price_12: number,
    basic_price_sell: number,
    price_per_day: number,
    price_per_1_month_in_dollar: number,
    price_per_1_month: number,
    price_per_3_months: number,
    price_per_6_months: number,
    price_per_12_months: number,
    starlisting_prices: number,
    is_fill_web_price: number,
    hide_6_months_price: number,
    deposit_charge: number,
    service_charge_type: number,
    service_charge: number,
    bedroom: number,
    bathroom: number,
    maid_room: number,
    floor_level: number,
    unit_number: number,
    is_available: number,
    is_available_sell: number,
    is_active: number,
    is_paid_listing: number,
    signing_date: string,
    published_date: string,
    category: number,
    sorting_score: number,
    use_new_schema_star_listing: number,
    pic_unit: number,
    direct_contact_pic_id: string,
    is_monthly: number,
    rent_date: string,
    is_by_bot: string,
    updated_by_bot?: string,
    available_flag: number,
    primary_image: string,
    primary_image_thumbnail: string,
    primary_image_thumbnail_small: string,
    is_primary_image_space: number,
    created_at: string,
    updated_at: string,
    deleted_at: string,
    last_activated_at: string,
    assigned_advisor_id: number,
    created_by: number,
    modified_by: number,
    meta_title: string,
    meta_title_sell: string,
    meta_keyword: string,
    meta_description: string,
    meta_title_en: string,
    meta_description_en: string,
    pic_name: string,
    landlord_id: number,
    agent_id: number,
    pic_email: string,
    keterangan: string,
    is_owner: number,
    pic_handphone: number,
    is_sell: number,
    is_rent: number,
    is_dollar: number,
    is_negotiable: number,
    is_special_price: number,
    is_priority_sort: number,
    is_entrusted_key: number,
    entrusted_key_at?: string,
    is_eligible_owner_dashboard: number,
    key_holder: number,
    borrow_date: string,
    return_key_date: string,
    user_holder_id: number,
    certificate: number,
    selling_price: number,
    closing_on_days?: string,
    is_agreement_sent: number,
    is_agreement_finished: number,
    doc_token?: string,
    is_requested_change_availability: number,
    inventory_status: number,
    seekmi_account_id?: number,
    is_rented_guarantee: number,
    scrap_id?: number,
    apartment: IApartment,
    landlord: {
        id: number,
        is_cleaned: number,
        is_skipped: number,
        assigned_pic_id: number,
        name: string,
        email: string,
        phone: number,
        alternative_contact: number,
        birth_date: string,
        religion: string,
        nationality: string,
        nickname: string,
        apartments: string,
        units: string,
        password: string,
        photo: string,
        address: string,
        ktp_number: string,
        kk_number: string,
        ktp_filename: string,
        ktp_filepath: string,
        surat_kuasa_filename: string,
        surat_kuasa_filepath: string,
        listing_inventory_filename: string,
        listing_inventory_filepath: string,
        npwp_number: string,
        nik: string,
        bank_number: string,
        bank_type: number,
        bank_behalf_name: string,
        bank_branch?: number,
        remember_token?: string,
        profile: string,
        profile_last_author: number,
        profile_last_date: string,
        migration_notes: string,
        is_active: number,
        created_at: string,
        updated_at: string,
        is_duplicate_landlord: number,
        is_file_moved: number,
        temp_code: string,
        deleted_at: string,
        primary_bank: {
            id: number,
            landlord_id: number,
            bank_type_id: number,
            bank_type_temp: number,
            is_verified: number,
            is_primary: number,
            behalf_name: string,
            number: number,
            created_by: number,
            created_at: string,
            updated_at: string,
            deleted_at: string,
            bank_type: {
                id: number,
                swift_code: string,
                onebrick_code: string,
                name: string
            }
        },
        files: []
    },
    agent: string,
}

interface ICustomer {
    id: number,
    name: string,
    nationality: string,
    email: string,
    phone: number,
    alternative_contact: number,
    birth_date: string,
    religion: string,
    address: string,
    ktp_number: number,
    passport: number,
    bank_branch?: string,
    is_referral_email_sent: number,
    created_at: string,
    updated_at: string,
    bank_type: number,
    bank_account_number: number,
    bank_behalf_name: string,
    primary_bank: string,
    files: []
}

interface IExtendPic {
    id: number,
    name: string,
    email: string,
    area_id: number,
    pintu_user_id: number,
    notification_email: string,
    phone_number: number,
    phone_number_alternate: number,
    permissions?: number,
    is_active: number,
    is_paused: number,
    is_paused_by_bot: number,
    round_robin_global: number,
    last_login: string,
    photo: string,
    proactive_score: number,
    helpful_score: number,
    notes: string,
    created_at: string,
    updated_at: string,
    join_date: string,
    notification_exist: number,
    filename: string,
    filepath: string
}

interface IDetail {
    id: string,
    enquiry_id: number | null,
    enquiry_code: string | null,
    wa_id: number | null,
    old_closing_id: string,
    closing_calculation_id: number,
    cobroke_type: number,
    checkout_reason_label: string,
    checkout_proof_file_url: string,
    code: string,
    extend_from?: number,
    extend_status: number,
    follow_up_status?: number,
    extend_pic_id: number,
    extend_pic_placement: string | null,
    is_daily: number,
    is_sell: number,
    is_star: number,
    is_early_checkout: number,
    customer_id: number,
    creator_id: number,
    advisor_id: number,
    agent_id?: number,
    listing_id?: number,
    unit_id: string,
    payment_type: string,
    group_termin_id?: number,
    start_date: string,
    end_date: string,
    duration: number,
    old_duration: number,
    holded_deposit_owner: number,
    holded_deposit_office: number,
    gmv_transaction: number,
    gmv_financing: string,
    revenue_financing: number,
    cogs_financing: number,
    gmv_property_management?: number,
    revenue_property_management?: number,
    revenue_transaction: number,
    discount_revenue: number,
    cobroke_fee: number,
    revenue_jendela: number,
    referral_commission: number,
    referral_account_id: number,
    referral_enquiry_id: number,
    owner_referral_commission?: number,
    client_referral_commission?: number,
    revenue_referral_wings?: number,
    payment_per_month_mto: IMTO[],
    additional_data: {
        custom_commission_percentage: number
    },
    digital_contract_data: IDigitalContractData,
    listing_inventories?: number,
    is_digital_contract: number,
    is_inhouse_contract: number,
    digital_contract_status: number,
    digital_contract_token: string,
    notes?: string,
    timeline: ITimeline[]
    due_date_follow_up?: string,
    finished_checkout_at?: string,
    created_at: string,
    updated_at: string,
    deleted_at?: string,
    deleted_reason?: string,
    payment_to_financing_service: number,
    status: number,
    handover_check_in_activity_id?: number,
    canceled_reason?: string,
    completed_by?: string,
    finished_payment_date?: string,
    completed_at?: string,
    dpp_value: number,
    ppn_value: number,
    canceled_by?: string,
    canceled_at?: string,
    verified_by?: string,
    first_payment_date?: string,
    verified_at?: string,
    confirmed_by?: string,
    confirmed_at?: string,
    is_include_maintenance: number,
    is_include_utility_billing: number,
    is_include_other_billing: number,
    is_extra_package: number,
    is_quality_control_finished: number,
    basic_price: number,
    stop_edit_label: string,
    activity_qc: {
        id: number,
        name: string,
    },
    activity_co: {
        id: number,
        name: string,
    },
    extend_will_be_termin: number,
    unit_closing_breakdown_id: string,
    owner_contract: {
        id: number,
        signed_at: string
    },
    tenant_contract: {
        id: number,
        signed_at: number
    },
    termin_code_label: string,
    status_label: string,
    listing_information: string,
    owner_id_label: number,
    owner_name_label: string,
    owner_phone_label: string,
    owner_bank_information_label: string,
    owner_ktp_url: string,
    agent_name_label: string,
    agent_phone_label: string,
    agent_office_label: string,
    created_by_label: string,
    advisor_name_label: string,
    tenant_name_label: string,
    tenant_phone_label: string,
    tenant_nationality_label: string,
    tenant_bank_information_label: string,
    tenant_ktp_url: string,
    digital_contract: string,
    docusign_status: string,
    extend_status_label: string,
    extend_pic_label: string,
    confirmed_by_label: string,
    confirmed_at_label: string,
    verified_by_label: string,
    verified_at_label: string,
    completed_by_label: string,
    completed_at_label: string,
    canceled_by_label: string,
    canceled_at_label: string,
    cobroke_label: string,
    owner_contract_status: number,
    owner_contract_link: string,
    tenant_contract_status: number,
    tenant_contract_link: string,
    enquiry_referral: string,
    termin_group?: string,
    unit: IUnit,
    agent: string,
    pic_landlord_id: number,
    pic_landlord_name: string,
    pic_landlord_phone: string,
    customer: ICustomer,
    extend_pic: IExtendPic,
    confirmer: string,
    verifier: string,
    canceler: string,
    new_contracts: INewContract[],
    can_extend_termin: number,
    extended_closing_code?: string,
    apartment_name?: string,
    transaction_amount: string,
    deposit_amount: number,
    deposit_type: number,
    commission: number,
    referral_account?: {
        name: string,
        email: string,
        phone: number,
        bank_number: number,
        bank_type: number,
        bank_behalf_name: string,
    },
    extend_is_digital_contract?: number,
    owner_name?: string,
    tenant_name?: string,
    old_closing_code?: string,
    owner_price: number,
    customer_price: number
    agent_cobroke_internal_name_label: string
    activity_ho_checkin: {
        id: number,
        name: string
    } | null
    telesales_name_label: string
}

const useStyles = generateStyle((theme: Theme) => ({
    cancel_overlay: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        zIndex: 1100,
        marginTop: "64px"
    },
    cancel_card: {
        textAlign: "center",
        width: "50%",
        marginLeft: "25%",
        marginTop: "10%"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    h6_title: {
        color: theme.palette.primary.main,
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        overflow: 'auto',
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
    extendActionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    },
    footer: {
        display: 'flex',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
}), "ClosingModal_Detail"
)

const initialDetailValue = {
    id: "",
    enquiry_code: null,
    wa_id: null,
    old_closing_id: "",
    closing_calculation_id: 0,
    cobroke_type: 0,
    code: "",
    checkout_proof_file_url: '',
    checkout_reason_label: '',
    extend_from: 0,
    extend_status: 0,
    follow_up_status: 0,
    extend_pic_id: 0,
    extend_pic_placement: null,
    is_daily: 0,
    is_sell: 0,
    is_star: 0,
    is_early_checkout: 0,
    customer_id: 0,
    creator_id: 0,
    advisor_id: 0,
    agent_id: 0,
    listing_id: 0,
    unit_id: "",
    enquiry_id: 0,
    payment_type: "",
    group_termin_id: 0,
    start_date: "",
    end_date: "",
    duration: 0,
    old_duration: 0,
    holded_deposit_owner: 0,
    holded_deposit_office: 0,
    gmv_transaction: 0,
    gmv_financing: "",
    revenue_financing: 0,
    cogs_financing: 0,
    gmv_property_management: 0,
    revenue_property_management: 0,
    revenue_transaction: 0,
    discount_revenue: 0,
    cobroke_fee: 0,
    revenue_jendela: 0,
    referral_commission: 0,
    referral_account_id: 0,
    referral_enquiry_id: 0,
    owner_referral_commission: 0,
    client_referral_commission: 0,
    owner_price: 0,
    revenue_referral_wings: 0,
    payment_per_month_mto: [] as IMTO[],
    additional_data: {
        custom_commission_percentage: 0
    },
    digital_contract_data: {} as IDigitalContractData,
    listing_inventories: 0,
    is_digital_contract: 0,
    is_inhouse_contract: 0,
    digital_contract_status: 0,
    digital_contract_token: "",
    notes: "",
    timeline: [] as ITimeline[],
    due_date_follow_up: "",
    finished_checkout_at: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    deleted_reason: "",
    payment_to_financing_service: 0,
    status: 0,
    handover_check_in_activity_id: 0,
    canceled_reason: "",
    completed_by: "",
    finished_payment_date: "",
    completed_at: "",
    dpp_value: 0,
    ppn_value: 0,
    canceled_by: "",
    canceled_at: "",
    verified_by: "",
    first_payment_date: "",
    verified_at: "",
    confirmed_by: "",
    confirmed_at: "",
    is_include_maintenance: 0,
    is_include_utility_billing: 0,
    is_include_other_billing: 0,
    is_extra_package: 0,
    is_quality_control_finished: 0,
    basic_price: 0,
    stop_edit_label: "",
    transaction_amount: "",
    activity_qc: {
        id: 0,
        name: ""
    },
    activity_co: {
        id: 0,
        name: ""
    },
    extend_will_be_termin: 0,
    unit_closing_breakdown_id: "",
    owner_contract: {
        id: 0,
        signed_at: ""
    },
    tenant_contract: {
        id: 0,
        signed_at: 0
    },
    termin_code_label: "",
    status_label: "",
    listing_information: "",
    owner_id_label: 0,
    owner_name_label: "",
    owner_phone_label: "",
    owner_bank_information_label: "",
    owner_ktp_url: "",
    agent_name_label: "",
    agent_phone_label: "",
    pic_landlord_id: 0,
    pic_landlord_name: "",
    pic_landlord_phone: "",
    agent_office_label: "",
    created_by_label: "",
    advisor_name_label: "",
    tenant_name_label: "",
    tenant_phone_label: "",
    tenant_nationality_label: "",
    tenant_bank_information_label: "",
    tenant_ktp_url: "",
    digital_contract: "",
    docusign_status: "",
    extend_status_label: "",
    extend_pic_label: "",
    confirmed_by_label: "",
    confirmed_at_label: "",
    verified_by_label: "",
    verified_at_label: "",
    completed_by_label: "",
    completed_at_label: "",
    canceled_by_label: "",
    canceled_at_label: "",
    cobroke_label: "",
    owner_contract_status: 0,
    owner_contract_link: "",
    tenant_contract_status: 0,
    tenant_contract_link: "",
    enquiry_referral: "",
    termin_group: "",
    unit: {} as IUnit,
    agent: "",
    customer: {} as ICustomer,
    extend_pic: {} as IExtendPic,
    confirmer: "",
    verifier: "",
    canceler: "",
    new_contracts: [] as INewContract[],
    can_extend_termin: 0,
    commission: 0,
    deposit_amount: 0,
    deposit_type: 0,
    customer_price: 0,
    agent_cobroke_internal_name_label: '',
    activity_ho_checkin: null,
    telesales_name_label: '',
}

export interface IQiscusData {
    closing_id: string,
    type: string,
    data_id: string,
    phone: string
}

const ClosingModalDetail = (props: Props) => {
    const { permissions, code } = props;
    const navigate = useNavigate();
    const { Root, classes } = useStyles();

    const inputCancelReason = useRef<HTMLInputElement>(null);
    const inputRevertReason = useRef<HTMLInputElement>(null);
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFooterLoading, setIsFooterLoading] = useState(false);
    const [footer, setFooter] = useState<any>(null);
    const [detail, setDetail] = useState<IDetail>(initialDetailValue);
    const [extendStatus, setExtendStatus] = useState(detail?.extend_status as number);
    const [modalEditOldState, setModalEditOldState] = useState(false);
    const [tenantEditModal, setTenantEditModal] = useState(false)
    const [landlordEditModal, setLandlordEditModal] = useState(false)
    const [imagePreview, setImagePreview] = useState('')

    const [qiscusData, setQiscusData] = useState<IQiscusData | null>(null)

    const handleOpenEditOld = () => setModalEditOldState(true);
    const handleCloseEditOld = () => setModalEditOldState(false);

    const [chats, setChats] = useState<IChats[]>([])
    const [chatCode, setChatCode] = useState<string | null>(null)

    const [depositTransactionRefreshFlag, setDepositTransactionRefreshFlag] = useState(0)

    /**
     * Modal State
     * 0 - No Modal
     * 1 - Customer
     * 2 - Owner
     * 3 - Deposit
     * 4 - Handover
     * 5 - Modal Extend Termin
     * 6 - Send Inquiry
     * 7 - Cancel Closing Modal
     * 8 - Verify Closing Modal
     * 9 - Modal Upload Document Owner
     * 10 - Docusign Status Modal
     * 11 - Void Docusign Modal
     * 12 - Complete Closing Modal
     * 13 - Change First Payment Date Modal
     * 14 - Handoverk Check-in Modal
     * 15 - Change PIC
     * 16 - Reset Status (Extend Status)
     * 17 - Revert Status (Status)
     * 18 - Partial Docusign Form
     */
    const [modalState, setModalState] = useState<string | number>(0);

    useEffect(() => {
        if (code) {
            fetchDetail();
        }
        // eslint-disable-next-line
    }, [modalEditOldState, code]);

    useEffect(() => {
        loadFooter();
        // eslint-disable-next-line
    }, [activeTab, detail?.id]);

    useEffect(() => {
        const oldPopState = window.onpopstate

        window.onpopstate = (e: any) => {
            props.onClose();
        }

        return () => {
            window.onpopstate = oldPopState
        }
        // eslint-disable-next-line
    }, [])


    const fetchDetail = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/new-closing/code/${code}`)
            .then(res => {
                setDetail(res.data);
                setExtendStatus(Number(res.data.extend_status));
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const getWarningOnChangeExtend = (changed_status: number) => {
        if (extendStatus === 7 && changed_status === 8) {
            return "Anda akan mengganti Konfirm Checkout secara Manual. Apakah anda yakin?";
        } else if (changed_status === 999) {
            return "Anda akan membuat Extend SECARA LANGSUNG. Gunakan menu ini hanya bila anda yakin ingin meng-extend secara langsung. Lanjutkan?";
        } else {
            return "Apakah anda yakin ingin mengganti status ini?";
        }
    }

    const handleActionChangeExtend = (value: number) => {
        if (detail.extend_will_be_termin && !detail.can_extend_termin) {
            Swal.fire({
                text: 'Harap lengkapi informasi termin terlebih dahulu',
                icon: 'info',
            })
        } else {
            renderWarningButton(getWarningOnChangeExtend(value))
                .then((result) => {
                    if (result.value) {
                        switch (value) {
                            case 999:
                                navigate(`/manage-extend/${detail.code}?is_force=1`);
                                break;
                            case 6:
                                navigate(`/manage-extend/${detail.code}`);
                                break;
                            case 7:
                                handleHandover();
                                break;
                            case 12:
                                setModalState(3);
                                break;
                            default:
                                setIsFooterLoading(true);
                                DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/new-closing/change-extend-status/${detail.id}`, { extend_status: value })
                                    .then(response => {
                                        if (response.data.updated) {
                                            Swal.fire({
                                                html: "Data Extend Updated",
                                                icon: 'info',
                                                showCloseButton: true,
                                                onClose: () => {
                                                    setIsFooterLoading(false);
                                                    fetchDetail();
                                                }
                                            })
                                        }
                                    })
                                    .catch(error => {
                                        alert('Kesalahan server, harap hubungi admin')
                                        setIsFooterLoading(false);
                                    });
                                break;
                        }
                    }
                })
        }
    }

    const loadFooter = () => {
        let url: string = '';

        switch (activeTab) {
            case 0:
                url = `${process.env.REACT_APP_API_URL}/comment/closing/${detail.id}`;
                break;
            case 2:
                url = `${process.env.REACT_APP_API_URL}/unit-closing-breakdown/${detail.id}/get-images`;
                break;
            case 3:
                url = `${process.env.REACT_APP_API_URL}/new-closing/${detail.id}/termin`;
                break;
            case 4:
                url = `${process.env.REACT_APP_API_URL}/new-closing/file/${detail.id}`;
                break;
            case 5:
                url = `${process.env.REACT_APP_API_URL}/new-closing/checkin-report`;
                break;
            case 6:
                url = `${process.env.REACT_APP_API_URL}/new-closing/${detail.id}/log`;
                break;
            case 20:
                setFooter(
                    <div style={{ width: '100%', flex: 1 }}>
                        <br></br>
                        <TicketSystemList
                            closing_id={detail.id}
                            closing_code={detail.code}
                        />
                    </div>
                )
                setIsFooterLoading(false);
                return;
            case 21:
                setFooter(
                    <div style={{ width: '100%' }}>
                        <br></br>
                        <BankTable
                            id={detail.id}
                            component="new-closing"
                            withoutAction
                        />
                    </div>
                )
                setIsFooterLoading(false);
                return
            case 23:
                url = `${process.env.REACT_APP_API_URL}/qiscus-broadcast/logs`;
                break;
            case 24:
                url = `${process.env.REACT_APP_API_URL}/new-closing/order-jhs-log`;
                break;
            default:
                break;
        }

        if (detail.id && activeTab === 1) {
            setFooter(
                <div style={{ display: "block" }}>
                    {detail.timeline !== null &&
                        detail.timeline.map((data: ITimeline, key: number) => {
                            return (
                                <p>
                                    <Typography variant="subtitle1">
                                        {data.datetime} - {data.comment}
                                    </Typography>
                                </p>
                            )
                        })
                    }
                    {detail.timeline === null && <p><strong>No Logs</strong></p>}
                </div>
            )
            return;
        }

        if (!url || !detail.id) {
            return;
        }

        setIsFooterLoading(true);
        DefaultAxios
            .get(url, {
                params: {
                    closing_id: activeTab === 5 || activeTab === 20 || activeTab === 23 ? detail.id : null,
                    id: activeTab === 24 ? detail.id : null,
                }
            })
            .then(res => {
                switch (activeTab) {
                    case 0:
                        setFooter(
                            <NewCommentList
                                id={detail.id}
                                type="closing"
                            />
                        )
                        break;
                    case 2:
                        setFooter(
                            <HandoverList
                                unitClosingBreakdownId={detail.unit_closing_breakdown_id || ''}
                                files={res.data}
                                onUploadFile={handleUploadFileHandover}
                            />
                        )
                        break;
                    case 3:
                        setFooter(
                            <TerminList
                                fetchDetail={fetchDetail}
                                closing_id={detail.id}
                                closing_code={detail.code}
                            />
                        )
                        break;
                    case 4:
                        setFooter(
                            <FileList
                                closing_id={detail.id}
                                permissions={permissions}
                                files={res.data}
                                onUploadFile={handleUploadFile}
                                onDeleteFile={() => loadFooter()}
                            />
                        )
                        break;
                    case 5:
                        setFooter(
                            <LaporanCheckInList
                                closing_id={detail.id}
                                permissions={permissions}
                                files={res.data}
                                onUploadFile={handleUploadCheckInFile}
                                onDeleteFile={() => loadFooter()}
                                setIsLoading={setIsLoading}
                            />
                        )
                        break
                    case 6:
                        setFooter(
                            <LogList
                                closingId={detail.id}
                                logs={res.data}
                            />
                        )
                        break;
                    case 20:

                        break;
                    case 23:
                        setFooter(
                            <LogBroadcastList
                                closingId={detail.id}
                                logs={res.data}
                            />
                        )
                        break;
                    case 24:
                        setFooter(
                            <OrderJHSList
                                closingId={detail.id}
                                orders={res.data}
                            />
                        )
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }

    const handleUploadFile = (fd: FormData) => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/new-closing/file/${detail.id}`, fd)
            .then(res => {
                Swal.fire({
                    title: "Upload berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadFooter();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleUploadCheckInFile = (fd: FormData) => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/new-closing/checkin-report`, fd)
            .then(res => {
                Swal.fire({
                    title: "Upload berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadFooter();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleUploadFileHandover = (fd: FormData) => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/unit-closing-breakdown-list`, fd)
            .then(res => {
                Swal.fire({
                    title: "Upload berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadFooter();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const openModalConfirmCancel = () => {
        renderWarningButton('Apakah anda yakin ingin mencancel closing?', 'Cancel Closing')
            .then((result) => {
                if (result.value) {
                    setModalState(7);
                }
            })
    }

    const handleCancelClosing = () => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/new-closing/${detail.id}/cancel`, { reason: inputCancelReason!.current!.value })
            .then(res => {
                Swal.fire({
                    title: "Closing berhasil di-cancel",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        fetchDetail();
                        setModalState(0);
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleRevertStatus = () => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/new-closing/${detail.id}/revert-status`, { reason: inputRevertReason!.current!.value })
            .then(res => {
                Swal.fire({
                    title: "Closing berhasil di-revert",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        fetchDetail();
                        loadFooter();
                        setModalState(0);
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleDeleteActivityQualityControl = () => {
        renderWarningButton('Apakah anda yakin ingin menghapus Activity Quality Control Ini?', 'Delete Activity Quality Control')
            .then((result) => {
                if (result.value) {
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/closing-activity/quality-control`, { data: { closing_id: detail.id } })
                        .then(res => {
                            fetchDetail();
                            Swal.fire({
                                title: 'Activity Deleted',
                                text: "Activity berhasil dihapus",
                                icon: 'success',
                            })
                        })
                }
            })
    }

    const handleDeleteActivityHandover = () => {
        renderWarningButton('Apakah anda yakin ingin menghapus Handover Ini?', 'Delete Activity Handover')
            .then((result) => {
                if (result.value) {
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/closing-activity/handover-checkin`, { data: { closing_id: detail.id } })
                        .then(res => {
                            fetchDetail();
                            Swal.fire({
                                title: 'Handover Deleted',
                                text: "Handover berhasil dihapus",
                                icon: 'success',
                            })
                        })
                }
            })
    }

    const handleDeleteActivityHandoverCheckout = () => {
        renderWarningButton('Apakah anda yakin ingin menghapus PIC Handover Checkout Ini?', 'Delete Activity Handover')
            .then((result) => {
                if (result.value) {
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/delete-handover-checkout`, { closing_id: detail.id })
                        .then(res => {
                            fetchDetail();
                            Swal.fire({
                                title: 'Handover Deleted',
                                text: "PIC Handover Checkout berhasil dihapus",
                                icon: 'success',
                            })
                        })
                }
            })
    }

    const getStatusContract = (status: number) => {
        let label = "";
        let color: 'default' | 'primary' | 'success' | 'info' = "default";
        switch (status) {
            case 0:
                label = 'Not Sent';
                break;
            case 1:
                label = 'Sent';
                color = 'primary';
                break;
            case 2:
                label = 'Signed';
                color = 'success';
                break;
            case 3:
                label = 'On Process';
                color = 'info';
                break;
            default:
                label = '[UNDEFINED]';
                break;
        }

        return (
            <Chip style={{ marginRight: 12 }} color={color} size='small' label={label} />
        )
    }

    // const handleConfirmedClosing = () => {
    //     Swal.fire({
    //         title: 'Konfirmasi Closing oleh Tim Operation',
    //         text: "Apakah anda yakin closing ini telah ditandatangan dan seluruh datanya sudah benar?",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ya'
    //     }).then((result) => {
    //         if (result.value) {
    //             setIsFooterLoading(true);
    //             DefaultAxios
    //                 .post(`${process.env.REACT_APP_API_URL}/new-closing/${detail.id}/confirm`)
    //                 .then(res => {
    //                     Swal.fire({
    //                         title: "Closing berhasil di-confirm",
    //                         icon: 'success',
    //                         timer: 1000
    //                     })
    //                         .then(() => {
    //                             fetchDetail();
    //                         })
    //                 })
    //                 .catch(error => {
    //                     generalErrorHandler(error)
    //                 })
    //                 .finally(() => {
    //                     setIsFooterLoading(false);
    //                 })
    //         }
    //     })
    // }

    const handleVerifyClosing = () => {
        setModalState(8)
    }

    const handleCompleteClosing = () => {
        setModalState(12);
    }

    const handleChangeExtendChild = (status: any) => {
        setExtendStatus(Number(status));
    }

    const handleHandover = () => {
        setModalState(4);
    }

    const handleNoteModal = () => {
        setModalState(21)
    }

    const handleChangeCheckoutReason = () => {
        setModalState(28)
    }

    const handleOpenQiscus = (phone: string, type: string, data_id: string) => {
        setQiscusData({
            phone,
            type,
            data_id,
            closing_id: detail.id
        })
        setModalState(22)
    }

    const renderPhone = (phone: string, type: string, dataId: string) => {
        if (phone && phone !== "-" && phone !== "[UNDEFINED]") {
            return (
                <>
                    HP: {phone}
                    <IconButton href={`tel:${phone}`} size="small" style={{ marginRight: "5px" }}>
                        <PhoneIcon />
                    </IconButton>
                    {
                        type ?
                            <IconButton size='small' onClick={() => handleOpenQiscus(phone, type, dataId)}>
                                <WhatsAppIcon />
                            </IconButton>
                            : null
                    }
                    {/* <IconButton href={`https://wa.me/${phone}`} size="small" target="_blank" rel="noopener noreferrer">
                        <WhatsAppIcon />
                    </IconButton> */}
                </>
            )
        } else {
            return `HP: -`;
        }
    }

    const getExtendChip = () => {
        let extendLabel = "[UNDEFINED]";
        let chipColor: "primary" | "secondary" | "default" = "default";
        switch (extendStatus) {
            case 0:
                extendLabel = "Sedang Sewa";
                break;
            case 1:
                extendLabel = "Konfirmasi Extend (Belum Bayar)";
                break;
            case 6:
                extendLabel = "Extend Dibayar (Menunggu Kontrak di ttd.)"
                break;
            case 7:
                extendLabel = "Menunggu konfirm proses checkout selesai dari saber";
                break;
            case 8:
                extendLabel = "Sudah Checkout";
                break;
            case 9:
                extendLabel = "Settled (Extend Selesai)";
                chipColor = "primary";
                break;
            case 10:
                extendLabel = "Settled (Deposit Dikembalikan)";
                chipColor = "primary";
                break;
            case 11:
                extendLabel = "Processing Deposit";
                break;
            case 12:
                extendLabel = "Menunggu Konfirm Finance";
                break;
        }

        if (chipColor === "default") {
            return <Chip label={extendLabel} />
        } else {
            return <Chip color={chipColor} label={extendLabel} />
        }
    }

    const getDepositLabel = (type: number) => {
        let deposit_text = '';
        switch (type) {
            case 0:
                deposit_text = 'Full Owner';
                break;
            case 1:
                deposit_text = '50% Jendela 50% Owner';
                break;
            case 2:
                deposit_text = 'Full Jendela';
                break;
            case 3:
                deposit_text = '50% Jendela 50% Agent';
                break;
            case 4:
                deposit_text = 'Full Agent';
                break;
            default:
                deposit_text = 'undefined'
                break;
        }
        return deposit_text;
    }

    const handleConvert = () => {
        renderWarningButton('Apakah anda yakin ingin meng-convert closingan ini ke New Closing?"', 'Convert Closing')
            .then(res => {
                if (res.value) {
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/new-closing/convert-old-closing`, { code: detail.code })
                        .then(res => {
                            Swal.fire({
                                title: "Closing berhasil di-convert",
                                text: "Harap pastikan kembali datanya sudah benar",
                                icon: 'success',
                            })
                                .then(res => {
                                    fetchDetail();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                }
            })
    }

    const handleWhatsappPreview = (id: number) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead`, { params: { id: id } })
            .then(res => res.data)
            .then(res => {
                setChats(res)
                setChatCode(detail.enquiry_code || null)
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleWhatsappPreviewOwner = (id: number) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead-owner`, { params: { id: id } })
            .then(res => res.data)
            .then(res => {
                setChats(res)
                setChatCode(detail.enquiry_code || null)
            })
            .catch(err => generalErrorHandler(err))
    }

    const changeCustomerClosing = (closingId: number | string, customerId: number) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/change-customer`, {
            id: closingId,
            customer_id: customerId
        })
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Customer Closing Changed!')
                setModalState(0)
                fetchDetail()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const generateBasicInformation = () => {
        let listDetail: Array<any> = [];

        if (detail.old_closing_id !== null && detail.status !== 3) {
            listDetail = listDetail.concat([
                detail.extended_closing_code &&
                ['Extend From', (
                    <LinkDom to={{ pathname: `/closing/${detail.extended_closing_code}` }}>
                        {detail.extended_closing_code}
                    </LinkDom>
                )]
                ,
                detail.old_closing_code &&
                [
                    'Old Closing Code',
                    (
                        <a href={`${process.env.REACT_APP_MAIN_URL}/admin-cms/closing?c=${detail.old_closing_code}`} target="_blank" rel="noopener noreferrer">
                            {detail.old_closing_code}
                        </a>
                    )]
                ,
                [`Jenis Transaksi`, detail.is_sell ? 'Jual' : 'Sewa'],
                [3, 4].includes(detail.cobroke_type) === false && detail.is_sell === 0 && [
                    'Periode Sewa',
                    `${dateFormat(detail.start_date, 'DD MMM YYYY')} - ${dateFormat(detail.end_date, 'DD MMM YYYY')} (${detail.duration} hari)`
                ],
                [
                    (
                        <>
                            Informasi Listing
                            <IconButton>
                                <DescriptionIcon />
                            </IconButton>
                        </>
                    )
                    , detail.listing_information]
            ]);
        }
        if (detail.old_closing_id === null) {
            listDetail = listDetail.concat([
                ...(detail.extended_closing_code ?
                    [[
                        `Extended From`,
                        <a href={`/closing/${detail.extended_closing_code}`} target="_blank" rel="noopener noreferrer">{detail.extended_closing_code}</a>
                    ]] : []),
                [(
                    <>
                        Kode Enquiry
                        {
                            (!permissions['closing.force-change-enquiry'] && (permissions['closing.change-enquiry'] && detail.status !== 4)) ?
                                <IconButton
                                    color='primary'
                                    onClick={() => setModalState(26)}
                                >
                                    <ChangeCircleIcon />
                                </IconButton>
                                : null
                        }
                    </>
                ), <>
                    {detail.enquiry_code}
                    {(permissions['whatsapp.manage'] && detail.wa_id !== null) &&
                        <>
                            <Tooltip title="Whatsapp Client">
                                <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(Number(detail.enquiry_id))} color='primary'>
                                    <WhatsAppIcon />
                                </IconButton>
                            </Tooltip>

                            {detail.unit_id &&
                                <Tooltip title="Whatsapp Owner">
                                    <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreviewOwner(Number(detail.enquiry_id))} color='primary'>
                                        <WhatsAppIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    }
                </>],
                [`Jenis Transaksi`, detail.is_sell ? 'Jual' : 'Sewa'],
                [`Metode Pembayaran`, detail.payment_type],
                typeof detail.apartment_name !== 'undefined' && [`Nama Apartemen`, detail.apartment_name],
                detail.cobroke_type !== 0 && [`Cobroke Type`, detail.cobroke_label],
                [
                    (
                        <>
                            Informasi Listing
                            <IconButton
                                color='primary'
                                onClick={() => handleNoteModal()}
                            >
                                <DescriptionIcon />
                            </IconButton>
                        </>
                    )
                    , detail.listing_information],
                [3, 4].includes(detail.cobroke_type) === false && detail.is_sell === 0 && [`Periode Sewa`, `${dateFormat(detail.start_date, 'DD MMM YYYY')} - ${dateFormat(detail.end_date, 'DD MMM YYYY')} (${detail.duration} hari)`],
                [`Tanggal Pembayaran Pertama`, detail.first_payment_date ? dateFormat(detail.first_payment_date, 'DD MMM YYYY') : "-"],
                [`Tanggal Pelunasan Closing`, detail.finished_payment_date ? dateFormat(detail.finished_payment_date, 'DD MMM YYYY') : "-"],
                [`Kunci sudah di Handover`, detail.handover_check_in_activity_id ? "Ya" : "Tidak"],
            ])
        }
        return listDetail;
    }

    const generateTransactionInformation = () => {
        if (detail.old_closing_id !== null) {
            return [
                [`Transaction Amount`, numberToCurrency(detail.transaction_amount, 'Rp.')],
                [`Basic Price`, `${numberToCurrency(detail.basic_price, 'Rp.')} [NOTE: Bila Basic KOSONG Price Kemungkinan Closing menggunakan perhitungan lama (tanpa basic price)]`],
                [`Komisi Amount`, numberToCurrency(detail.commission, 'Rp.')],
                [`Deposit`, `${numberToCurrency(detail.deposit_amount, 'Rp.')} (${getDepositLabel(detail.deposit_type)})`],
                detail.is_star !== 0 && [`Owner Price`, numberToCurrency(detail.owner_price, 'Rp.')],
                detail.is_star !== 0 && [`Customer Price`, numberToCurrency(detail.customer_price, 'Rp.')],
            ];
        } else {
            let array: Array<any> = [];

            array = array.concat([
                [`Deposit Owner`, typeof detail.holded_deposit_owner !== 'undefined' && detail.holded_deposit_owner ? `${numberToCurrency(detail.holded_deposit_owner, 'Rp.')}` : "-"],
                [`Deposit Jendela`, typeof detail.holded_deposit_office !== 'undefined' && detail.holded_deposit_office ? `${numberToCurrency(detail.holded_deposit_office, 'Rp.')}` : "-"]
            ])

            /**
             * Star Listing Not Used anymore
             */
            // if (detail.is_star) {
            if (false) {
                // if (detail.payment_type === "cash") {
                //     array.push(`GMV Property Management: ` + numberToCurrency(detail.gmv_property_management, 'Rp.'));
                // } else if (['installment-blibli', 'installment-modalku'].includes(detail.payment_type)) {
                //     array.push(
                //         <>
                //             GMV:
                //             {generateList([
                //                 `GMV Property Management: ` + numberToCurrency(detail.gmv_property_management, 'Rp.'),
                //                 `GMV Financing: ` + numberToCurrency(detail.gmv_financing, 'Rp.'),
                //                 `COGS Financing: ` + numberToCurrency(detail.cogs_financing, 'Rp.'),
                //             ])}
                //         </>
                //     );
                // } else {
                //     array.push(
                //         <>
                //             GMV:
                //             {generateList([
                //                 `GMV Property Management: ` + numberToCurrency(detail.gmv_property_management, 'Rp.'),
                //                 `Jumlah Pembayaran Cash: ` + numberToCurrency(detail.gmv_property_management - detail.payment_to_financing_service, 'Rp.'),
                //                 `Jumlah Pembayaran Financing: ` + numberToCurrency(detail.payment_to_financing_service, 'Rp.'),
                //                 `GMV Financing: ` + numberToCurrency(detail.gmv_financing, 'Rp.'),
                //                 `COGS Financing: ` + numberToCurrency(detail.cogs_financing, 'Rp.'),
                //             ])}
                //         </>
                //     );
                // }

                // array.push(
                //     <>
                //         Revenue:
                //         {generateList([
                //             `(+) Revenue Transaksi: ${numberToCurrency(detail.revenue_transaction, 'Rp.')}`,
                //             detail.payment_type !== "cash" ? `(+) Revenue Financing (after COGS): ${numberToCurrency(detail.revenue_financing - detail.cogs_financing, 'Rp.')} (${numberToCurrency(detail.revenue_financing, 'Rp.')} - ${numberToCurrency(detail.cogs_financing, 'Rp.')})` : null,
                //             (detail.cobroke_type !== 0) ? `(-) Cobroke Fee: ${numberToCurrency(detail.cobroke_fee, 'Rp.')}` : null,
                //             `(-) Discount Revenue: ${numberToCurrency(detail.discount_revenue, 'Rp.')}`,
                //             (<>
                //                 <strong>Total:</strong> {numberToCurrency((detail.revenue_jendela), 'Rp.')}
                //             </>)
                //         ])}
                //     </>
                // );
            } else {
                /**
                                                * GMV
                                                */
                if (detail.payment_type === "cash") {
                    array = array.concat([
                        [`GMV Transaction`, `${numberToCurrency(detail.gmv_transaction, 'Rp.')} (${detail.is_include_maintenance ? 'Inc.' : 'Excl.'} Maintenance)`],
                    ])
                } else if (detail.payment_type === "monthly-owner") {
                    array = array.concat([
                        [`GMV Transaction`,
                            <>
                                {`${numberToCurrency(detail.gmv_transaction, 'Rp.')} (${detail.is_include_maintenance ? 'Inc.' : 'Excl.'} Maintenance)`}
                                {
                                    detail.payment_per_month_mto ?

                                        (detail.payment_per_month_mto.length) &&
                                        <ul>
                                            {detail.payment_per_month_mto.map((mto: IMTO, key: number) => {
                                                return <li>Pembayaran {key + 1}: Tanggal {dateFormat(mto.date, 'D MMMM YYYY')} senilai {numberToCurrency(mto.transaction_amount, 'Rp.')}</li>
                                            })}
                                        </ul>
                                        :
                                        <p>
                                            <strong>NOTE: Harap isi Pembayaran per Bulannya</strong>
                                        </p>
                                }
                            </>
                        ]]);
                } else if (['installment-blibli', 'installment-modalku', 'jendelapay'].includes(detail.payment_type)) {
                    array = array.concat([
                        [`GMV Transaction`, `${numberToCurrency(detail.gmv_transaction, 'Rp.')} (${detail.is_include_maintenance ? 'Inc.' : 'Excl.'} Maintenance)`],
                        [`GMV Financing`, numberToCurrency(detail.gmv_financing, 'Rp.')],
                    ])
                } else {
                    array = array.concat([
                        [`GMV Transaction`, `${numberToCurrency(detail.gmv_transaction, 'Rp.')} (${detail.is_include_maintenance ? 'Inc.' : 'Excl.'} Maintenance)`],
                        [`Jumlah Pembayaran Cash`, numberToCurrency(detail.gmv_transaction - detail.payment_to_financing_service, 'Rp.')],
                        [`Jumlah Pembayaran Financing`, numberToCurrency(detail.payment_to_financing_service, 'Rp.')],
                        [`GMV Financing`, numberToCurrency(detail.gmv_financing, 'Rp.')],
                        [`COGS Financing`, numberToCurrency(detail.cogs_financing, 'Rp.')],
                    ])
                }

                array = array.concat([
                    [`Basic Price`, numberToCurrency(detail.basic_price, 'Rp.')],
                ])

                if (permissions['closing.detail-type'] === 'finance') {
                    array = array.concat([
                        [`Revenue Transaksi`, numberToCurrency(detail.revenue_transaction, 'Rp.')],
                        detail.payment_type !== "cash" ? ['Revenue Financing', numberToCurrency(detail.revenue_financing, 'Rp.')] : [],
                        detail.cobroke_type !== 0 ? [`(-) Cobroke Fee`, numberToCurrency(detail.cobroke_fee, 'Rp.')] : [],
                        [`Total`, numberToCurrency(detail.revenue_jendela, 'Rp.')],
                    ])

                    if (detail.status === 4) {
                        array = array.concat([
                            [`PPN Komisi Sewa`, numberToCurrency((detail.ppn_value), 'Rp.')],
                            [`DPP Komisi Sewa`, numberToCurrency((detail.dpp_value), 'Rp.')],
                        ])
                    }

                    array = array.concat([
                        [`Include Maintenance`, detail.is_include_maintenance ? <Chip color="primary" label="Yes" /> : <Chip label="No" />],
                    ])
                } else {
                    array = array.concat([
                        [`Komisi`, numberToCurrency(detail.revenue_jendela, 'Rp.')],
                    ])
                }

                if (detail.referral_commission !== 0) {
                    array = array.concat([
                        [`Referral Komisi`, numberToCurrency(detail.referral_commission, 'Rp.')],
                    ]);
                }
            }

            const revertButton = permissions['closing.revert-status']
                ? <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{
                        marginLeft: '10px'
                    }}
                    onClick={() => setModalState(17)}
                >
                    Revert
                </Button>
                : null;

            array = array.concat([
                detail.status === 1 && [`Status`, `${detail.status_label} by ${detail.confirmed_by_label} at ${detail.confirmed_at_label}`],
                detail.status === 2 && [`Status`, <>
                    {`${detail.status_label} by ${detail.verified_by_label} at ${detail.verified_at_label}`}
                    {revertButton}
                </>],
                detail.status === 4 && [`Status`, <>
                    {`${detail.status_label} by ${detail.completed_by_label} at ${detail.completed_at_label}`}
                    {revertButton}
                </>],
                detail.status === 3 && [`Status`, `${detail.status_label} by ${detail.canceled_by_label} at ${detail.canceled_at_label}`],
                detail.status === 3 && [`Canceled Reason`, detail.canceled_reason]
            ]);

            return array;
        }
    }

    // const handleToggleDigitalContract = () => {
    //     setIsLoading(true);
    //     const data = {
    //         closing_id: detail.id
    //     }
    //     DefaultAxios
    //         .post(`${process.env.REACT_APP_API_URL}/new-closing/toggle-docusign`, data)
    //         .then(res => {
    //             Swal.fire({
    //                 title: `Berhasil ${detail.is_digital_contract !== 0 ? 'Menonaktiftkan' : 'Mengaktifkan'} Docusign`,
    //                 icon: 'success',
    //                 timer: 1000
    //             })
    //                 .then(res => {
    //                     fetchDetail();
    //                 })
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         })
    // }

    const generateAgentInformation = () => {
        return (
            <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                <Grid item>
                    <Typography variant="h6" className={classes.h6_title}>
                        Agent Information

                        {permissions['agent.manage'] &&
                            <LinkDom target="_blank" to={`/agent/${detail.agent_id}`} >
                                <IconButton color="primary">
                                    <VisibilityIcon />
                                </IconButton>
                            </LinkDom>
                        }
                        {
                            permissions['agent.manage'] &&
                            <LinkDom target='_blank' to={`/agent/edit/${detail.agent_id}`}>
                                <IconButton color="primary">
                                    <EditIcon />
                                </IconButton>
                            </LinkDom>
                        }
                        {
                            permissions['closing.change-agent'] &&
                            <IconButton color='primary' onClick={() => setModalState(25)}>
                                <SwitchAccountIcon />
                            </IconButton>
                        }
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <ClosingModalEdit
                closingId={detail.old_closing_id}
                open={modalEditOldState}
                handleCloseModal={handleCloseEditOld}
            />

            <WhatsappPreviewModal
                open={!!chats.length}
                chats={chats}
                code={chatCode}
                created_at={detail.created_at}
                onClose={() => {
                    setChats([])
                    setChatCode(null)
                }}
            />

            <ModalDetail
                open={props.open}
                title={`${detail.code} [${detail.agent_id ? 'AGENT' : (detail.cobroke_type === 3 ? 'WINGS' : 'OWNER')}] ${detail.termin_code_label ? `- ${detail.termin_code_label} ` : ""}`}
                appBarProps={{
                    color: detail.status === 3 ? 'secondary' : 'primary',
                }}
                onCloseModal={() => props.onClose()}
                isLoading={isLoading}
                customRightButton={(
                    <>
                        {(detail.stop_edit_label !== null) ?
                            <Button variant="contained" onClick={() => {
                                Swal.fire({
                                    text: detail.stop_edit_label,
                                    icon: 'info',
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                })
                            }}>
                                Edit
                            </Button>
                            : (detail.old_closing_id && permissions['closing.old-edit'] && detail.stop_edit_label === null) ?
                                <Button color="inherit" onClick={() => { handleOpenEditOld() }}>
                                    Edit
                                </Button>
                                :
                                <Button color="inherit" onClick={() => navigate(`/closing/edit/${detail.code}`)}>
                                    Edit
                                </Button>
                        }
                    </>
                )}
                stickyHeader
            >
                <>
                    <Grid item md={6} xs={12}>
                        <ModalSection
                            title="Informasi Dasar"
                            titleContent={
                                (
                                    <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.h6_title}>
                                                Informasi Dasar

                                                {permissions['closing.delete'] &&
                                                    <IconButton onClick={() => setModalState(24)} color="secondary">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            content={generateBasicInformation()}
                        />

                        <ModalSection
                            title="Informasi Transaksi"
                            content={generateTransactionInformation()}
                            additionalContent={(
                                <>
                                    {(permissions['closing.verify'] && (detail.status === 0)) &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={handleVerifyClosing}
                                        >
                                            Verify Closing
                                        </Button>
                                    }

                                    {(permissions['closing.complete'] && (detail.status === 2)) &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={handleCompleteClosing}
                                        >
                                            Complete Closing
                                        </Button>
                                    }
                                </>
                            )}
                        />

                        {detail.referral_account &&
                            <ModalSection
                                title="Informasi Referral"
                                content={[
                                    [`Nama`, detail.referral_account.name],
                                    [`Email`, detail.referral_account.email],
                                    [`Phone`, detail.referral_account.phone],
                                    [`Bank Info`, detail.referral_account.bank_number ? `(${detail.referral_account.bank_type}) ${detail.referral_account.bank_number} an. ${detail.referral_account.bank_behalf_name}` : '-'],
                                ]}
                            />
                        }
                    </Grid>

                    <Grid item md={6} xs={12}>
                        {detail.cobroke_type !== 3 &&
                            <>
                                {
                                    detail.cobroke_type === 2 ? (
                                        <ModalSection
                                            title={`Agent Information`}
                                            titleContent={generateAgentInformation()}
                                            content={
                                                [
                                                    [`Name`, detail.agent_name_label],
                                                    [`Phone`, renderPhone(detail.agent_phone_label, 'agent', detail.agent_id?.toString() || '')],
                                                    [`Kantor Agency`, detail.agent_office_label]
                                                ]
                                            }
                                        />
                                    ) : null
                                }

                                <ModalSection
                                    title={`Tenant Information`}
                                    titleContent={(
                                        <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                                            <Grid item>
                                                <Typography variant="h6" className={classes.h6_title}>
                                                    Tenant Information

                                                    {permissions['customer.manage'] && detail.customer_id &&
                                                        <LinkDom target="_blank" to={`/customer/${detail.customer_id}`} >
                                                            <IconButton color="primary">
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </LinkDom>
                                                    }
                                                    {permissions['customer.edit'] && detail.customer_id &&
                                                        <IconButton color="primary" onClick={() => setTenantEditModal(true)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                    {detail.cobroke_type === 2 && permissions['closing.cobroke-customer'] && !detail.customer_id ?
                                                        <IconButton title='Assign Customer' color="primary" onClick={() => setModalState(23)}>
                                                            <PersonAddIcon />
                                                        </IconButton>
                                                        : null
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    content={
                                        [
                                            [`Name`, detail.tenant_name_label],
                                            ['Expat', (detail.tenant_nationality_label === "-" || detail.tenant_nationality_label === 'Indonesian' ? 'No' : `Yes (${detail.tenant_nationality_label})`)],
                                            [`Phone`, renderPhone(detail.tenant_phone_label, 'customer', detail.customer_id?.toString() || '')],
                                            [`Bank Info`, detail.tenant_bank_information_label ? detail.tenant_bank_information_label : "-"]
                                        ]
                                    }
                                />
                            </>
                        }

                        <ModalSection
                            title={`${[0, 2].includes(detail.cobroke_type) === false ? 'Agent' : 'Owner'} Information`}
                            titleContent={[0, 2].includes(detail.cobroke_type) ?
                                (
                                    <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.h6_title}>
                                                Owner Information

                                                {(permissions['landlord.manage'] || permissions['landlord.admin']) &&
                                                    <LinkDom target="_blank" to={`/landlord/${detail.owner_id_label}`} >
                                                        <IconButton color="primary">
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </LinkDom>
                                                }
                                                {(permissions['landlord.edit'] || permissions['landlord.admin']) &&
                                                    <IconButton color="primary" onClick={() => setLandlordEditModal(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                }
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item>
                                            {(permissions['landlord.edit'] && detail.owner_name_label !== "[UNDEFINED]" && detail.status !== 3) &&
                                                <Button variant="contained" color="primary" size="small" onClick={() => { setModalState(2) }}>
                                                    Edit
                                                    </Button>
                                            }
                                        </Grid> */}
                                    </Grid>
                                )
                                :
                                generateAgentInformation()
                            }
                            content={[0, 2].includes(detail.cobroke_type) === false ?
                                [
                                    [`Name`, detail.agent_name_label],
                                    [`Phone`, renderPhone(detail.agent_phone_label, 'agent', detail.agent_id?.toString() || '')],
                                    [`Kantor Agency`, detail.agent_office_label]
                                ]
                                :
                                [
                                    ...((detail.cobroke_type !== 3 && detail.unit.is_owner) ?
                                        [[
                                            <>
                                                <span>PIC Owner Information</span>
                                                {
                                                    permissions['pic-landlord.show'] && detail.pic_landlord_id ?
                                                        <IconButton
                                                            title='Show PIC Detail Information'
                                                            onClick={() => window.open(`/pic-landlord/${detail.pic_landlord_id}`)}
                                                        >
                                                            <VisibilityIcon color='primary' />
                                                        </IconButton>
                                                        : null
                                                }
                                            </>,
                                            detail.pic_landlord_name && detail.pic_landlord_phone ?
                                                <>
                                                    <span>{detail.pic_landlord_name}</span> - <span>{renderPhone(detail.pic_landlord_phone, 'pic_landlord', detail.pic_landlord_id?.toString() || '')}</span>
                                                    {/* <IconButton href={`tel:${detail.pic_landlord_phone}`} size="small" style={{ marginRight: "5px" }}>
                                                        <PhoneIcon />
                                                    </IconButton>
                                                    <IconButton size='small' onClick={() => handleOpenQiscus({phone: detail.pic_landlord_phone, type: 'landlord'})}>
                                                        <WhatsAppIcon />
                                                    </IconButton> */}
                                                    {/* <IconButton href={`https://wa.me/${detail.pic_landlord_phone}`} size="small" target="_blank" rel="noopener noreferrer">
                                                        <WhatsAppIcon />
                                                    </IconButton> */}
                                                </> :
                                                '-'
                                        ]] : []
                                    ),
                                    [`Ownership Name`, detail.owner_name_label],
                                    // [`Phone`, renderPhone(detail.owner_phone_label)],
                                    [`Bank Info`, detail.owner_bank_information_label ? detail.owner_bank_information_label : "-"]
                                ]
                            }
                        />

                        <ModalSection
                            title={`Other Information`}
                            content={[
                                [`Created By`, `${detail.created_by_label} at ${dateFormat(detail.created_at, 'DD MM YYYY HH:mm:ss')}`],
                                [(<>
                                    Property Manager
                                    {
                                        (detail.extend_from === null && permissions['closing.change-advisor']) &&
                                        <Tooltip title={`${detail.advisor_name_label !== null ? 'Ganti' : 'Assign'} Advisor`}>
                                            <IconButton color="primary" onClick={() => setModalState(27)}>
                                                {detail.advisor_name_label !== null ?
                                                    <AssignmentIndIcon />
                                                    :
                                                    <PersonAddIcon />
                                                }
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </>), `${detail.advisor_name_label !== null ? detail.advisor_name_label : "-"}`],
                                [`Property Manager 2`, `${detail.agent_cobroke_internal_name_label !== null ? detail.agent_cobroke_internal_name_label : "-"}`],
                                [`Telesales`, detail.telesales_name_label || '-'],
                                [(
                                    <>
                                        PIC Extend
                                        {(detail.status !== 3 && permissions['extend.admin']) &&
                                            <Tooltip title={`${detail.extend_pic_label !== null ? 'Ganti' : 'Assign'} PIC`}>
                                                <IconButton color="primary" onClick={() => setModalState(15)}>
                                                    {detail.extend_pic_label !== null ?
                                                        <AssignmentIndIcon />
                                                        :
                                                        <PersonAddIcon />
                                                    }
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>
                                ), detail.extend_pic_label !== null ? detail.extend_pic_label : "-"],
                                (permissions['closing.see_extend_placement'] && detail.extend_pic_label) ?
                                    [`PIC Extend Placement`, detail.extend_pic_placement ? detail.extend_pic_placement : "-"] : [],
                                [(<>
                                    PIC Quality Control
                                    {detail.activity_qc ?
                                        <>
                                            <Tooltip title="Lihat Activity">
                                                <IconButton color="primary" target="_blank" href={`/jhs-order/${detail.activity_qc.id}`}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {permissions['closing.activity-quality-control'] &&
                                                <Tooltip title="Hapus Activity">
                                                    <IconButton color="secondary" onClick={handleDeleteActivityQualityControl}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }

                                        </>
                                        :
                                        permissions['closing.activity-quality-control'] &&
                                        <Tooltip title="Buat Activity Quality Control">
                                            <IconButton color="primary" onClick={() => setModalState('quality-control')}>
                                                <PersonAddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </>), (
                                    (
                                        <>
                                            {detail.activity_qc ? detail.activity_qc.name : "-"}

                                        </>
                                    )
                                )],
                                [(
                                    <>
                                        PIC Handover Checkin
                                        {permissions['closing.manage-ho-checkin'] ?
                                            (detail.activity_ho_checkin) ?
                                                <>
                                                    <Tooltip title="Lihat Activity">
                                                        <IconButton color="primary" target="_blank" href={`/jhs-order/${detail.activity_ho_checkin.id}`}>
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Hapus Activity">
                                                        <IconButton color="secondary" onClick={handleDeleteActivityHandover}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                :
                                                <Tooltip title="Assign Handover">
                                                    <IconButton color="primary" onClick={() => setModalState('handover-checkin')}>
                                                        <PersonAddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            :
                                            ""
                                        }
                                    </>
                                ), detail.activity_ho_checkin ? detail.activity_ho_checkin.name : "-"],
                                [7, 8, 10, 11, 12].includes(extendStatus) ? [(<>
                                    PIC Handover Checkout
                                    {detail.activity_co ?
                                        <>
                                            <Tooltip title="Lihat Activity">
                                                <IconButton color="primary" target="_blank" href={`/activity/${detail.activity_co.id}`}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                permissions['extend.manage'] ?
                                                    <>
                                                        <Tooltip title="Hapus Activity">
                                                            <IconButton color="secondary" onClick={handleDeleteActivityHandoverCheckout}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                    : null
                                            }
                                        </> :
                                        permissions['extend.manage'] ?
                                            <Tooltip title="Assign Handover Ceckout">
                                                <IconButton color="primary" onClick={() => setModalState('handover-checkout')}>
                                                    <PersonAddIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null
                                    }
                                </>), (
                                    (
                                        <>
                                            {detail.activity_co ? detail.activity_co.name : "-"}

                                        </>
                                    )
                                )] : [],
                                [7, 8, 10, 11, 12].includes(extendStatus) ?
                                    [(
                                        <>
                                            Alasan Checkout
                                            {
                                                permissions['extend.manage'] &&
                                                <IconButton color='primary' title='Change Checkout Reason' onClick={handleChangeCheckoutReason}>
                                                    <EditIcon />
                                                </IconButton>
                                            }
                                        </>
                                    ), (
                                        <>
                                            {detail.checkout_reason_label ? detail.checkout_reason_label : '-'}
                                            <Tooltip title="Proof">
                                                <IconButton color="primary" onClick={() => setImagePreview(detail.checkout_proof_file_url)}>
                                                    <ImageIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )
                                    ] : [],
                                [(
                                    <>
                                        Contract Status
                                        {
                                            (+detail.is_digital_contract === 1 && detail.old_closing_id === null) &&
                                            <>
                                                <Tooltip title="Document Status">
                                                    <IconButton color="primary" onClick={() => setModalState(10)}>
                                                        <FindInPageIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit Docusign">
                                                    <IconButton color="primary" onClick={() => setModalState(18)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    </>
                                ),
                                <>
                                    <>
                                        Owner Contract: {(getStatusContract(detail.owner_contract_status))}  |  Tenant Contract: {(getStatusContract(detail.tenant_contract_status))}
                                        <br />
                                        <br />
                                    </>
                                </>
                                ],
                                [(
                                    <>
                                        Extend Status

                                    </>
                                ), (
                                    <Root>
                                        <div className={classes.extendActionContainer}>
                                            {getExtendChip()}
                                            {
                                                (detail.status !== 3) &&
                                                <>
                                                    {extendStatus === 0 &&
                                                        <>
                                                            {(permissions['extend.force'] && detail.old_closing_id !== null) ?
                                                                <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(999)}>
                                                                    Force Extend
                                                                </Button>
                                                                : null}


                                                            {(detail.extend_will_be_termin && (permissions['extend.manage'] || permissions['closing.termin'])) ?
                                                                <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(6)}>
                                                                    Extend Termin
                                                                </Button>
                                                                : null}

                                                            {!detail.extend_will_be_termin && permissions['extend.manage'] &&
                                                                <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(1)}>Extend</Button>
                                                            }

                                                            {permissions['extend.manage'] &&
                                                                <Button variant="contained" color="secondary" size="small" onClick={() => handleActionChangeExtend(7)}>Checkout</Button>
                                                            }
                                                        </>

                                                    }

                                                    {extendStatus === 1 &&
                                                        <div className={classes.extendActionContainer}>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(6)}>Buat Closing Extend</Button>
                                                            <Button variant="contained" color="secondary" size="small" onClick={() => handleActionChangeExtend(7)}>Checkout</Button>
                                                        </div>
                                                    }

                                                    {extendStatus === 6 && detail.extend_is_digital_contract !== 1 &&
                                                        <div className={classes.extendActionContainer}>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(9)}>Konfirm ttd.</Button>
                                                        </div>
                                                    }

                                                    {extendStatus === 7 &&
                                                        <div className={classes.extendActionContainer}>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(8)}>Sudah Checkout</Button>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(1)}>Rubah menjadi Extend</Button>
                                                        </div>
                                                    }

                                                    {extendStatus === 8 &&
                                                        <div className={classes.extendActionContainer}>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(11)}>Proses Deposit</Button>
                                                        </div>
                                                    }

                                                    {/* Disabled for now*/}
                                                    {/* {extendStatus === 11 &&
                                                            <div className={classes.extendActionContainer}>
                                                                <Button variant="contained" color="primary" onClick={() => handleActionChangeExtend(12)}>Buat Pengajuan Deposit ke Finance</Button>
                                                            </div>
                                                        } */}

                                                    {extendStatus === 11 &&
                                                        <div className={classes.extendActionContainer}>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => handleActionChangeExtend(10)}>Konfirm Deposit telah dikembalikan</Button>
                                                        </div>
                                                    }

                                                    {(extendStatus === 12 && permissions['deposit.confirm']) &&
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => setModalState(3)}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    }

                                                    {
                                                        (extendStatus !== 0 && permissions['extend.reset-extend-status']) &&
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={() => setModalState(16)}
                                                        >
                                                            Reset
                                                        </Button>
                                                    }

                                                </>
                                            }
                                        </div>
                                    </Root>
                                )]
                            ]}
                        />
                    </Grid>

                    {(detail.notes !== null && typeof detail.notes !== "undefined" && detail.notes.trim() !== "") &&
                        <ModalSection
                            title="Notes"
                            content={[
                                [``, detail.notes]
                            ]}
                        />
                    }

                    <Grid item xs={12}>
                        <Root>
                            <div className={classes.actionButtonContainer}>
                                {
                                    permissions['closing-deposit.manage'] &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setModalState(20)
                                        }}
                                    >
                                        Deposit List
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        setModalState(19)
                                    }}
                                >
                                    List Activity
                                </Button>
                                {/* {detail.handover_check_in_activity_id === null &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setModalState(14)
                                        }}
                                    >
                                        Buat Handover Check-In
                                    </Button>
                            } */}

                                {(permissions['closing.convert-old'] && detail.old_closing_id) &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleConvert()}
                                    >
                                        Convert Closing
                                    </Button>
                                }
                                {
                                    (detail.old_closing_id === null) &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => setModalState(10)}
                                    >
                                        Contracts
                                    </Button>
                                }
                                {permissions['closing.cancel'] && (detail.status !== 3) &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={openModalConfirmCancel}
                                    >
                                        Cancel Closing
                                    </Button>
                                }

                            </div>
                        </Root>
                    </Grid>

                    <Grid item xs={12}>
                        <TransactionList
                            closingData={detail}
                            setIsLoading={setIsLoading}
                            permissions={permissions}
                            paymentType={detail?.payment_type}
                            generateTransactionInformation={generateTransactionInformation}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DepositTransactionList
                            closingData={detail}
                            setIsLoading={setIsLoading}
                            permissions={permissions}
                            generateTransactionInformation={generateTransactionInformation}
                            refreshFlag={depositTransactionRefreshFlag}
                            paymentType={detail?.payment_type}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Root>
                            <Paper className={classes.paper}>
                                <LoadingScreen open={isFooterLoading} />
                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Comment" value={0} />
                                    {/* <Tab label="Timeline" value={1} /> */}
                                    {[8, 10, 11].includes(extendStatus) && <Tab label="Handover" value={2} />}
                                    {/* <Tab label="Laporan Check-In" value={5} /> */}
                                    {detail.group_termin_id !== null && <Tab label="Termins" value={3} />}
                                    <Tab label="Files" value={4} />
                                    {permissions['closing.see-log'] && <Tab label="Logs" value={6} />}
                                    {permissions['logs-broadcast.see-logs'] && <Tab label="Logs Broadcast" value={23} />}
                                    <Tab label="Ticket" value={20} />
                                    {permissions['closing.bank-manage'] && <Tab label="Bank" value={21} />}
                                    {permissions['closing.see-order-jhs'] && <Tab label="Order JHS" value={24} />}
                                </Tabs>
                                <div className={classes.footer}>
                                    {footer}
                                </div>
                            </Paper>
                        </Root>
                    </Grid>
                </>
            </ModalDetail>

            <QualityControlModal
                closing_id={detail.id}
                open={modalState === 'quality-control' || modalState === 'handover-checkout'}
                type={modalState as string}
                onClose={(refetch: boolean) => {
                    setModalState(0)
                    if (refetch) {
                        fetchDetail()
                    }
                }}
            />

            <HandoverCheckinModal
                closing_id={detail.id}
                open={modalState === 'handover-checkin'}
                type={modalState as string}
                onClose={(refetch: boolean) => {
                    setModalState(0)
                    if (refetch) {
                        fetchDetail()
                    }
                }}
            />

            {detail.cobroke_type !== 3 &&
                <NoteModal
                    open={modalState === 21}
                    onClose={(refresh) => {
                        setModalState(0)
                        if (refresh) {
                            fetchDetail()
                        }
                    }}
                    id={detail.unit.apartment_id}
                    name={detail.unit.apartment_name}
                />
            }
            {
                (modalState === 4) &&
                <HandOverCheckOutModal
                    closing_id={detail.id}
                    listing_information={detail.listing_information}
                    owner_name={detail.owner_name_label ? detail.owner_name_label : "-"}
                    tenant_name={detail.tenant_name_label ? detail.tenant_name_label : "-"}
                    open={modalState === 4}
                    onChange={handleChangeExtendChild}
                    onClose={(refresh: boolean) => {
                        setModalState(0)
                        if (refresh) fetchDetail()
                    }}
                />
            }
            <DepositModal
                closing_id={detail.id}
                extendStatus={extendStatus}
                detail={detail}
                open={(modalState === 3)}
                onChange={handleChangeExtendChild}
                onClose={() => setModalState(0)}
            />
            <CustomerModal
                id={detail.customer_id}
                closingID={detail.id}
                isOpen={modalState === 1}
                handleClose={() => {
                    setModalState(0)
                    fetchDetail();
                }}
                permissions={permissions}
            />
            {
                [0, 2].includes(detail.cobroke_type) &&
                <OwnerModal
                    id={detail.owner_id_label}
                    isOpen={modalState === 2}
                    handleClose={() => {
                        setModalState(0)
                        fetchDetail();
                    }}
                    permissions={permissions}
                />
            }
            {
                ![3, 4].includes(detail.cobroke_type) &&
                <SendInquiryModal
                    closing={detail}
                    isOpen={modalState === 6}
                    handleClose={() => setModalState(0)}
                />
            }
            <CancelClosingModal
                open={modalState === 7}
                onClose={() => { setModalState(0) }}
                inputRef={inputCancelReason}
                onCancelClosing={handleCancelClosing}
            />
            <VerifyClosingModal
                open={modalState === 8}
                closingId={detail.id}
                handleClose={() => {
                    setModalState(0)
                    fetchDetail();
                }}
            />
            <CompleteClosingModal
                open={modalState === 12}
                closingId={detail.id}
                handleClose={() => {
                    setModalState(0)
                    fetchDetail();
                }}
            />
            <ChangeFirstPaymentDateModal
                open={modalState === 13}
                closingId={detail.id}
                handleClose={() => {
                    setModalState(0)
                    fetchDetail();
                }}
            />
            {detail.cobroke_type !== 3 &&
                <UploadOwnerDocumentModal
                    open={modalState === 9}
                    unitId={detail.unit_id}
                    onClose={() => setModalState(0)}
                />
            }
            <ChangePicModal
                closing={detail}
                open={modalState === 15}
                onClose={(refetch: boolean) => {
                    setModalState(0)
                    if (refetch) {
                        fetchDetail()
                    }
                }}
            />

            <ResetStatusModal
                closing={detail}
                open={modalState === 16}
                onClose={(refetch: boolean) => {
                    setModalState(0)
                    if (refetch) {
                        fetchDetail()
                    }
                }}
            />
            <RevertClosingModal
                open={modalState === 17}
                onClose={() => { setModalState(0) }}
                inputRef={inputRevertReason}
                onRevertStatus={handleRevertStatus}
            />
            {
                <>
                    <ClosingContractModal
                        open={modalState === 10}
                        onClose={() => setModalState(0)}
                        closingId={detail.id}
                        closingCode={detail.code}
                        digitalContractData={detail.digital_contract_data}
                    />
                    {/* <ContractStatusModal
                        open={modalState === 10}
                        isInhouse={detail.is_inhouse_contract}
                        onClose={(refresh: boolean) => {
                            setModalState(0)
                            if (refresh) {
                                fetchDetail()
                            }
                        }}
                        detail={detail}
                        permissions={permissions}
                        onFetchDetail={fetchDetail}
                    /> */}
                </>
            }
            <PartialDocusignForm
                open={modalState === 18}
                onClose={(fetch) => {
                    setModalState(0)
                    if (fetch) {
                        fetchDetail()
                    }
                }}
                closingId={detail.id}
                digitalContractData={detail.digital_contract_data}
            />

            <TenatEditModal
                open={tenantEditModal}
                id={detail.customer_id}
                onClose={(fetch) => {
                    setTenantEditModal(false)
                    if (fetch) {
                        fetchDetail()
                    }
                }}
            />

            <LandlordEditModal
                open={landlordEditModal}
                id={detail.owner_id_label}
                onClose={(fetch) => {
                    setLandlordEditModal(false)
                    if (fetch) {
                        fetchDetail()
                    }
                }}
            />

            {detail.cobroke_type !== 3 &&
                <UnitActivityListModal
                    unitId={Number(detail.unit_id)}
                    open={modalState === 19}
                    onClose={() => {
                        setModalState(0)
                    }}
                />
            }

            <DepositListModal
                closingCode={detail.code}
                closingId={detail.id}
                isOpen={modalState === 20}
                onClose={() => {
                    setModalState(0)
                }}
                setDepositTransactionRefreshFlag={setDepositTransactionRefreshFlag}
            />

            <QiscusTemplateModal
                open={modalState === 22 && qiscusData !== null}
                onClose={() => setModalState(0)}
                onSubmitSuccess={() => [setModalState(0), setQiscusData(null)]}
                data={qiscusData}

            />

            <AddCustomerModal
                open={modalState === 23}
                onClose={() => setModalState(0)}
                onSubmitSuccess={() => {
                    setModalState(0)
                    fetchDetail()
                }}
                id={detail.id}
            />

            <DeleteClosingModal
                open={modalState === 24}
                code={detail.code}
                id={detail.id}
                onClose={() => setModalState(0)}
                onSubmitSuccess={() => {
                    setModalState(0)
                    window.location.href = '/closing'
                }}
            />

            <ChangeAgentModal
                open={modalState === 25}
                closingId={detail.id}
                initialAgent={detail.agent_name_label}
                onClose={(refresh: boolean) => {
                    setModalState(0)

                    if (refresh) {
                        fetchDetail()
                    }
                }}
            />

            <ChangeEnquiryModal
                id={detail.id}
                onClose={() => setModalState(0)}
                open={modalState === 26}
                onSubmitSuccess={() => {
                    setModalState(0)
                    fetchDetail()
                }}
                onEnquiryExistCallback={(data) => {
                    Swal.fire({
                        title: 'Data tenant ditemukan!',
                        icon: 'warning',
                        html: `
                        Closing : ${data.closing_code} <br />
                        Nama : ${data.customer_name} <br /></br />
                        Lanjutkan memindahkan data enquiry? <br /></br>
                        <b>(NOTE: Data customer akan terhubung dengan closing ${data.closing_code})</b>`,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ya'
                    })
                        .then(res => {
                            if (res.value) {
                                changeCustomerClosing(detail.id, data.customer_id)
                            }
                        })
                }}
            />

            <GeneralAutocompleteModal
                title={detail.advisor_name_label ? 'Change Advisor' : 'Assign Advisor'}
                autocompleteUrl={`${process.env.REACT_APP_API_URL}/autocomplete/user/advisor`}
                label="Advisor"
                onClose={() => setModalState(0)}
                onSuccess={() => {
                    renderSuccessButton("Berhasil Submit Data")
                    setModalState(0)
                    fetchDetail()
                }}
                open={modalState === 27}
                url={`${process.env.REACT_APP_API_URL}/new-closing/change-advisor`}
                paramName='advisor_id'
                extraParams={{
                    closing_id: detail.id,
                    _method: "PATCH"
                }}
                defaultQuery={detail.advisor_name_label || ''}
                isPatch
            />

            <CheckoutReasonModal
                closingId={detail.id}
                open={modalState === 28}
                onClose={(refresh) => {
                    setModalState(0)
                    if (refresh) {
                        fetchDetail()
                    }
                }}
            />

            <ImagePreview
                image={imagePreview}
                onClose={() => setImagePreview('')}
            />
        </Root>
    );
}

export default ClosingModalDetail;