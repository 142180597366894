import React from "react"
import { Avatar, Card, Theme, CardContent, Divider, Grid, IconButton } from "@mui/material"
import moment from "moment";
import { convertIndonesiaPhoneNumber } from "../../../_utils/Helper";

/**
 * Components
*/

/**
 * Icons
 */
import { WhatsApp } from "@mui/icons-material";

/**
 * Interfaces
 */
import { generateStyle } from "../../../_utils/DefaultStyle";
import { IActivity, IData } from "../../_activity/ActivitySchedule";

interface IProps {
    data: IData
    noActivityText?: string
    forShowing?: boolean
}

const ShowingScheduleCard = ({ data, forShowing, noActivityText }: IProps) => {
    const { Root, classes } = useStyles()

    const createSchedule = (activity: IActivity) => {
        return (
            <>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Grid container className={classes.container} flexWrap={'nowrap'}>
                    <Grid item xs={9} >
                        {
                            typeof activity.apartment_name === 'object' ?
                                activity.apartment_name :
                                <p className={classes.small}>
                                    {activity.apartment_name}
                                </p>
                        }
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <p className={classes.small}>
                            {activity.time ? moment(new Date('2001-01-01 ' + activity.time)).format('HH:mm') : ''}
                        </p>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Root>
            <div className={classes.cardWrapper}>
                <Card elevation={2} className={classes.root}>
                    <CardContent className={classes.content}>
                        <div className={classes.body}>
                            <div className={classes.header}>
                                <Avatar className={classes.image} alt={data.name} src={data.photo} />
                                <p className={classes.name}>{data.name}</p>
                                {
                                    data.phone_number && !forShowing ?
                                        <IconButton
                                            color="primary"
                                            href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(data.phone_number)}`}
                                            target="_blank"
                                            rel="noopener norefferer"
                                            sx={{ marginLeft: 'auto' }}
                                        >
                                            <WhatsApp />
                                        </IconButton>
                                        : null
                                }
                            </div>
                            {
                                data.activities && data.activities.length ?
                                    data.activities.map((activity) => {
                                        return createSchedule(activity)
                                    }) :
                                    <>
                                        <Divider sx={{ mt: 1, mb: 1 }} />
                                        <div className={classes.noActivity}>{noActivityText ? noActivityText : 'Tidak ada Aktivitas hari ini'}</div>
                                    </>
                            }
                        </div>
                    </CardContent>
                </Card>
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    cardWrapper: {
        width: '100%'
    },
    root: {
        borderRadius: 20,
        boxShadow: '0px 5px 25px #52575D33',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        padding: '1rem'
    },
    noActivity: {
        marginTop: 10,
        fontSize: 13
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    image: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 16,
    },
    small: {
        fontSize: 13,
        margin: 0,
        marginRight: 5
    },
    extraSmall: {
        fontSize: 12,
        margin: 0
    },
    name: {
        fontSize: 18,
        fontWeight: 'bolder'
    },
}), "ActivitySchedule_Card"
)

export default ShowingScheduleCard