import React, { useState, useEffect, useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Chip, IconButton, Link, Tab, Tabs, Theme, Typography } from '@mui/material';

/**
 * Components
 */
import SimpleDataTable, { IColumn } from '../../../_components/_dataTable/SimpleDataTable'
import TodoListModal from './TodoListModal';
import ChangeDueDateModal from './ChangeDueDateModal';
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Utils
*/
import { format, isToday } from 'date-fns';
import { id } from 'date-fns/locale';
import { generalErrorHandler, renderSuccessButton, renderToastSuccess, renderWarningButton } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { PermissionContext } from '../../../_contexts/PermissionContext';

/**
 * Icons
 */
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import { generateStyle } from '../../../_utils/DefaultStyle';
import DataTable, { IColumn as IDataTableColumn } from '../../../_components/_dataTable/DataTable';

export interface ITodoList {
    id: number
    completed_at?: string
    ticket_id: number
    ticket_code: string
    due_date: Date
    due_time: Date | null
    pic: string
    description: string
}

interface IProps {
    type: 'detail' | 'dashboard'
    ticketId?: string
    addTodo?: number
    hideComplete?: boolean
    callBackTodo?: Function
    initialList?: ITodoList[]
    isAdmin?: boolean
}

interface INotify {
    created_at: string
    notification_text: string
    url: string
    user_id: string
}

const TodoListTable = (props: IProps) => {
    const { Root, classes } = useStyles()
    const permissions = useContext(PermissionContext)
    const [isLoading, setIsLoading] = useState(false)
    const [redrawDataTable, setRedrawDataTable] = useState(new Date().getTime())

    const [todoList, setTodoList] = useState<ITodoList[]>([])
    const [notifications, setNotifications] = useState<INotify[]>([])
    const [selectedTodo, setSelectedTodo] = useState<ITodoList | null>(null)
    const [modalState, setModalState] = useState<'todo' | 'due_date' | ''>('')
    const [filter, setFilter] = useState<Record<string, any>>({})
    const [ticketType, setTicketType] = useState<"general" | "deposit">('general')

    const renderDueDateStatus = (date: string, row: ITodoList, type?: string) => {
        const time = row.due_time
        let newDate = new Date(`${date}${time ? `T${time}` : ''}`)
        let isStatus = type === 'status'

        if (row.completed_at) {
            return (
                <Chip
                    color="success"
                    avatar={isStatus ? undefined : <AccessTimeIcon style={{ color: 'white' }} />}
                    label={isStatus ? 'Completed' : format(newDate, time ? 'dd MMMM yyyy HH:mm' : 'dd MMMM yyyy', { locale: id })}
                />
            )


        } else if (isToday(newDate)) {
            if (time) {
                if (newDate.getTime() > new Date().getTime()) {
                    return (
                        <Chip
                            color="default"
                            avatar={isStatus ? undefined : <AccessTimeIcon />}
                            label={isStatus ? 'On Deadline' : format(newDate, time ? 'dd MMMM yyyy HH:mm' : 'dd MMMM yyyy', { locale: id })}
                        />
                    )
                } else {
                    return <Chip
                        color="error"
                        avatar={isStatus ? undefined : <AccessTimeIcon style={{ color: 'white' }} />}
                        label={isStatus ? 'Passed Deadline' : format(newDate, time ? 'dd MMMM yyyy HH:mm' : 'dd MMMM yyyy', { locale: id })}
                    />
                }
            }

            return <Chip
                color="warning"
                avatar={isStatus ? undefined : <AccessTimeIcon style={{ color: 'white' }} />}
                label={isStatus ? 'On Deadline' : format(newDate, time ? 'dd MMMM yyyy HH:mm' : 'dd MMMM yyyy', { locale: id })}
            />

        } else if (newDate.getTime() < new Date().getTime()) {
            return <Chip
                color="error"
                avatar={isStatus ? undefined : <AccessTimeIcon style={{ color: 'white' }} />}
                label={isStatus ? 'Passed Deadline' : format(newDate, time ? 'dd MMMM yyyy HH:mm' : 'dd MMMM yyyy', { locale: id })}
            />
        } else {
            return <Chip
                color='default'
                avatar={isStatus ? undefined : <AccessTimeIcon />}
                label={isStatus ? 'On Going' : format(newDate, time ? 'dd MMMM yyyy HH:mm' : 'dd MMMM yyyy', { locale: id })}
            />
        }
    }

    const handleChangeTodo = (row: ITodoList) => {
        if (props.type === 'detail') {
            setModalState('todo')
        } else {
            setModalState('due_date')
        }

        setSelectedTodo(row)
    }

    const handleDeleteTodo = (row: ITodoList) => {
        renderWarningButton('Apakah anda ingin menghapus To-do ini?')
            .then(res => {
                if (res.value) {
                    setIsLoading(true)
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/ticket-todolist/${row.id}`)
                        .then(res => res.data)
                        .then(res => {
                            if (res) {
                                renderSuccessButton('Todo Deleted!')
                                let newTodo = [...todoList]
                                newTodo = newTodo.filter(todo => todo.id !== row.id)
                                setTodoList(newTodo)

                                if (typeof props.callBackTodo !== 'undefined') {
                                    props.callBackTodo(newTodo)
                                }

                                loadTodo(true)
                            }
                        })
                        .catch(generalErrorHandler)
                        .finally(() => setIsLoading(false))
                }
            })
    }

    const handleCompleteTodo = (row: ITodoList) => {
        renderWarningButton('Apakah anda ingin menandai selesai pada To-do ini?')
            .then(res => {
                if (res.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/ticket-todolist/complete`, { id: row.id })
                        .then(res => res.data)
                        .then(res => {
                            if (res) {
                                renderToastSuccess('Completed')
                                loadTodo(true)
                            }
                        })
                        .catch(generalErrorHandler)
                        .finally(() => setIsLoading(false))
                }
            })
    }

    const loadTodo = (refresh?: boolean) => {
        setIsLoading(true)
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}${props.type === 'detail' ? `/ticket-todolist/${props.ticketId}` : props.isAdmin ? '/ticket-todolist/admin' : '/tr-dashboard'}`, {
                params: {
                    ...filter
                }
            })
            .then(res => {
                const data = res.data;
                if (props.type === 'detail') {
                    setTodoList(data);
                } else {
                    if (props.isAdmin) {
                        setTodoList(data)
                    } else {
                        setTodoList(data.tickets)
                    }
                    setNotifications(data.notifications)

                    if (permissions['dashboard-type'] === 'tenant-relation') {
                        const oldTodo = localStorage.getItem('todo-list')
                        if (oldTodo) {
                            localStorage.setItem('todo-list', JSON.stringify(data.tickets.filter((ticket: any) => ticket.due_time && ticket.due_date && isToday(new Date(ticket.due_date))).sort((a: any, b: any) => Number(a.due_time.split(':').join('')) - Number(b.due_time.split(':').join(''))).map((ticket: any) => {
                                const old = JSON.parse(oldTodo).filter((oldTd: any) => oldTd.id === ticket.id)[0]

                                return ({ ...ticket, is_notified: (old && ticket.due_time === old.due_time) ? old.is_notified : 0 })
                            })))
                        } else {
                            localStorage.setItem('todo-list', JSON.stringify(data.tickets.filter((ticket: any) => ticket.due_time && ticket.due_date && isToday(new Date(ticket.due_date))).sort((a: any, b: any) => Number(a.due_time.split(':').join('')) - Number(b.due_time.split(':').join(''))).map((ticket: any) => ({ ...ticket, is_notified: 0 }))))
                        }
                    }
                }

                if (typeof props.callBackTodo !== 'undefined') {
                    props.callBackTodo(data)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })

        if (refresh && props.type === 'detail' && permissions['dashboard-type'] === 'tenant-relation') {
            DefaultAxios
                .get(`${process.env.REACT_APP_API_URL}/tr-dashboard`)
                .then(res => {
                    const data = res.data;

                    if (permissions['dashboard-type'] === 'tenant-relation') {
                        const oldTodo = localStorage.getItem('todo-list')
                        if (oldTodo) {
                            localStorage.setItem('todo-list', JSON.stringify(data.tickets.filter((ticket: any) => ticket.due_time && ticket.due_date && isToday(new Date(ticket.due_date))).sort((a: any, b: any) => Number(a.due_time.split(':').join('')) - Number(b.due_time.split(':').join(''))).map((ticket: any) => {
                                const old = JSON.parse(oldTodo).filter((oldTd: any) => oldTd.id === ticket.id)[0]

                                return ({ ...ticket, is_notified: (old && ticket.due_time === old.due_time) ? old.is_notified : 0 })
                            })))
                        } else {
                            localStorage.setItem('todo-list', JSON.stringify(data.tickets.filter((ticket: any) => ticket.due_time && ticket.due_date && isToday(new Date(ticket.due_date))).sort((a: any, b: any) => Number(a.due_time.split(':').join('')) - Number(b.due_time.split(':').join(''))).map((ticket: any) => ({ ...ticket, is_notified: 0 }))))
                        }
                    }
                })
        }
    }

    const todoColumn: IColumn[] = [
        {
            name: 'due_date',
            label: 'Due Date',
            columnStyle: {
                textAlign: 'left',
            },
            render: (date: string, row: ITodoList) => {
                return renderDueDateStatus(date, row, '')
            }
        },
        {
            name: 'description',
            label: 'Deskripsi',
            columnStyle: {
                textAlign: 'left',
            }
        },
        {
            name: 'due_date',
            label: 'Status',
            columnStyle: {
                textAlign: 'left',
            },
            render: (date: string, row: ITodoList) => {
                return renderDueDateStatus(date, row, 'status')
            }
        },
        {
            name: 'created_by_name',
            columnStyle: {
                textAlign: 'left'
            },
            label: 'Created By',
        },
        {
            name: 'EXTRA',
            label: 'Action',
            columnProps: {
                align: 'right',
            },
            render: (row: ITodoList) => {
                return (
                    <div className={classes.actionContainer}>
                        {
                            row.completed_at ?
                                "-" :
                                <>
                                    <IconButton
                                        color="warning"
                                        component="span"
                                        title='Edit Todo'
                                        onClick={() => handleChangeTodo(row)}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        title='Complete Todo'
                                        onClick={() => handleCompleteTodo(row)}
                                    >
                                        <CheckIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        component="span"
                                        title='Delete Todo'
                                        onClick={() => handleDeleteTodo(row)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </>
                        }
                    </div>
                );
            }
        },
    ]

    const notificationColumn = [
        {
            name: 'created_at',
            label: 'Tanggal',
            columnStyle: { textAlign: 'left' },
            render: (data: any) => {
                if (data) return format(new Date(data), 'dd MMMM yyyy', { locale: id })
                return '-'
            }
        },
        {
            name: 'notification_text',
            label: 'Deskripsi',
            columnStyle: { textAlign: 'left' },
            render: (data: any, rows: INotify) => {
                if (data) return (
                    <Link
                        href={rows.url}
                        target="_blank"
                    >
                        {data}
                    </Link>
                )

                return "-"
            }
        },
    ]

    const todoColumnsDashboard: IColumn[] = [
        {
            name: 'due_date',
            label: 'Due Date',
            columnStyle: {
                textAlign: 'left'
            },
            render: (date: string, rows: ITodoList) => (
                renderDueDateStatus(date, rows, '')
            )
        },
        {
            name: 'ticket_code',
            label: 'Ticket Code',
            columnStyle: {
                textAlign: 'left'
            },
            render: (code: string, rows: any) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        rel="noopener noreferrer"
                        to={`/ticket/list/${rows.ticket_id}`}
                        target="_blank"
                    >
                        {code}
                    </Link>
                )
            }
        },
        {
            name: 'description',
            filterable: true,
            columnStyle: {
                textAlign: 'left'
            },
            label: 'Deskripsi',
        },
        {
            name: 'client_name',
            filterable: true,
            columnStyle: {
                textAlign: 'left'
            },
            label: 'Nama Client',
        },
        {
            name: 'unit_code',
            filterable: true,
            columnStyle: {
                textAlign: 'left'
            },
            label: 'Unit',
        },
        ...(props.isAdmin ? [{
            name: 'pic_name',
            filterable: true,
            columnStyle: {
                textAlign: 'left'
            },
            label: 'PIC',
        }] : []),
        {
            name: 'created_by_name',
            filterable: true,
            columnStyle: {
                textAlign: 'left'
            },
            label: 'Created By',
        },
        {
            name: 'EXTRA',
            label: 'Action',
            columnProps: {
                align: 'right',
            },
            render: (row: ITodoList) => {
                return (
                    <div className={classes.actionContainer}>
                        <IconButton
                            color="primary"
                            component="span"
                            title='Edit Due Date'
                            onClick={() => handleChangeTodo(row)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            color="primary"
                            component="span"
                            title='Complete Todo'
                            onClick={() => handleCompleteTodo(row)}
                        >
                            <CheckIcon fontSize="small" />
                        </IconButton>
                    </div>
                );
            }
        }

    ]

    const ticketDepositColumn: IDataTableColumn[] = [
        {
            name: 'code',
            label: 'Ticket Code',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/ticket/list/${row.id}`}
                        target='_blank'
                        rel='noopener norefferer'
                    >
                        {/* eslint-disable-next-line */}
                        {data}
                    </Link>
                );
            }
        },
        {
            name: 'unit_code',
            label: 'Kode Unit',
            filterable: true,
            sortable: false
        },
        {
            name: 'tenant_name',
            label: 'Nama Tenant',
            sortable: false,
            filterable: true,
        },
        {
            name: 'deposit_age',
            label: 'Deposit Age',
            type: 'number',
            filterable: true,
            render: (data: any, row: any) => {
                return data !== null ? `${data} days` : '-'
            }
        },
        {
            name: 'pic_name',
            label: 'PIC TR',
            sortable: false,
            filterable: true,
        }, 
    ]

    useEffect(() => {
        setRedrawDataTable(new Date().getTime())
    }, [todoList, props.hideComplete])

    useEffect(() => {
        if (props.addTodo) {
            setModalState('todo')
        }
    }, [props.addTodo])

    useEffect(() => {
        if (props.initialList) {
            setTodoList(props.initialList)
        } else if (props.type === 'dashboard' || (props.type === 'detail' && props.ticketId)) {
            loadTodo()
        }

        return () => {
            setTodoList([])
            setNotifications([])

            if (typeof props.callBackTodo !== 'undefined') {
                props.callBackTodo([])
            }
        }
        // eslint-disable-next-line
    }, [props.initialList, props.ticketId, props.type])

    useEffect(() => {
        if (Object.keys(filter).length) {
            loadTodo()
        }

        // eslint-disable-next-line
    }, [filter])

    return (
        <Root>
            <LoadingScreen open={isLoading} />
            {
                props.type === 'detail' ?
                    <SimpleDataTable
                        customData={props.hideComplete ? todoList.filter((todo) => !todo.completed_at) : todoList}
                        reDraw={redrawDataTable}
                        columns={todoColumn}
                        withPagination
                    /> :
                    <>
                        {
                            props.isAdmin ?
                                null :
                                <>
                                    <Typography variant='h6' sx={{ mb: 1 }}>Informasi</Typography>
                                    <SimpleDataTable
                                        columns={notificationColumn}
                                        customData={notifications}
                                        reDraw={redrawDataTable}
                                        limitHeight={389}
                                    />
                                </>
                        }
                        <Typography variant='h6' sx={{ mb: 1, mt: 2 }}>Tiket</Typography>
                        {
                            props.isAdmin ?
                                <Tabs value={ticketType} sx={{mb: 1}} onChange={(e, value) => setTicketType(value as typeof ticketType)}>
                                    <Tab value="general" label="General" />
                                    <Tab value="deposit" label="Deposit" />
                                </Tabs>
                                : null
                        }
                        {
                            ticketType === "general" ?
                                <SimpleDataTable
                                    customData={props.hideComplete ? todoList.filter((todo) => !todo.completed_at) : todoList}
                                    reDraw={redrawDataTable}
                                    columns={todoColumnsDashboard}
                                    filterable={true}
                                    onFilter={(filter) => setFilter(filter)}
                                    withPagination
                                /> :
                                <DataTable
                                    url={`${process.env.REACT_APP_API_URL}/ticket-deposit`}
                                    columns={ticketDepositColumn}                                    
                                />

                        }
                    </>
            }

            <TodoListModal
                open={modalState === 'todo'}
                ticketId={props.ticketId}
                id={selectedTodo ? selectedTodo.id : null}
                defaultState={selectedTodo as ITodoList}
                onClose={(refresh: boolean) => {
                    setModalState('')
                    setSelectedTodo(null)

                    if (refresh) loadTodo(true)

                }}
            />

            <ChangeDueDateModal
                open={modalState === 'due_date'}
                defaultState={selectedTodo}
                onClose={(refresh: boolean) => {
                    setModalState('')
                    setSelectedTodo(null)

                    if (refresh) loadTodo(true)
                }}
            />


        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    todoHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}), "TodoListTable"
)

export default TodoListTable