import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import GeneralModal from './GeneralModal'
import TextFieldSelect from '../_form/TextFieldSelect'
import AsyncAutoComplete, { IAutoCompleteOption } from '../_form/AsyncAutoComplete'
import MuiDatePicker from '../MuiDatePicker'

/**
 * Utils
 */
import { Grid, TextField } from '@mui/material'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'

interface GeneralFormModalProps {
    title: string
    open: boolean
    onClose: () => void
    onSubmit: (
        state: GeneralFormState,
        onFinal: () => void
    ) => void
    forms: Form[]
}

type Form = {
    name: string
    label: string
    placeholder?: string
    defaultValue: string | null | Date
    type: 'input' | 'textArea' | 'select' | 'datePicker' | 'autocomplete'
    /**
     * Process original value received from onChange
     * @param value original value that received from onChange
     * @returns processed value to be saved
     */
    processValue?: (value: string | null | Date) => string | null | Date
    // Autocompelte url
    url?: string
    // Autocomplete initial query
    initialQuery?: string
    // Select form options
    options?: {
        value: string
        label: string
        disabled?: boolean
    }[]
    required?: boolean
    /**
     * Extra validation other than required
     * @example otherValidation="email|min-char=3"
     */
    otherValidation?: string
    // Alias for validation
    alias?: string
    // Minimum rows for text area
    minRows?: number
}

export type GeneralFormState = {
    [key: string]: string | null | Date
}

const GeneralFormModal = (props: GeneralFormModalProps) => {
    const [isLoading, setIsLoading] = useState(false)

    // Get initial state based on the formst defaultValue
    const initialState = useMemo(() => props.forms.reduce((prev, next) => ({ ...prev, [next.name]: next.defaultValue }), {}), [props.forms])

    const [state, setState] = useState<GeneralFormState>(initialState)
    const [error, setError] = useState<IValidationErrors<GeneralFormState>>({})

    // Keep track wether the form has been edited or not
    const [hasEdited, setHasEdited] = useState(false)

    // Reset state, error and isLoading value if form is closed
    useEffect(() => {
        if (!props.open) {
            setState({})
            setError({})
            setIsLoading(false)
            setHasEdited(false)
        }
        // eslint-disable-next-line
    }, [props.open])

    // Handle dynamic value for defaultValue in form
    useEffect(() => {
        if (props.open && !hasEdited) setState(initialState)
    }, [props.open, hasEdited, initialState])

    // Generate form rules based on the required and otherValidation props
    const rules = useMemo(() => {
        const newRules: IValidationRules = {}

        props.forms.forEach(form => {
            if (form.required || form.otherValidation) {
                newRules[form.name] = `${form.required ? 'required' : ''}${form.otherValidation ? `${form.required ? '|' : ''}${form.otherValidation}` : ''}`
            }
        })

        return newRules
    }, [props.forms])

    // Generate form alias, fallback using the form name
    const alias = useMemo(() => {
        const newRules: IValidationAlias = {}

        props.forms.forEach(form => {
            if (form.alias) {
                newRules[form.name] = form.alias || form.name
            }
        })

        return newRules
    }, [props.forms])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, form: Form) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: form.processValue ? form.processValue(value) : value, // Process the value if needed
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))

        setHasEdited(true)
    }

    const handleAutocompleteChange = (name: string, option: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            [name]: option.id.toString(),
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))

        setHasEdited(true)
    }

    const handleAutocompleteInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setState(prev => ({
            ...prev,
            [name]: null,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))

        setHasEdited(true)
    }

    const handleDateChange = (name: string, date: Date | null) => {
        setState(prev => ({
            ...prev,
            [name]: date,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))

        setHasEdited(true)
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, rules, alias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)
            // Pass the validated state to the parent and pass the setIsLoading(false) function
            props.onSubmit(state, () => {
                setIsLoading(false)
            })
        }
    }

    const renderForm = (form: Form) => {
        switch (form.type) {
            case 'input':
                return (
                    <TextField
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        value={form.processValue ? form.processValue(state[form.name]) : state[form.name]}
                        onChange={e => handleInputChange(e, form)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                        fullWidth
                    />
                )
            case 'textArea':
                return (
                    <TextField
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        value={form.processValue ? form.processValue(state[form.name]) : state[form.name]}
                        onChange={e => handleInputChange(e, form)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                        fullWidth
                        multiline
                        minRows={form.minRows || 3}
                    />
                )
            case 'select':
                return (
                    <TextFieldSelect
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        value={form.processValue ? form.processValue(state[form.name]) : state[form.name]}
                        onChange={e => handleInputChange(e, form)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                        fullWidth
                    >
                        {
                            form.options!.map((option, index) => (
                                <option key={index} value={option.value} disabled={option.disabled}>{option.label}</option>
                            ))
                        }
                    </TextFieldSelect>
                )
            case 'autocomplete':
                return (
                    <AsyncAutoComplete
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        initialQuery={form.initialQuery}
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteInputChange}
                        errorText={error[form.name]}
                        url={form.url!}
                        disabled={isLoading}
                    />
                )
            case 'datePicker':
                return (
                    <MuiDatePicker
                        label={form.label}
                        value={state[form.name] as Date | null}
                        onChange={date => handleDateChange(form.name, date)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                    />
                )
            default: return 'Form type is not supported'
        }
    }

    return (
        <GeneralModal
            title={props.title}
            open={props.open}
            onClose={isLoading ? () => { } : props.onClose}
            buttons={[
                {
                    text: 'Submit',
                    color: 'primary',
                    onClick: handleSubmit,
                    isLoading,
                }
            ]}
            maxWidth='xs'
        >
            <Grid container spacing={2}>
                {
                    props.forms.map(form =>
                        <Grid item xs={12} key={form.name}>
                            {renderForm(form)}
                        </Grid>
                    )
                }
            </Grid>
        </GeneralModal>
    )
}

export default GeneralFormModal
