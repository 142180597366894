import { Grid, MenuItem, TextField, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

/**
 * Components
 */
import DateRangePicker, { OnDatesChange } from '../../_components/DateRangePicker'
import LoadingScreen from '../../_components/LoadingScreen'
import LoadingCard from '../../_components/_cards/LoadingCard'
import DataNA from '../../_components/DataNA'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { format } from 'date-fns'

/**
 * Context
 */
import { PermissionContext } from '../../_contexts/PermissionContext'
import { IActivity } from '../_activity/ActivitySchedule'
import ShowingScheduleCard from './_components/ShowingScheduleCard'


type Props = {}

interface IShowingScheduleFilter {
    start_date: Date | null,
    end_date: Date | null,
    group_id: string | number | null
}

const initialFilter = {
    start_date: null,
    end_date: null,
    group_id: null
}

interface ResData {
    activities: {
        // agent name
        [key: string]: {
            // date
            [key: string]: {
                agent_group: string
                agent_name: string
                customer_name: string
                apartment_name: string
                date: string
                datetime: string
                id: number
                time: string
            }[]
        }
    }
    date: string
}

export interface ParsedData {
    agent: string
    dates: {
        date: string
        times: {
            agent_group: string
            agent_name: string
            customer_name: string
            apartment_name: string
            datetime: string
            id: number
            time: string
        }[]
    }[]
}
const initialGroupOptions = [{ key: 'All', value: '', label: 'All' }]

const ShowingSchedule = (props: Props) => {
    const [filter, setFilter] = useState<IShowingScheduleFilter>(initialFilter)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [schedules, setSchedules] = useState<ParsedData[]>([])
    const [groupOptions, setGroupOptions] = useState(initialGroupOptions)

    const theme = useTheme()

    const permissions = useContext(PermissionContext)

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/showing/schedule`, {
            params: {
                ...filter,
                start_date: filter.start_date ? format(filter.start_date, 'yyyy-MM-dd') : null,
                end_date: filter.end_date ? format(filter.end_date, 'yyyy-MM-dd') : null,
            }
        })
            .then(res => res.data)
            .then((data: ResData) => {
                const newData: ParsedData[] = Object.entries(data.activities).map(([agent, dates]) => ({
                    agent,
                    dates: Object.entries(dates).map(([date, times]) => ({
                        date,
                        times,
                    }))
                }))
                setSchedules(newData)
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const fetchGroupList = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/advisor-group/all`)
            .then(res => res.data)
            .then((data: any) => {
                setGroupOptions([
                    ...initialGroupOptions,
                    ...data.map((group: { id: number, label: string }) => ({
                        key: group.id.toString(),
                        value: group.id.toString(),
                        label: group.label,
                    }))
                ])
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const handleChangeDate = ({ startDate, endDate }: OnDatesChange) => {
        setFilter(prev => ({
            ...prev,
            start_date: startDate,
            end_date: endDate
        }))
    }

    useEffect(() => {
        fetchData()

        // eslint-disable-next-line
    }, [filter.group_id])

    useEffect(() => {
        if (permissions['showing-schedule.see-all']) {
            fetchGroupList()
        }
    }, [permissions])

    // export interface IActivity {
    //     activity: string
    //     apartment_name: string
    //     client_name: string
    //     created_by_name: string
    //     id: number
    //     notes: string
    //     time: string
    //     unit_code: string
    // }

    const assignToActivity = (title: string | React.ReactNode, time: string, idx: number) => ({
        activity: '',
        apartment_name: title,
        client_name: '',
        created_by_name: '',
        id: idx,
        notes: '',
        time: time,
        unit_code: '',
    })

    const convertDateToActivity = (data: ParsedData['dates']): IActivity[] => {
        let result: IActivity[] = []
        let idx = 0;
        data.forEach(item => {
            result.push(assignToActivity(<span style={{ fontSize: 13, color: theme.palette.primary.main, fontWeight: '700' }}>{new Date(item.date).toLocaleDateString('id-ID', { weekday: 'long', day: 'numeric', month: 'long' })}</span>, '', idx))
            idx++
            item.times.forEach((time) => {
                result.push(assignToActivity(
                    <div>
                        <p style={{ fontSize: 11, fontWeight: '700', margin: 0 }}>{time.customer_name}</p>
                        <p style={{ fontSize: 11, margin: 0 }}>{time.apartment_name}</p>
                    </div>
                    , time.time, idx))
                idx++
            })
        })

        return result
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6} md={3}>
                            <DateRangePicker
                                startDate={filter.start_date}
                                endDate={filter.end_date}
                                onDatesChange={handleChangeDate}
                                onFinished={() => fetchData()}
                                fullWidth
                            />

                        </Grid>
                        {
                            permissions['showing-schedule.see-all'] &&
                            <Grid item xs={3} sx={{ ml: 'auto' }}>
                                <TextField
                                    onChange={(e) => setFilter(prev => ({ ...prev, group_id: e.target.value }))}
                                    label="Group"
                                    name='group_id'
                                    fullWidth
                                    value={filter.group_id ? filter.group_id : ''}
                                    SelectProps={{
                                        displayEmpty: true,
                                        notched: true
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size='small'
                                    select
                                >
                                    {
                                        groupOptions.map((group) => (
                                            <MenuItem key={group.value} value={group.value}>{group.label}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        {
                            isLoading ?
                                <>
                                    <Grid item xs={12} md={4}>
                                        <LoadingCard />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <LoadingCard />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <LoadingCard />
                                    </Grid>
                                </>
                                :
                                schedules.length ?
                                    schedules.map((schedule, idx) => (
                                        <Grid item xs={12} md={4} key={idx}>
                                            <ShowingScheduleCard
                                                noActivityText="Belum ada jadwal showing"
                                                key={idx}
                                                data={{
                                                    name: schedule.agent,
                                                    activities: convertDateToActivity(schedule.dates),
                                                    id: idx,
                                                    photo: ''
                                                }}
                                            />
                                        </Grid>
                                    )) :
                                    <Grid item xs={12} style={{ minHeight: '50%' }}>
                                        <DataNA type='Jadwal Showing' init={false} renderBody={(h5, p) => (
                                            <>
                                                <h5 className={h5}>Maaf Jadwal Showing tidak ditemukan</h5>
                                                <p className={p}>Silahkan sesuaikan filter tanggal</p>
                                            </>
                                        )} navigate={() => { }} />
                                    </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ShowingSchedule