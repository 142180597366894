import React, { useState } from "react"
import { Avatar, Theme, Grid, IconButton, Box, Typography } from "@mui/material"
import { convertIndonesiaPhoneNumber } from "../../../_utils/Helper";

/**
 * Components
*/
import ActivityDetailModal from "./ScheduleDetailModal";

/**
 * Icons
 */
import InfoIcon from '@mui/icons-material/Info';
import { AlarmOutlined, WhatsApp } from "@mui/icons-material";

/**
 * Interfaces
 */
import { IActivity, IData, initialRequestType } from "../ActivitySchedule";
import { generateStyle } from "../../../_utils/DefaultStyle";
import CardND from "../../../_components/_newDesignComponents/_card/CardND";
import { format } from "date-fns";

interface IProps {
    data: IData
    noActivityText?: string
    forShowing?: boolean
}

const ActivityScheduleCard = ({ data, forShowing, noActivityText }: IProps) => {
    const { Root, classes } = useStyles()
    const [detailModal, setDetailModal] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState<IActivity | null>(null)

    const getActivity = (activity: string) => {
        if (activity) {
            return initialRequestType.find(item => item.value === activity)?.label || '-'
        }

        return '-'
    }

    return (
        <Root>
            <CardND
                className={classes.card}
            >
                <Box className='header'>
                    <Avatar className='avatar' alt={data.name} src={data.photo} />
                    <Box className="profile">
                        <Typography variant='subtitle2' fontWeight={500}>{data.name}</Typography>
                    </Box>
                    {
                        data.phone_number ?
                            <IconButton
                                color="primary"
                                href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(data.phone_number)}`}
                                target="_blank"
                                rel="noopener norefferer"
                                sx={{ marginLeft: 'auto' }}
                                size='small'
                            >
                                <WhatsApp fontSize='small' />
                            </IconButton>
                            : null
                    }
                </Box>
                <Box
                    className='list'
                >
                    {
                        data.activities.length ?
                            data.activities.map((activity, index) =>
                                <CardND
                                    key={activity.id}
                                    elevation={0}
                                    className='schedule-card'
                                    sx={{ background: '#F7F8FA' }}
                                >
                                    <Grid container>
                                        <Grid item xs={9} display='flex' flexDirection='column' alignItems='flex-start'>
                                            <Typography variant='caption' fontWeight={600} sx={{ mb: 0.75 }} lineHeight={1.2}>
                                                {activity.apartment_name || '-'}
                                            </Typography>
                                            <Typography variant='caption' color='#484848' lineHeight={1.2}>
                                                {activity.unit_code || ''} - {getActivity(activity.activity)}
                                            </Typography>
                                            {
                                                activity.created_by_name ?
                                                    <Typography variant='caption' sx={{ mt: 0.75 }} color='#484848' lineHeight={1.2}>
                                                        Ordered by: {activity.created_by_name}
                                                    </Typography>
                                                    : null
                                            }
                                        </Grid>
                                        <Grid item xs={3} display='flex' flexDirection='column' alignItems='flex-end'>
                                            <Grid container direction={'row'}>
                                                <Grid item xs={12} display='flex' flexDirection='row' justifyContent='flex-end'>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => [setSelectedActivity(activity as IActivity), setDetailModal(true)]}
                                                        sx={{ fontSize: 18 }}
                                                        color='primary'
                                                    >
                                                        <InfoIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ pr: 1.25, mt: 'auto' }} display='flex' alignItems='center'>
                                                <AlarmOutlined sx={{ fontSize: 14 }} htmlColor={activity.time ? '#0080FE' : '#484848'} />
                                                <Typography variant='caption' color='#484848' lineHeight={1.2} sx={{ pl: 0.6 }}>
                                                {activity.time ? format(new Date(`${format(new Date(), 'yyy-MM-dd')} ${activity.time}`), 'HH:mm') : '-'}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardND>
                            )
                            :
                            <Typography variant='subtitle2' fontWeight={400} textAlign='center' sx={{ mt: 1 }}>No scheduled activity</Typography>
                    }
                </Box>
            </CardND>
            <ActivityDetailModal
                open={detailModal}
                activity={selectedActivity}
                onClose={() => setDetailModal(false)}
            />
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    card: {
        width: 400,
        minHeight: 'calc((100vh - 64px - 62px - 40px - 3vh) / 2)',
        overflow: 'hidden',
        marginBottom: 20,
        marginLeft: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        '& > .header': {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 10,
            '& > .profile': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
            },
            '& > .avatar': {
                width: 32,
                height: 32,
                borderRadius: 23,
                marginRight: 15,
            },
        },
        '& > .list': {
            maxHeight: 'calc(((100vh - 64px - 62px - 40px - 3vh) / 2) - 66px)',
            overflowY: 'auto',
            '& > .schedule-card': {
                padding: 10,
                paddingRight: 5,
                marginBottom: 10,
                borderRadius: 8,
                '& > .header': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
                '& .type-box': {
                    height: 20,
                    color: 'white',
                    fontSize: 12,
                    lineHeight: 1.2,
                    paddingLeft: 5.5,
                    paddingRight: 5.5,
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 10,
                },
            },
        },
        '&:nth-of-type(1), &:nth-of-type(2)': {
            marginLeft: 20,
        },
        '&:last-child, &:nth-last-of-type(1)': {
            marginRight: 20,
        },
    },
}), "ActivitySchedule_Card"
)

export default ActivityScheduleCard