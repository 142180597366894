import { Button, Grid, TextField, MenuItem } from '@mui/material'
import React, { useContext, useState } from 'react'
import DateRangePicker, { DateRange, OnDatesChange } from '../../_components/DateRangePicker'
import { addMinutes, endOfWeek, startOfWeek, subWeeks, } from 'date-fns'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import LoadingScreen from '../../_components/LoadingScreen'
import { PermissionContext } from '../../_contexts/PermissionContext'

interface IDate {
    start_date: DateRange
    end_date: DateRange
}

const reportOptions = [
    { label: "Pilih Report", value: "" },
    { label: "Active Tenant", value: "active-tenant" },
    { label: "Active Tenant Extended Info", value: "active-tenant-extended-info" },
    { label: "Informasi Titip Kunci per Apartemen", value: "entrusted-key-information"},
    { label: "Created Unit", value: "created-unit"},
    { label: "Expired Closing Bulan Sebelumnya", value: "expired-closing-last-month" },
    { label: "Expired Closing Bulan Ini", value: "expired-closing-this-month" },
    { label: "Pelunasan Closing Bulan Ini", value: "payment-closing-this-month" },
    { label: "Pelunasan Closing Bulan Sebelumnya", value: "payment-closing-last-month" },
    { label: "Deposit Bulan Ini", value: "deposit-closing-this-month" },
    { label: "Checkout Closing", value: "checkout-closing" },
    { label: "Tiket Deposit Bulan Ini", value: "ticket-deposit-this-month" },
    { label: "Tiket Deposit Bulan Sebelumnya", value: "ticket-deposit-last-month" },
    { label: "Reimburse dan Checkout Bulan Ini", value: "reimbursement-and-checkout-this-month" },
    { label: "Reimburse dan Checkout Bulan Sebelumnya", value: "reimbursement-and-checkout-last-month" },
    { label: "Ticket Done tipe Greeting dan Lainnya Bulan Ini", value: "finished-ticket-greeting-and-other-this-month" },
    { label: "Ticket Done tipe Greeting dan Lainnya Bulan Sebelumnya", value: "finished-ticket-greeting-and-other-last-month" },
    { label: "Billing Collection Bulan Ini", value: "billing-collection-this-month" },
    { label: "Billing Collection Bulan Sebelumnya", value: "billing-collection-last-month" },
    { label: "List Unit Dibuat", value: "inputted-unit-apartment" },
    { label: "Listing Submission", value: "listing-submission" },
    { label: "Enquiry PM Bulan Ini", value: "enquiry-pm-this-month" },
    { label: "Enquiry PM Bulan Sebelumnya", value: "enquiry-pm-last-month" },
    { label: "Showing PM Bulan Ini", value: "showing-pm-this-month" },
    { label: "Showing PM Bulan Sebelumnya", value: "showing-pm-last-month" },
    { label: "Enquiry Telesales Bulan Ini", value: "enquiry-telesales-this-month" },
    { label: "Enquiry Telesales Bulan Sebelumnya", value: "enquiry-telesales-last-month" },
    { label: "Showing Telesales Bulan Ini", value: "showing-telesales-this-month" },
    { label: "Showing Telesales Bulan Sebelumnya", value: "showing-telesales-last-month" },
    { label: "Enquiry Showing & Closing Rate", value: "enquiry-showing-closing-rate" },
    { label: "Activity Showing", value: "activity-showing" },
    { label: "Checkout Reason", value: "checkout-reason" },
    { label: "JHS Order", value: "jhs-order" },
    { label: "Report Fixing And Cleaning JHS", value: "fixing-and-cleaning-jhs" },
    { label: "Failed Listing Submission Apartment", value: "rejected-listing-apartment-submission"},
    { label: "Closing Data General", value: "general-closing-data" },
    { label: "Enquiry General", value: "enquiry-general" },
    { label: "Showing General", value: "showing-general" },
    { label: "Order JHS Paid By Deposit", value: "order-jhs-paid-by-deposit" },
    { label: "Order JHS Set Unpaid", value: "order-jhs-unpaid-at" },
    { label: "Data Propose Deposit", value: "reimburse-client" },
]

const timeZoneId = +7 * 60

const initialDate = {
    start_date: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
    end_date: endOfWeek(new Date(), { weekStartsOn: 1 })
}

const DownloadReportGeneral = () => {
    const [date, setDate] = useState<IDate>(initialDate)
    const [report, setReport] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const permissions = useContext(PermissionContext)

    const handleChangeDate = ({ startDate, endDate }: OnDatesChange) => {
        setDate(prev => ({
            ...prev,
            start_date: startDate,
            end_date: endDate
        }))
    }

    const handleDownload = () => {
        setIsLoading(true)

        if (!report) {
            alert('Harap pilih Report yang mau didownload terlebih dahulu');
        } else {
            DefaultAxios
                .post(`${process.env.REACT_APP_API_URL}/tools/download-report-general/${report}`, {
                    start_date: addMinutes(date.start_date as Date, timeZoneId),
                    end_date: addMinutes(date.end_date as Date, timeZoneId),
                }, { responseType: 'blob' })
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `report-${report}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReport(e.target.value);
    }

    return (
        <>
            <LoadingScreen open={isLoading} />
            <Grid container>
                <Grid item md={4} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Report"
                                name="report"
                                value={report}
                                onChange={handleChange}
                            >
                                {reportOptions.map(item => {
                                    if (permissions[`report.${item.value}`]) {
                                        return <MenuItem value={item.value} key={item.value} >{item.label}</MenuItem>
                                    }
                                    
                                    return null
                                })}
                            </TextField>
                        </Grid>
                        {!['active-tenant', 'active-tenant-extended-info', 'entrusted-key-information'].includes(report) && (!report.includes('this-month') && !report.includes('last-month')) &&
                            <Grid item xs={12}>
                                <DateRangePicker
                                    startDate={date.start_date}
                                    endDate={date.end_date}
                                    onDatesChange={handleChangeDate}
                                    onFinished={() => null}
                                    format="dd/MM/yyyy"
                                    fullWidth
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                onClick={handleDownload}
                                fullWidth

                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DownloadReportGeneral