import React, { useEffect, useState } from 'react'

import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    Divider,
} from '@mui/material';

import { Grid } from '@mui/material';
import Rank1 from "../../Assets/Images/svg/rank1.svg"
import Rank2 from "../../Assets/Images/svg/rank2.svg"
import Rank3 from "../../Assets/Images/svg/rank3.svg"
import { converNumberFormat, generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';

interface AgentRanking {
    name: string;
    avatar_url: string;
    nominal: number;
}

interface FilterOption {
    id: number;
    name: string;
    group: 'type' | 'is_highend';
    isSelected?: boolean;
}


const useStyles = generateStyle((theme) => ({
    tableContainer: {
        position: 'relative',
        flex: 1,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 200px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
    },
    sidebarContainers: {
        width: '263px',
        flexShrink: 0,
        marginLeft: -10,
        paddingRight: 25
    },
    title: {
        fontSize: '30px',
        color: '#484848',
        fontWeight: 700,
        marginLeft: 30
    },
    subtitle: {
        color: '#484848',
        fontSize: '18px',
        marginLeft: 30
    },
    filterTitle: {
        fontSize: '18px',
        fontWeight: 700,
        paddingLeft: '30px',
        paddingTop: 10
    },
    listItem: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        marginTop: 10,
        marginBotom: 10,
        fontSize: 14,
        color: "#484848",
        cursor: 'pointer',
        '&.selected': {
            backgroundColor: '#00A1DF',
            color: '#ffffff',
            borderRadius: 12,
            fontWeight: 'bold',
            width: "100%"
        },
        '&:hover': {
            backgroundColor: 'rgba(3, 90, 166, 0.08)',
            borderRadius: 12,
        },
    },
    divider: {
        marginRight: 45,
        marginLeft: 45,
        marginTop: 10
    },
    sidebar: {
        borderRadius: '12px',
        backgroundColor: "#EFF7FE",
        marginTop: 40,
        paddingTop: 10,
        paddingBottom: 20,
        height: 350
    },
    filterSection: {
        flex: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        backgroundColor: "#EFF7FE"
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    table: {
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    tableHead: {
        height: 50,
        // position: 'relative',
        zIndex: 999,
        backgroundColor: '#00A1DF',
        '& th:first-of-type': {
            borderTopLeftRadius: '12px',
        },
        '& th:last-of-type': {
            borderTopRightRadius: '12px',
        },
        overflow: 'hidden'
    },
    tableWrapper: {
        flex: 1,
        marginTop: 13,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 100px)',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        '&:after': {
            content: '""',
            height: '50px',
            background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
            pointerEvents: 'none',
        },
    },
    scrollableSection: {
        height: '300px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '8px',
        },
        // Untuk browser yang mendukung scrollbar selain Webkit
        '&::-moz-scrollbar': {
            display: 'none',
        },
    },
    agentInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}), 'TopSellerList');

// Sample Data
const filterOptions: FilterOption[] = [
    { id: 0, name: 'TOP NOC', isSelected: true, group: 'type' },
    { id: 1, name: 'TOP REVENUE', group: 'type' },
    { id: 0, name: 'Regular', isSelected: true, group: 'is_highend' },
    { id: 1, name: 'Highend', group: 'is_highend' },
];

const TopSellerList = () => {
    const { Root, classes } = useStyles();

    const [filterOption, setFilterOption] = useState<FilterOption[]>(filterOptions);
    const [agents, setAgents] = useState<AgentRanking[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const currentDate = new Date();
    const monthYear = new Intl.DateTimeFormat('id-ID', {
        month: 'long',
        year: 'numeric',
    }).format(currentDate);

    useEffect(() => {
        fetchAgentData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOption])

    const fetchAgentData = async () => {
        try {
            setLoading(true);
            const response = await DefaultAxios.get(`${process.env.REACT_APP_API_URL}/ranking-agent`, {
                params: {
                    type: filterOption.find(option => option.group === 'type' && option.isSelected)?.id || 0,
                    is_highend: filterOption.find(option => option.group === 'is_highend' && option.isSelected)?.id || null,
                },
            });

            // Menangani respons dan memformat data agent
            const formattedAgents = response.data.map((agent: any) => {
                return {
                    name: agent.name,
                    avatar_url: agent.avatar_url,
                    nominal: agent.nominal,
                };
            });

            setAgents(formattedAgents);
        } catch (error) {
            generalErrorHandler(error)
        } finally {
            setLoading(false);
        }
    };

    const getBackgroundColor = (agentId: number) => {
        if (agentId === 0) return '#99D9F2';
        if (agentId === 1) return '#B2E2F5';
        if (agentId === 2) return '#CCECF9';
    };

    const toggleGroupOneSelection = (id: number) => {
        setFilterOption((prevOptions) =>
            prevOptions.map((option) =>
                option.group === 'type'
                    ? { ...option, isSelected: option.id === id }
                    : option
            )
        );
    };

    const toggleGroupTwoSelection = (id: number) => {
        setFilterOption((prevOptions) =>
            prevOptions.map((option) =>
                option.group === 'is_highend'
                    ? { ...option, isSelected: option.id === id }
                    : option
            )
        );
    };

    return (
        <Root>
            <LoadingScreen open={loading} />
            <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
                <div className={classes.sidebarContainers}>
                    <Box>
                        <Typography variant="h6" className={classes.title}>
                            Top Agent
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            {monthYear}
                        </Typography>
                    </Box>
                    <Box className={classes.sidebar}>
                        <Typography className={classes.filterTitle}>Filter By</Typography>

                        <List sx={{ marginRight: '30px', marginLeft: '30px' }}>
                            {filterOption.filter(option => option.group === 'type').map((option) => (
                                <React.Fragment key={option.name}>
                                    <ListItem
                                        className={`${classes.listItem} ${option.isSelected ? 'selected' : ''}`}
                                        onClick={() => toggleGroupOneSelection(option.id)}
                                    >
                                        <ListItemText
                                            primary={
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        fontWeight: option.isSelected ? 'bold' : 'normal',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {option.name}
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>

                        <Divider className={classes.divider} />

                        <div className={classes.scrollableSection}>
                            <List sx={{ marginRight: '30px', marginLeft: '30px' }}>
                                {filterOption.filter(option => option.group === 'is_highend').map((option) => (
                                    <React.Fragment key={option.name}>
                                        <ListItem
                                            className={`${classes.listItem} ${option.isSelected ? 'selected' : ''}`}
                                            onClick={() => toggleGroupTwoSelection(option.id)}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            fontWeight: option.isSelected ? 'bold' : 'normal',
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {option.name}
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                        </div>
                    </Box>
                </div>
                <div className={classes.mainContent}>
                    <TableContainer component={Box} className={classes.tableWrapper}>
                        <Table stickyHeader sx={{
                            overflowY: 'hidden',
                        }}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            color: "#ffffff",
                                            fontSize: 16,
                                            padding: '8px 0px 8px 24px',
                                            backgroundColor: '#00A1DF',
                                        }}
                                        width={90}
                                    >
                                        Position
                                    </TableCell>
                                    <TableCell sx={{
                                        fontWeight: 'bold',
                                        color: "#ffffff",
                                        fontSize: 16,
                                        padding: '8px 24px',
                                        backgroundColor: '#00A1DF'
                                    }}>
                                        Agents
                                    </TableCell>
                                    <TableCell sx={{
                                        fontWeight: 'bold',
                                        color: "#ffffff",
                                        fontSize: 16,
                                        padding: '8px 24px',
                                        backgroundColor: '#00A1DF'
                                    }} align="right">
                                        Value
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agents.length > 0 ? (
                                    agents.map((agent, id: number) => (
                                        <TableRow key={id} sx={{ background: getBackgroundColor(id) }}>
                                            <TableCell
                                                sx={{
                                                    padding: '8px 0px 8px 24px',
                                                }}
                                                width={90}
                                            >
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: id === 0 || id === 1 ? 20 : 16,
                                                            fontWeight: id === 0 || id === 1 || id === 2 ? 700 : 400,
                                                            color: "#484848",
                                                        }}
                                                    >
                                                        {id + 1}.
                                                    </Typography>
                                                    {id === 0 && <img src={Rank1} alt="Rank 1" style={{ marginLeft: 18 }} />}
                                                    {id === 1 && <img src={Rank2} alt="Rank 2" style={{ marginLeft: 18 }} />}
                                                    {id === 2 && <img src={Rank3} alt="Rank 3" style={{ marginLeft: 22 }} />}
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{
                                                padding: '8px 24px',
                                            }}>
                                                <Box className={classes.agentInfo} sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <Avatar src={agent.avatar_url} />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography sx={{
                                                            fontSize: id === 0 || id === 1 ? 20 : 16,
                                                            fontWeight: 700,
                                                            color: "#484848",
                                                            // marginLeft: 1
                                                        }}>
                                                            {agent.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right" sx={{ padding: '8px 24px' }}>
                                                <Typography sx={{
                                                    fontSize: id === 0 || id === 1 ? 20 : 16,
                                                    fontWeight: 700,
                                                    color: "#484848"
                                                }}>
                                                    {filterOption.some(option => option.name === 'TOP NOC' && option.isSelected)
                                                        ? converNumberFormat(String(agent.nominal)).replace(/,/g, ".")
                                                        : `Rp ${converNumberFormat(String(agent.nominal)).replace(/,/g, ".")}`}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <Typography sx={{ color: '#484848' }}>
                                        Tidak ada data
                                    </Typography>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Grid>
        </Root >
    )
}

export default TopSellerList