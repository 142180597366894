import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Theme,
    Tooltip,
    TextField
} from '@mui/material';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import WhatsappPreviewModal from '../../_components/_modal/WhatsappPreviewModal';
import ListingSubmissionApartmentModalDetail from '../../_pages/_listingSubmissionApartment/ListingSubmissionApartmentModalDetail';
import { IChats } from '../../_components/WhatsappPreview';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import { Check, Edit, Visibility, WhatsApp } from '@mui/icons-material';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, isMobile, renderToastSuccess } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

import ListingSubmissionApartmentModalFailed from '../_listingSubmissionApartment/ListingSubmissionApartmentModalFailed';

interface IProps {
    rent_id?: string;
    reload?: boolean | undefined;
    closeModal?: boolean | undefined;
}

interface IParams extends Record<string, string | undefined> {
    submission_code?: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    edit_button: {
        // backgroundColor: '#fff',
        color: '#fff'
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}), "RentApartmentlist"
);

const initialChatsData = {
    chats: [],
    code: '',
    created_at: ''
}

export default function ListingSubmissionSellList(props?: IProps) {
    const permissions = useContext(PermissionContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const RENT_URL = process.env.REACT_APP_API_URL + '/admin-flipper/listing-submission';
    const [openDetail, setOpenDetail] = useState<boolean>(false)
    const [selectedNotesRow, setSelectedNotesRow] = useState<null | any>(null)
    const [openFailed, setOpenFailed] = useState(false)
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const [queryString, setQueryString] = useState('')
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());


    const params = useParams<IParams>()

    const [sellId, setSellId] = useState<string>('');
    const [submissionCode, setSubmissionCode] = useState('')

    const [chatsData, setChatsData] = useState<{
        chats: IChats[],
        code: string | null,
        created_at: string
    }>(initialChatsData)

    const handleEdit = (data: any) => {
        navigate('/listing-submission-apartment/edit/' + data.id)
    }

    const handleCloseDetail = (isRefresh?: boolean) => {
        window.history.pushState([], 'Listing Submission (Sell)', `/listing-submission-sell/${queryString ? `?${queryString}` : ''}`);
        if (isRefresh) setReDrawDatatable(new Date().getTime())
        setOpenDetail(false)
        setSellId('')
        setSubmissionCode('')
    }

    useEffect(() => {
        if (typeof params.submission_code === 'string') {
            setOpenDetail(true);
            setSubmissionCode(params.submission_code)
            window.history.pushState([], 'Listing Submission (Sell)', `/listing-submission-sell/${params.submission_code}`);
        }
    }, [params]);

    const FormFailedToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleFailedClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Sell Failed
                </Typography>
            </Toolbar>
        )
    }

    const onNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setSelectedNotesRow((prev: any) => ({
            ...prev,
            admin_flipper_notes: value,
        }))
    }

    const saveNewNotes = (rows: any, setRows: any) => {
        if (!selectedNotesRow) return;

        const data = {
            id: selectedNotesRow.id,
            type: "listing_submission",
            admin_flipper_notes: selectedNotesRow.admin_flipper_notes,
        }

        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/admin-flipper/notes`, data)
            .then(res => res.data)
            .then(() => {
                setSelectedNotesRow(null)

                renderToastSuccess('Berhasil mengubah notes')
                const newRows = rows.map((element: any) => {
                    if (+element.id === +data.id) element['notes'] = data.admin_flipper_notes;
                    return element
                })
                setReDrawDatatable(new Date().getTime())
                setRows(newRows);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFailedClose = () => {
        setOpenFailed(false)
    };

    const handleWhatsappPreview = (row: any) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/unit`, { params: { id: row.id } })
            .then(res => res.data)
            .then(res => {
                setChatsData({
                    chats: res,
                    code: row.code,
                    created_at: row.created_at
                })
            })
            .catch(err => generalErrorHandler(err))
    }

    const column = [
        {
            name: 'submission_at',
            label: 'Submission Time',
            type: 'date',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return format(new Date(data), 'dd LLL yyyy HH:mm:ss')
            }
        },
        ...(
            permissions['whatsapp.manage-listing-submission'] ?
                [{
                    name: 'wa_id',
                    label: 'Whatsapp Preview',
                    type: 'string',
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <>
                                    <Tooltip title="Preview Chat">
                                        <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row)} color='primary'>
                                            <WhatsApp />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ),
        {
            name: 'submission_code',
            label: 'Code',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <>
                        <a
                            href={`/listing-submission-sell/${row.submission_code}`}
                            className={classes.link}
                            onClick={e => {
                                e.preventDefault()
                                setSellId(row.id)
                                setSubmissionCode(row.submission_code)
                                window.history.pushState([], 'Listing Submission (Sell)', `/listing-submission-sell/${row.submission_code}`);
                                setOpenDetail(true)
                            }}
                        >
                            {data}
                        </a>
                        <div>{row.status === 1 ? `[${row.submission_code}]` : ''}</div>
                    </>
                );
            }
        },
        {
            name: 'unit_code',
            label: 'Kode Unit',
            filterable: true,
            type: 'string',
            render: (data: string) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{data === null ? "-" : data}</span>
                        {data === null ? null :
                            <IconButton
                                href={`${process.env.REACT_APP_MAIN_URL}/${data}`}
                                target='_blank'
                                color='primary'
                            >
                                <Visibility />
                            </IconButton>
                        }
                    </div>
                );
            },
        },
        {
            name: 'apartment_name',
            label: 'Apartemen',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'unit_detail',
            label: 'Unit Detail',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'furnished',
            label: 'Furnish',
            filterable: true,
            type: 'string',
            options: [
                { key: '', value: 'All' },
                { key: 'full', value: 'Full Furnished' },
                { key: 'semi', value: 'Semi Furnished' },
                { key: 'non', value: 'Non Furnished' },
            ],
            render: (data: string) => {
                switch (data) {
                    case 'non':
                        return 'Non Furnished';
                    case 'semi':
                        return 'Semi Furnished';
                    case 'full':
                        return 'Full Furnished';
                    default:
                        return '';
                }
            }
        },
        {
            name: 'view_direction',
            label: 'View',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'unit_area',
            label: 'Unit Area',
            filterable: true,
            type: 'number',
            render: (data: number) => data || '-',
        },
        {
            name: 'area_name',
            label: 'Area',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'requester_name',
            label: 'Requester Name',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'requester_phone',
            label: 'Requester Phone',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'admin_flipper_notes',
            label: 'Remarks',
            filterable: true,
            columnStyle: {
                minWidth: 200,
            },
            render: (data: string, row: any, rows: any, index: number, setRows: any) => {
                return (
                    selectedNotesRow?.id === row.id ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                value={selectedNotesRow?.admin_flipper_notes || ''}
                                onChange={onNotesChange}
                                disabled={isLoading}
                                size='small'
                                variant='standard'
                                style={{
                                    maxWidth: 'calc(100% - 30px)',
                                }}
                                multiline
                            />
                            <IconButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveNewNotes(rows, setRows);
                                }}
                                size='small'
                                disabled={isLoading}
                            >
                                <Check fontSize='small' color='success' />
                            </IconButton>
                        </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {data || '-'}
                            </span>
                            {permissions['enquiry-buy.manage'] &&
                                <IconButton
                                    onClick={() => setSelectedNotesRow(row)}
                                    size='small'
                                    style={{ verticalAlign: 'top' }}
                                >
                                    <Edit fontSize='small' color='primary' />
                                </IconButton>
                            }
                        </div>
                );
            }
        },

    ]

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <ListingSubmissionApartmentModalDetail
                open={openDetail}
                onClose={handleCloseDetail}
                onEdit={handleEdit}
                rent_id={sellId || undefined}
                submissionCode={submissionCode || undefined}
                parentQueryString={queryString}
            />

            {openFailed ?
                <Dialog
                    open={openFailed}
                    closeAfterTransition
                    fullWidth
                    maxWidth="md"
                    fullScreen={isMobile() ? true : false}
                // onClose={handleFailedClose}
                // TransitionComponent={Transition}
                >
                    <Root>
                        <AppBar className={classes.appBar}>
                            <FormFailedToolbar></FormFailedToolbar>
                        </AppBar>
                        <ListingSubmissionApartmentModalFailed
                            rentDetail_id={sellId}
                            open={openFailed}
                            closeModal={() => setOpenFailed(false)}
                            successCallback={() => {
                                setReDrawDatatable(new Date().getTime())
                            }}
                        />
                    </Root>
                </Dialog>
                : <></>
            }

            <DataTable
                reDraw={reDrawDatatable}
                setParentQueryString={setQueryString}
                url={RENT_URL}
                columns={column}
            />

            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(initialChatsData)
                }}
            />
        </Root>
    );
}