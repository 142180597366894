import React, { useState, useReducer, useEffect, useContext, useMemo } from 'react';
import {
    Theme,
    Grid,
    TextField,
    Button,
    Typography,
    IconButton,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency, generalErrorHandler } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

/**
 * Icons
 */
import { Cancel } from '@mui/icons-material';

interface Props {
    onSubmitSuccess?: () => void,
    provinceId?: string
}

export interface IFiles {
    id: number
    url: string
    file: File
}

interface IState {
    attachments: any[];
    attachments_add: null | FileList;
    title: string;
    closing_id: string;
    closing_name: string;
    closing_label: string;
    pic_id: string;
    pic_name: string;
    details: string[];
    type: string;
    priority: number;
    file: {
        file: File
    }[],
    rent_bill: string,
    water_electricity_bill: string
    other_bill: string
    other_bill_notes: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) =>
({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(0, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    fileList: {
        listStyle: 'none',
        padding: 0,
        '& li': {
            display: 'flex'
        }
    },
    filename: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    }
}), "TicketSystemForm"
)

const TicketSystemForm = (props: Props) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/ticket';
    const [loading, setLoading] = useState(false);
    const params: any = useParams();
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false)
    const [isShowPicForm, setIsShowPicForm] = useState(true)

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const initialState = {
        title: '',
        attachments: [],
        attachments_add: null,
        closing_id: '',
        closing_name: '',
        closing_label: '',
        pic_id: '',
        pic_name: '',
        details: [''],
        type: '',
        priority: 0,
        file: [],
        rent_bill: '',
        water_electricity_bill: '',
        other_bill: '',
        other_bill_notes: ''
        // file: null
    }

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const isCollectionOnly = useMemo(() => {
        setInputState({ value: 'Collection', name: 'type', type: "SET_ITEM" })
        return permissions['ticket.collection-only']
    }, [permissions])

    const handleInputChanged = (e: any, index?: number) => {
        const convertCurrencyToNumberList = ['rent_bill', 'water_electricity_bill', 'other_bill']
        const target = e.target;
        if (target) {
            let value: string | { file: File | null } = e.target.value
            const files = target.files as FileList;
            if (target.name === 'file') {
                if (target.files && target.files) {
                    let newPhotos = []
                    for (let i = 0; i < files.length; i++) {
                        newPhotos.push({
                            file: target.files[i],
                        })
                    }
                    setInputState({ name: target.name, value: newPhotos, type: 'SET_ITEM' });
                }
            } else if (target.name === 'type') {
                if (value !== 'Collection') {
                    setInputState({
                        type: 'REPLACE_STATE',
                        value: {
                            ...inputState,
                            rent_bill: '',
                            water_electricity_bill: '',
                            other_bill: '',
                            other_bill_notes: ''
                        },
                        name: ''
                    })
                }

                if (value === 'Deposit') {
                    return setInputState({
                        type: 'REPLACE_STATE',
                        value: {
                            ...inputState,
                            pic_id: isEdit ? inputState.pic_id : '',
                            title: '',
                            type: value
                        },
                        name: ''
                    })
                }
                setInputState({ name: 'type', value: value, type: 'SET_ITEM' });
            } else if (convertCurrencyToNumberList.includes(target.name)) {
                const number = (value as string).replace(/[^0-9]/g, '')
                const extraData: any = {}

                extraData[target.name] = number
                if (target.name === 'other_bill') {
                    if (!number) {
                        extraData['other_bill_notes'] = ''
                    }
                }

                setInputState({
                    name: '',
                    value: {
                        ...inputState,
                        ...extraData
                    },
                    type: 'REPLACE_STATE'
                });
            } else {
                setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }
    }

    const handleAddDetail = () => {
        const newDetails = [...inputState.details]
        newDetails.push('')

        setInputState({ name: 'details', value: newDetails, type: 'SET_ITEM' });
    }

    const handleDetailChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { value } = e.target

        const newDetails = [...inputState.details]
        newDetails[index] = value

        setInputState({ name: 'details', value: newDetails, type: 'SET_ITEM' });
    }

    const handleRemoveDetail = (index: number) => {
        const newDetails = [...inputState.details].filter((_, jndex) => index !== jndex)

        setInputState({ name: 'details', value: newDetails, type: 'SET_ITEM' });
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files, value } = e.target

        if (value) {
            if (files && files.length > 0) {
                setInputState({ name, value: files, type: 'SET_ITEM' });
            }
        } else {
            setInputState({ name, value: null, type: 'SET_ITEM' });
        }
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleAutocompletePic = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name: 'pic_id', value: value.id.toString(), type: 'SET_ITEM' });
        setInputState({ name: 'pic_name', value: value.label, type: 'SET_ITEM' });
    }

    const handleAutocompleteClosing = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name: 'closing_id', value: value.id.toString(), type: 'SET_ITEM' });
        setInputState({ name: 'closing_label', value: value.label, type: 'SET_ITEM' });
        setInputState({ name: 'closing_name', value: value.label, type: 'SET_ITEM' });
    }

    const submitForm = () => {
        let axios;

        const fd = new FormData();

        for (let key in inputState) {
            switch (key) {
                case 'title':
                    if (!['Deposit', 'Renewal', 'Renewal Termin'].includes(inputState.type)) {
                        const value = String(inputState[key as keyof IState]);

                        fd.append(key, value !== "null" ? value : "");
                    }
                    break
                case 'file':
                case 'attachments_add':
                    if (inputState.file?.length) {
                        inputState.file.forEach(file => {
                            fd.append('file[]', file.file as File)
                        })
                    }
                    if (inputState.attachments_add && inputState.attachments_add.length) {
                        Array.from(inputState.attachments_add).forEach((file: File, index: number) => {
                            fd.append(`attachments[${index}]`, file)
                        })
                    }
                    break
                case 'details':
                    inputState.details.forEach(detail => {
                        fd.append('details[]', detail)
                    })
                    break
                default:
                    const value = String(inputState[key as keyof IState]);

                    fd.append(key, value !== "null" ? value : "");
                    break
            }
        }

        if (params.ticket_id) {
            fd.append('_method', 'PUT');
            axios = DefaultAxios.post(`${API_URL}/${params.ticket_id}`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Ticket Updated",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(res => {
                            navigate(`/ticket/list/${params.ticket_id}`);
                        });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setLoading(true);
            axios
                .then(res => {
                    const ticketId = res.data.id
                    Swal.fire({
                        title: "Ticket Submitted",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(res => {
                            localStorage.setItem('from-ticket-add', '1')
                            navigate(`/ticket/list/${ticketId}`);
                        });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    const loadData = () => {
        if (params.closing_code) {
            setLoading(true);
            DefaultAxios
                .get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/closing?search=${params.closing_code}`)
                .then(res => {
                    const data = res.data[0];
                    setInputState({ name: 'closing_label', value: data.label, type: 'SET_ITEM' });
                    setInputState({ name: 'closing_id', value: data.id, type: 'SET_ITEM' });
                    setInputState({ name: 'closing_name', value: data.label, type: 'SET_ITEM' });
                    setLoading(false);
                })
        }
        if (params.ticket_id) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.ticket_id}`)
                .then(res => {
                    const data = res.data;
                    const billingData = {
                        rent_bill: res.data.rent_bill !== null ? String(res.data.rent_bill) : '',
                        water_electricity_bill: res.data.water_electricity_bill !== null ? String(res.data.water_electricity_bill) : '',
                        other_bill: res.data.other_bill !== null ? String(res.data.other_bill) : '',
                    }
                    const imageGallery = res.data.attachments;
                    const newImageStates = [];
                    for (let key in imageGallery) {
                        newImageStates.push({
                            filename: imageGallery[key].filename,
                            filepath: imageGallery[key].filepath,
                        })
                    }
                    setIsEdit(true)
                    setInputState({ name: '', value: { ...data, ...billingData }, type: 'REPLACE_STATE' });
                    setInputState({ name: 'file', value: [], type: 'SET_ITEM' });
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEdit) {
            setIsShowPicForm(true)
        } else if (['Deposit', 'Renewal', 'Renewal Termin'].includes(inputState.type)) {
            setIsShowPicForm(false)
        } else if (inputState.type === 'Collection') {
            if (permissions['ticket.create-pic-required'] || permissions['ticket.admin']) {
                setIsShowPicForm(true)
            } else {
                setIsShowPicForm(false)
            }
        } else if (permissions['ticket.admin']) {
            setIsShowPicForm(true)
        } else {
            setIsShowPicForm(false)
        }
    }, [isEdit, inputState.type, permissions])

    return (
        <Root>
            {/* <Dialog
                open={modalImage}
                onClose={handleCloseImg}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Card style={{ padding: 0 }} variant="outlined">
                    <CardContent style={{ padding: 0 }} >
                        <img src={modalImageUrl} alt="" width="100%" />
                    </CardContent>
                </Card>
            </Dialog> */}
            <LoadingScreen open={loading} fullScreen />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Type"
                        name="type"
                        value={inputState.type}
                        onChange={handleInputChanged}
                        variant="outlined"
                        select
                        disabled={isEdit && !permissions['ticket.admin']}
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    >
                        <option value="" key="default" disabled>Pilih Type</option>
                        {
                            (isCollectionOnly && !isEdit) ?
                                <option value="Collection">Collection</option> :
                                <>
                                    <option value="Collection">Collection</option> :
                                    <option value="Renewal">Renewal</option>
                                    <option value="Renewal Termin">Renewal Termin</option>
                                    <option value="Greeting">Greeting</option>
                                    <option value="Deposit">Deposit</option>
                                    <option value="Bill">Bill</option>
                                    <option value="Lainnya">Lainnya</option>
                                </>
                        }
                    </TextField>
                </Grid>
                {
                    !(['Renewal', 'Deposit', 'Renewal Termin'].includes(inputState.type)) &&
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            variant="outlined"
                            name="title"
                            value={inputState.title}
                            onChange={handleInputChanged}
                            fullWidth
                        />
                    </Grid>
                }
                {
                    !params.closing_code &&
                    <Grid item xs={12} sm={12}>
                        <AsyncAutoComplete
                            label="Closing"
                            name="closing_id"
                            initialQuery={inputState.closing_label}
                            onChange={handleAutocompleteClosing}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/closing`}
                            placeholder="Closing"
                        // disabled={!!params.id}
                        />
                    </Grid>
                }
                {/* {
                    otherType === true ?
                        <Grid item xs={12}>
                            <TextField
                                label="Tipe Lainnya"
                                variant="outlined"
                                name="type"
                                value={inputState.type}
                                onChange={handleInputChanged}
                                fullWidth
                            />
                        </Grid>
                        : null
                } */}
                {
                    isShowPicForm ?
                        <Grid item xs={12} sm={12}>
                            <AsyncAutoComplete
                                label="PIC Jendela"
                                name="pic_id"
                                initialQuery={inputState.pic_name}
                                onChange={handleAutocompletePic}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-staff`}
                                placeholder="PIC Jendela"
                            />
                        </Grid>
                        : null
                }

                {
                    inputState.type === "Collection" ?
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{ margin: '10px 0px', fontWeight: '500' }}>Bills</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Rent Bill"
                                        variant="outlined"
                                        name="rent_bill"
                                        value={inputState.rent_bill ? numberToCurrency(inputState.rent_bill, 'Rp. ') : ''}
                                        onChange={handleInputChanged}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Utility Bill"
                                        variant="outlined"
                                        name="water_electricity_bill"
                                        value={inputState.water_electricity_bill ? numberToCurrency(inputState.water_electricity_bill, 'Rp. ') : ''}
                                        onChange={handleInputChanged}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Other Bill"
                                        variant="outlined"
                                        name="other_bill"
                                        value={inputState.other_bill ? numberToCurrency(inputState.other_bill, 'Rp. ') : ''}
                                        onChange={handleInputChanged}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Other Bill Notes"
                                        variant="outlined"
                                        minRows={3}
                                        multiline
                                        name="other_bill_notes"
                                        disabled={!inputState.other_bill}
                                        onChange={handleInputChanged}
                                        value={inputState.other_bill_notes}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                }

                {
                    !params.ticket_id ?
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{ margin: '10px 0px', fontWeight: '500' }}>Complaint Detail</Typography>
                            {
                                (!isEdit && inputState.type === 'Deposit') ?
                                    <Typography variant='subtitle2' sx={{ mb: 1 }}>
                                        Note: Detail tambahan akan otomatis ditambah untuk Ticket Deposit setelah disubmit
                                    </Typography>
                                    : null
                            }
                            {
                                inputState.details.map((detail, index) =>
                                    <Grid container key={index} sx={{ mb: 1.5 }} spacing={2}>
                                        <Grid item xs={10}>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Detail"
                                                value={detail}
                                                onChange={e => handleDetailChanged(e, index)}
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => handleRemoveDetail(index)} color='error'>
                                                <Cancel />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            <Button
                                variant='contained'
                                onClick={handleAddDetail}
                                sx={{ mt: 0.5 }}
                                size='small'
                            >
                                Add Detail
                            </Button>
                        </Grid>
                        : null
                }
                {
                    !params.ticket_id &&
                    <Grid item xs={12}>
                        <Typography variant='body1' style={{ margin: '10px 0px', fontWeight: '500' }}>Upload Attachments</Typography>
                        <input type="file" multiple name="attachments_add" onChange={handleChangeFile} />
                    </Grid>
                }
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

export default TicketSystemForm;