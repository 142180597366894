import { BottomNavigation, BottomNavigationAction, Button, FormControlLabel, Grid, MenuItem, Switch, TextField, Theme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { CustomRulesOuput, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generateStyle } from '../../../_utils/DefaultStyle'
import TextEditor from '../../../_components/_form/TextEditor'
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import { cleanObject, generalErrorHandler } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

interface IProps {
    id?: string
}

interface IAutocompleteData {
    value: string;
    label: string;
}

interface IAutoCompleteForDataType {
    id: number
    label: string
}

interface IBasicInfo {
    is_sell: number
    data_type: string
    type: string
    data_id: number;
    data_name: string
    area_data: IAutoCompleteForDataType,
    apart_data: IAutoCompleteForDataType,
    district_data: IAutoCompleteForDataType
    landmark_name: string;
    latitude: string
    longitude: string
    landmark_type: string
    article: string,
    article_en: string,
    meta_title: string
    meta_description: string;
    meta_title_en: string;
    meta_description_en: string;
}

const optionsTypeData = [
    { value: 'area', label: 'Area' },
    { value: 'district', label: 'District' },
    { value: 'apartment', label: 'Apartment' },
    { value: 'landmark', label: 'Landmark' }
]

const optionsType = [
    { value: 'yearly', label: 'Yearly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'studio', label: 'Studio' },
    { value: '1br', label: '1 Bedroom' },
    { value: '2br', label: '2 Bedroom' },
    { value: '3br', label: '3 Bedroom' },
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    { value: 'directory', label: 'Directory' },
    { value: 'list', label: 'List' },
]

const optionsLandmarkType = [
    { value: 'office', label: 'Office' },
    { value: 'university', label: 'University' },
    { value: 'transportation', label: 'Transportation' },
    { value: 'popular', label: 'Popular' }
]

const ArticleBasicInfoForm = (props: IProps) => {
    const navigate = useNavigate()
    const { Root, classes } = useStyles()
    const [basicInfo, setBasicInfo] = useState<IBasicInfo>({
        is_sell: 0,
        data_id: 0,
        data_name: '',
        landmark_name: '',
        latitude: '',
        longitude: '',
        landmark_type: '',
        type: '',
        data_type: '',
        area_data: {
            id: 0,
            label: '',
        },
        apart_data: {
            id: 0,
            label: '',
        },
        district_data: {
            id: 0,
            label: '',
        },
        article: '',
        article_en: '',
        meta_title: '',
        meta_description: '',
        meta_title_en: '',
        meta_description_en: ''
    })
    const [basicInfoError, setBasicInfoError] = useState<IValidationErrors>({})
    const [langSwitch, setLangSwitch] = useState('id')

    useEffect(() => {
        if (props.id) {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/article-landing-page/${props.id}`)
                .then(res => res.data)
                .then(res => cleanObject(res))
                .then(res => {
                    setBasicInfo(res)
                })
                .catch(err => {
                    generalErrorHandler(err)
                })
        }
    }, [props.id])

    const createList = (options: IAutocompleteData[], exclude?: string[]) => {

        return options
            .filter((item) => (exclude && exclude.length) ? !exclude.includes(item.value) : true)
            .map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))
    }

    const validationRules: { [key in keyof IBasicInfo]?: string } = {
        data_type: 'required',
        type: basicInfo.data_type !== 'landmark' ? 'required' : '',
        data_name: 'required',
        latitude: 'required_if:data_type=landmark',
        longitude: 'required_if:data_type=landmark',
        landmark_name: 'required_if:data_type=landmark',
        landmark_type: 'required_if:data_type=landmark',
        article: 'required',
        article_en: 'nullable',
        meta_title: 'nullable',
        meta_title_en: 'nullable',
        meta_description: 'nullable',
        meta_description_en: 'nullable'
    };

    const renderLabelAutocomplete = (name: string) => {
        let label = ""
        switch (dataTypeMemo) {
            case 'district':
                label = "District"
                break
            case 'apartment':
                label = "Apartment"
                break;
            default:
                label = "Area"
                break
        }

        return label
    }

    const getAutoCompleteProps = (dataTypeMemo: string) => {
        let name = "";
        let url = "";

        switch (dataTypeMemo) {
            case 'area':
            case 'landmark':
                name = "area_data";
                url = `${process.env.REACT_APP_API_URL}/autocomplete/area`;
                break;
            case 'district':
                name = "district_data";
                url = `${process.env.REACT_APP_API_URL}/autocomplete/district`;
                break;
            case 'apartment':
                name = "apartment";
                url = `${process.env.REACT_APP_API_URL}/autocomplete/apartment`;
                break;
            default:
                break;
        }

        return { name, url };
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name in basicInfo) {
            const { isValid, errors } = validateData(
                { [name]: value, data_type: basicInfo.data_type, type: basicInfo.type },
                basicInfoRules(customAreaValidation, customDistrictValidation),
                basicInfoAlias
            );

            if (!isValid) {
                setBasicInfoError(prev => ({
                    ...prev,
                    ...errors,
                }));
            } else {
                setBasicInfoError(prev => ({
                    ...prev,
                    [name]: '',
                }));
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setBasicInfoError((prev) => ({
            ...prev,
            [name]: '',
        }));

        if (name === 'data_type') {
            setBasicInfo((prev) => ({
                ...prev,
                [name]: value,
                data_id: 0,
                data_name: '',
            }));
        } else {
            setBasicInfo((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleAutocompleteChange = (name: string, value: IAutoCompleteForDataType) => {
        const errorMessage = 'Data Name cannot be empty';

        setBasicInfo((prev) => ({
            ...prev,
            data_id: value.id,
            data_name: value.label,
        }));

        if (value && value.id && value.label) {
            setBasicInfoError((prev) => ({
                ...prev,
                data_name: '',
            }));
        } else {
            setBasicInfoError((prev) => ({
                ...prev,
                data_name: errorMessage,
            }));
        }
    };

    const handleAutocompleteInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = e.target.value

        if (!value) {
            setBasicInfo(prev => ({
                ...prev,
                data_id: 0,
                data_name: ''
            }))
        } else {
            switch (name) {
                case 'area_data':
                    setBasicInfo(prev => ({
                        ...prev,
                        area_data: {
                            ...prev.area_data,
                            label: value,
                        },
                        data_name: value
                    }))
                    break
                case 'apart_data':
                    setBasicInfo(prev => ({
                        ...prev,
                        apart_data: {
                            ...prev.apart_data,
                            label: value,
                        },
                        data_name: value
                    }))
                    break
                case 'district_data':
                    setBasicInfo(prev => ({
                        ...prev,
                        district_data: {
                            ...prev.district_data,
                            label: value,
                        },
                        data_name: value
                    }))
                    break
            }
        }
    }

    const handleEditorChanged = (name: string, value: string) => {
        setBasicInfo(prev => ({
            ...prev,
            [name]: value
        }))

        setBasicInfoError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const customAreaValidation = (): CustomRulesOuput => {
        let isValid = true
        if (!basicInfo.area_data.id) {
            isValid = false
        }
        return { isValid, errorMessage: 'Area cannot be empty' }
    }

    const customDistrictValidation = (): CustomRulesOuput => {
        let isValid = true
        if (basicInfo.district_data) {
            isValid = false
        }
        return { isValid, errorMessage: 'District cannot be empty' }
    }

    const handleSave = () => {
        const { isValid, errors } = validateData(basicInfo, validationRules, basicInfoAlias);
        setBasicInfoError(errors);

        if (isValid) {
            const data = {
                ...basicInfo,
                data_type: basicInfo.data_type,
                type: basicInfo.type,
                data_id: basicInfo.data_id,
                data_name: basicInfo.data_name,
                latitude: basicInfo.latitude,
                longitude: basicInfo.longitude,
                landmark_name: basicInfo.landmark_name,
                landmark_type: basicInfo.landmark_type,
                article: basicInfo.article,
                article_en: basicInfo.article_en || null,
                meta_title: basicInfo.meta_title || null,
                meta_title_en: basicInfo.meta_title_en || null,
                meta_description: basicInfo.meta_description || null,
                meta_description_en: basicInfo.meta_description_en || null
            };
            if (props.id) {
                DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/article-landing-page/${props.id}`, data)
                    .then(res => res.data)
                    .then(res => {
                        Swal.fire({
                            title: "Article updated",
                            icon: 'success',
                            timer: 1000
                        })
                        navigate('/article-landing-page')
                    })
                    .catch(err => {
                        generalErrorHandler(err)
                    })
            } else {
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/article-landing-page`, data)
                    .then(res => res.data)
                    .then(res => {
                        Swal.fire({
                            title: "Article created",
                            icon: 'success',
                            timer: 1000
                        })

                        navigate('/article-landing-page')
                    })
                    .catch(err => {
                        generalErrorHandler(err)
                    })
            }
        }
    };

    const selectedDataType = [basicInfo.data_type]

    const dataTypeMemo = useMemo(() => {
        return basicInfo.data_type;
    }, [basicInfo.data_type]);

    const { url } = getAutoCompleteProps(dataTypeMemo);

    const handleChangeSellSwitch = (event: React.SyntheticEvent, checked: boolean) => {
        setBasicInfo(prev => ({ ...prev, is_sell: checked ? 1 : 0, data_type: checked ? '' : prev.data_type }))
    }

    return (
        <Grid item xs={12}>
            <Root>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2>Informasi Dasar</h2>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                            control={<Switch />}
                            label="Sell Page"
                            labelPlacement='start'
                            value={basicInfo.is_sell}
                            checked={basicInfo.is_sell === 1}
                            onChange={handleChangeSellSwitch}
                            className={classes.switch}
                        />
                    </Grid>
                    <Grid item xs={8} />
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            select
                            name='data_type'
                            label="Data Type"
                            value={basicInfo.data_type}
                            onChange={handleChange}
                            error={!!basicInfoError.data_type}
                            helperText={basicInfoError.data_type || ''}
                            fullWidth
                        >
                            {createList(optionsTypeData, basicInfo.is_sell ? ['landmark'] : [])}
                        </TextField>
                    </Grid>
                    <Grid item xs={8} />
                    {!selectedDataType.includes('landmark') && dataTypeMemo !== '' && (
                        <>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    select
                                    name='type'
                                    label="Type"
                                    value={basicInfo.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!basicInfoError.type}
                                    helperText={basicInfoError.type || ''}
                                    fullWidth
                                >
                                    {createList(
                                        optionsType,
                                        (!['area', 'district'].includes(basicInfo.data_type) || basicInfo.is_sell) ? ['directory', 'list'] :
                                            basicInfo.is_sell ?
                                                ['yearly', 'monthly'] : []
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item xs={8} />
                        </>
                    )}
                    {dataTypeMemo !== "" ?
                        <>
                            <Grid item xs={12} lg={4}>
                                <AsyncAutoComplete
                                    label="Data name"
                                    placeholder={`Please choose ${renderLabelAutocomplete(dataTypeMemo)}`}
                                    name="data_id"
                                    initialQuery={basicInfo.data_name}
                                    // value={basicInfo.area_data}
                                    onChange={handleAutocompleteChange}
                                    onInputChange={handleAutocompleteInputChange}
                                    url={url}
                                    errorText={basicInfoError.data_name}
                                />
                            </Grid>
                            <Grid item xs={8} /> </> : null}
                    {selectedDataType.includes('landmark') && dataTypeMemo !== '' && (
                        <>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    name="landmark_name"
                                    label="Landmark Name"
                                    value={basicInfo.landmark_name}
                                    error={!!basicInfoError.landmark_name}
                                    helperText={basicInfoError.landmark_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    select
                                    name='landmark_type'
                                    label="Landmark Type"
                                    value={basicInfo.landmark_type}
                                    error={!!basicInfoError.landmark_type}
                                    helperText={basicInfoError.landmark_type || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                >
                                    {createList(optionsLandmarkType)}
                                </TextField>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    name="latitude"
                                    label="Latitude"
                                    value={basicInfo.latitude}
                                    error={!!basicInfoError.latitude}
                                    helperText={basicInfoError.latitude || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <TextField
                                    name="longitude"
                                    label="Longitude"
                                    value={basicInfo.longitude}
                                    error={!!basicInfoError.longitude}
                                    helperText={basicInfoError.longitude || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                {dataTypeMemo !== "" ?
                    <div>
                        <h2>Article</h2>
                        <Grid item xs={12}>
                            <BottomNavigation
                                value={langSwitch}
                                onChange={(e, newValue) => setLangSwitch(newValue)}
                                showLabels
                                className={classes.filter}
                            >
                                <BottomNavigationAction
                                    classes={{
                                        root: classes.filterButton,
                                        label: classes.filterButtonLabel,
                                        selected: classes.filterButtonSelected
                                    }}
                                    value={'id'}
                                    label={'Indonesia'}
                                />
                                <BottomNavigationAction
                                    classes={{
                                        root: classes.filterButton,
                                        label: classes.filterButtonLabel,
                                        selected: classes.filterButtonSelected
                                    }}
                                    value={'en'}
                                    label={'English'}
                                />
                            </BottomNavigation>
                            <div className={classes.section}>
                                <TextEditor
                                    name={langSwitch === 'id' ? 'article' : 'article_en'}
                                    value={langSwitch === 'id' ? basicInfo.article : (basicInfo.article_en || '')}
                                    onChange={handleEditorChanged}
                                />
                                {
                                    ((langSwitch === 'id' || langSwitch === 'en') && basicInfoError.article) &&
                                    <span className={classes.articleError}>Field Indonesia Article is required</span>
                                }

                            </div>
                        </Grid>
                    </div> : null}
                {dataTypeMemo !== "" ?
                    <>
                        <h2>Meta</h2>
                        <div className={classes.section}>
                            <h3>[ID] Meta</h3>
                            <TextField
                                name="meta_title"
                                label="Title"
                                value={basicInfo.meta_title}
                                error={!!basicInfoError.meta_title}
                                helperText={basicInfoError.meta_title || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                fullWidth
                            />
                            <TextField
                                name="meta_description"
                                label="Description"
                                value={basicInfo.meta_description}
                                error={!!basicInfoError.meta_description}
                                helperText={basicInfoError.meta_description || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                fullWidth
                            />
                        </div>

                        <div className={classes.section}>
                            <h3>[EN] Meta</h3>
                            <TextField
                                name="meta_title_en"
                                label="Title"
                                value={basicInfo.meta_title_en}
                                error={!!basicInfoError.meta_title_en}
                                helperText={basicInfoError.meta_title_en || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                fullWidth
                            />
                            <TextField
                                name="meta_description_en"
                                label="Description"
                                value={basicInfo.meta_description_en}
                                error={!!basicInfoError.meta_description_en}
                                helperText={basicInfoError.meta_description_en || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                fullWidth
                            />
                        </div> </> : null}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    style={{ marginTop: dataTypeMemo !== "" ? 0 : 20 }}
                >
                    Save
                </Button>
            </Root>
        </Grid>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    section: {
        paddingBottom: 24,
        borderBottom: '1px solid #ddd',
        '&:nth-last-child(2)': {
            borderBottom: 'none',
        },
        '& > *, & .collapse > div > div > *': {
            marginBottom: 16,
        },
        '& > .split': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            [theme.breakpoints.up('sm')]: {
                flexWrap: 'nowrap',
            },
            '& > *': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '50%',
                    flexShrink: 1,
                },
            },
            [theme.breakpoints.up('md')]: {
                '& > div:first-of-type': {
                    marginRight: 8,
                },
                '& > div:last-child': {
                    marginLeft: 8,
                },
            },
        },
        '& > h3': {
            fontWeight: 500,
        },
        '& > .toggle-button': {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 24,
            '& > *': {
                minWidth: '100px',
                margin: 0,
                textTransform: 'capitalize',
            },
            '& > button:first-of-type': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
            '& > button:last-child': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
        },
        '& .half-width > *': {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginRight: 'auto',
            },
        },
        '& > .grid': {
            display: 'grid',
            gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
            gap: 16,
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
            }
        },
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
        marginBottom: 30,
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
        // fontSize: '0.8125rem',
        // border: '1px solid #006db3',
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    articleError: {
        color: theme.palette.error.main,
        marginTop: 8,
        marginLeft: 4,
        display: 'block',
    },
    switch: {
        minWidth: '100%',
        marginLeft: 0,
        justifyContent: 'space-between',
        "& .MuiSwitch-root": {
            position: 'relative',
            left: '12px'
        }
    }
}), "ArticleBasicInfoForm"
)

const basicInfoRules = (
    areaValidation: () => CustomRulesOuput,
    districtValidation: () => CustomRulesOuput
): IValidationRules => ({
    // data_id: 'required',
    area_data: areaValidation,
    district_data: districtValidation,
    latitude: 'required',
    longitude: 'required',
})

const basicInfoAlias: { [key: string]: string } = {
    latitude: 'Latitude',
    longitude: 'Longitude',
    data_name: 'Data Name',
    data_type: 'Data Type',
    area_data: 'Area',
    type: 'Type',
    district_data: 'District',
    apart_data: 'Apart',
    landmark_name: 'Landmark Name',
    landmark_type: 'Landmark Type'
}

export default ArticleBasicInfoForm