import React, { useState, useEffect } from 'react';
import { Button, Theme, Chip, FormControl, Select, MenuItem, Grid, FormControlLabel, Switch } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import CustomerPhoneSearch from '../_manageExtend/_components/CustomerPhoneSearch';
import ClosingModalDetail from '../_closing/_components/ClosingModalDetail';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Icon
 */
import AddIcon from '@mui/icons-material/Add';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency, generalErrorHandler, queryParamsToParams, renderInfoButton } from '../../_utils/Helper';
import { useContext } from 'react';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

interface ITRList {
    key: string,
    value: string
}

interface IParams extends Record<string, string | undefined> { code: string | undefined }

const useStyles = generateStyle((theme: Theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    addButton: {
        marginBottom: '20px',
        marginLeft: '10px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
}), "Closing_list"
)

const ClosingList = () => {
    const permissions = useContext(PermissionContext)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const API_URL = `${process.env.REACT_APP_API_URL}/new-closing`;
    const params = useParams<IParams>();
    const { Root, classes } = useStyles();
    const [modalCode, setModalCode] = useState("");
    const [moreDetail, setMoreDetail] = useState(false)
    const [picTRList, setPICTRList] = useState<Array<ITRList>>([]);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    useEffect(() => {
        if (params.code) {
            setModalCode(params.code);
        } else {
            setModalCode("");
        }
    }, [params.code]);

    /**
     * Initial when Page Accessed
     */
    useEffect(() => {
        if (permissions['closing.list-type'] === 'operation-manager') {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/user/tenant-relation`)
                .then(response => {
                    const lists = response.data;
                    let listToPush: Array<ITRList> = [];

                    lists.map((data: { id: string, label: string }) => {
                        listToPush.push({
                            key: data.id,
                            value: data.label
                        })
                        return true;
                    });

                    setPICTRList([{ key: '', value: 'All' }, { key: 'none', value: 'Not Assigned' }, ...listToPush]);
                })
                .catch(error => {
                    alert('Kesalahan server, harap hubungi admin')
                });
        }
    }, [permissions]);

    const exportData = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/export`, queryParamsToParams())
            .then(res => {
                renderInfoButton("Export Processing", res.data.message);
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const exportBalance = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/export-transaction-balance`, queryParamsToParams())
            .then(res => {
                renderInfoButton("Export Processing", res.data.message);
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const getColorCode = (status: string) => {
        switch (Number(status)) {
            case 0:
                return 'rgba(179, 173, 173, 0.87)';
            case 1:
                return 'black';
            case 2:
                return '#009be5';
            case 3:
                return 'red';
            case 4:
                return 'green';
            default:
                return '';
        }
    }

    const renderPIC = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        if (!permissions['extend.admin']) {
            return row.extend_pic_id ? `${row.extend_pic_name}` : 'None Selected';
        }

        return (
            <FormControl className={classes.formControl}>
                <Select
                    value={row.extend_pic_id ?? 'none'}
                    onChange={(e) => handleChangePic(row.id, e.target.value as string)}
                    autoWidth
                >
                    <MenuItem value={'none'}>None Selected</MenuItem>
                    {picTRList.map(data => {
                        return (
                            <MenuItem value={data.key} key={data.key}>{data.value}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    const handleChangePic = (closing_id: string, extend_pic_id: string) => {
        DefaultAxios.patch(`${API_URL}/change-pic`, {
            closing_id: closing_id,
            extend_pic_id: extend_pic_id
        })
            .then(response => {
                if (response.data.updated) {
                    Swal.fire({
                        html: "Success change data",
                        icon: 'info',
                        showCloseButton: true,
                        onClose: () => {
                            setReDrawDatatable(new Date().getTime());
                        }
                    })
                }
            })
            .catch(error => {
                alert('Kesalahan server, harap hubungi admin')
            });
    }

    const generateColumn = () => {
        /**
         * Available Columns
         */
        const columns = [
            {
                name: 'closing_date',
                label: 'Tanggal Input',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'first_payment_date',
                label: 'Tanggal Pembayaran Pertama',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'finished_payment_date',
                label: 'Tanggal Pelunasan Closing',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'enquiry_code',
                label: 'Kode Enquiry',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        return (
                            <Chip
                                variant="outlined"
                                label={row.enquiry_code} onClick={() => {
                                    window.open(`/enquiry/${row.enquiry_id}`, "_blank", "noreferrer");
                                }}
                            />
                        )
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'closing_code',
                label: 'Kode Transaksi',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        return (
                            <Chip
                                variant="outlined"

                                label={row.closing_code} onClick={() => {
                                    setModalCode(row.closing_code);
                                    window.history.pushState('', '', `/closing/${row.closing_code}`)
                                }}
                            />
                        )
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'termin_code',
                label: 'Kode Termin',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'code',
                label: 'Kode Unit',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'creator_name',
                label: 'Created By',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'advisor_name',
                label: 'Advisor',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'created_by',
                label: 'Created By',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'customer_name',
                label: 'Nama Customer',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'is_expat',
                label: 'Expat',
                filterable: true,
                sortable: true,
                defaultOption: '',
                options: [
                    {
                        key: '',
                        value: 'All'
                    },
                    {
                        key: '0',
                        value: 'No'
                    },
                    {
                        key: '1',
                        value: 'Yes'
                    },
                ],
            },
            {
                name: 'is_include_maintenance',
                label: 'Include Maintenance',
                filterable: true,
                sortable: true,
                defaultOption: '',
                options: [
                    {
                        key: '',
                        value: 'All'
                    },
                    {
                        key: '0',
                        value: 'No'
                    },
                    {
                        key: '1',
                        value: 'Yes'
                    },
                ],
                render: (data: string, row: any) => {
                    return data ? 'Yes' : 'No';
                }
            },
            {
                name: 'start_date',
                label: 'Tanggal Masuk',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'gmv_transaction',
                label: 'GMV',
                filterable: true,
                sortable: true,
                type: 'number',
                render: (data: string, row: any) => {
                    if ([0, 1].includes(row.status)) {
                        return (<div style={{ color: getColorCode(row.status) }}>Rp. 0 ({numberToCurrency(data, 'Rp')})</div>);
                    } else if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>Rp. 0 ({numberToCurrency(data, 'Rp')})</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{numberToCurrency(data, 'Rp')}</div>);
                    }
                }
            },
            {
                name: 'revenue_jendela',
                label: 'Total Komisi',
                filterable: true,
                sortable: true,
                type: 'number',
                render: (data: string, row: any) => {
                    if ([0, 1].includes(row.status)) {
                        return (<div style={{ color: getColorCode(row.status) }}>Rp. 0 ({numberToCurrency(data, 'Rp')})</div>);
                    } else if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>Rp. 0 ({numberToCurrency(data, 'Rp')})</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{numberToCurrency(data, 'Rp')}</div>);
                    }
                }
            },
            {
                name: 'status',
                label: 'Status',
                filterable: true,
                sortable: true,
                defaultOption: '',
                options: [
                    {
                        key: '',
                        value: 'All'
                    },
                    {
                        key: '0',
                        value: 'Draft'
                    },
                    {
                        key: '2',
                        value: 'Verified'
                    },
                    {
                        key: '3',
                        value: 'Canceled'
                    },
                    {
                        key: '4',
                        value: 'Completed'
                    }
                ],
                render: (data: string, row: any) => {
                    switch (Number(data)) {
                        case 0:
                            return (<div style={{ color: getColorCode(data) }}>Draft</div>);
                        case 1:
                            return (<div style={{ color: getColorCode(data) }}>Confirmed</div>);
                        case 2:
                            return (<div style={{ color: getColorCode(data) }}>Verified</div>);
                        case 3:
                            return (<div style={{ color: getColorCode(data) }}><del>Canceled</del></div>);
                        case 4:
                            return (<div style={{ color: getColorCode(data) }}>Completed</div>);
                        default:
                            return '-';
                    }
                }
            },
            {
                name: 'extend_pic_id',
                label: 'Extend PIC',
                filterable: true,
                sortable: true,
                type: 'string',
                render: renderPIC,
                defaultOption: "",
                options: picTRList
            },
            {
                name: 'extend_pic_name',
                label: 'Extend PIC',
                filterable: true,
                type: 'string',
            },
            {
                name: 'duration',
                label: 'Durasi',
                filterable: true,
                type: 'string',
            },

            ...(
                moreDetail ? [
                    {
                        name: 'utm_source',
                        label: 'UTM Source',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'utm_medium',
                        label: 'UTM Medium',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'utm_campaign',
                        label: 'UTM Campaign',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'utm_content',
                        label: 'UTM Content',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'keyword',
                        label: 'Keyword',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'adsetname',
                        label: 'Adsetname',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'adgroupid',
                        label: 'Adgroupid',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'creative',
                        label: 'Creative',
                        type: 'string',
                        filterable: true
                    },
                ] : []
            ),
        ];

        /**
         * Build column
         */
        let listColumns;
        let columnsToRender: any = [];
        if (permissions['closing.list-type'] === 'advisor-captain' || permissions['closing.list-type'] === 'admin-data') {
            listColumns = ['closing_date', 'enquiry_code', 'closing_code', 'code', 'duration', 'gmv_transaction', 'revenue_jendela', 'customer_name', 'advisor_name', 'extend_pic_name', 'status'];
        } else if (permissions['closing.list-type'] === 'advisor-manager') {
            listColumns = ['closing_date', 'first_payment_date', 'finished_payment_date', 'start_date', 'enquiry_code', 'closing_code', 'code', 'duration', 'gmv_transaction', 'revenue_jendela', 'customer_name', 'advisor_name', 'extend_pic_name', 'status'];
        } else if (permissions['closing.list-type'] === 'operation-manager') {
            listColumns = ['closing_date', 'status', 'enquiry_code', 'closing_code', 'termin_code', 'code', 'gmv_transaction', 'revenue_jendela', 'customer_name', 'is_include_maintenance', 'start_date', 'advisor_name', 'creator_name', 'extend_pic_id'];
        } else if (permissions['closing.list-type'] === 'listing-acquisition-manager') {
            listColumns = ['closing_date', 'status', 'closing_code', 'termin_code', 'code', 'duration', 'customer_name', 'is_include_maintenance', 'advisor_name', 'creator_name', 'gmv_transaction', 'revenue_jendela'];
        } else {
            listColumns = ['closing_date', 'first_payment_date', 'finished_payment_date', 'status', 'closing_code', 'termin_code', 'code', 'customer_name', 'is_include_maintenance', 'advisor_name', 'creator_name', 'start_date', 'gmv_transaction', 'revenue_jendela'];
            if (moreDetail) {
                listColumns = listColumns.concat([
                    'utm_source',
                    'utm_medium',
                    'utm_campaign',
                    'utm_content',
                    'keyword',
                    'adsetname',
                    'adgroupid',
                    'creative'
                ]
                )
            }
        }


        listColumns.map(key => {
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].name === key) {
                    columnsToRender.push(columns[i]);
                    break;
                }
            }
            return true;
        });

        return columnsToRender;
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <>
                {permissions['closing.create'] &&
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/closing/add')}

                    >
                        Add Closing
                    </Button>
                }

                {permissions['closing.create-wings'] &&
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/closing-wings/add')}

                    >
                        Add Closing Wings
                    </Button>
                }

            </>
            <div className={classes.buttonContainer}>
                <CustomerPhoneSearch />
                {permissions['closing.export'] &&
                    <Button color="primary" variant="contained" onClick={exportData}>
                        Export
                    </Button>
                }
                {permissions['closing.export-transaction-balance'] &&
                    <Button color="primary" variant="contained" onClick={exportBalance} sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                        Export Balance
                    </Button>
                }
            </div>

            <Grid container sx={{ mb: 2 }}>
                {
                    permissions['closing.more-detail'] &&
                        !['advisor-captain', 'admin-data', 'operation-manager'].includes(permissions['closing.list-type']) ?
                        <Grid item sx={{ marginLeft: 'auto' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={moreDetail}
                                        onChange={() => { setMoreDetail(!moreDetail) }}
                                        color="primary"
                                    />
                                }
                                label="More Detail"
                            />
                        </Grid>
                        : null
                }
            </Grid>

            <DataTable
                url={API_URL}
                columns={generateColumn()}
                reDraw={reDrawDatatable}
                // closing={permissions['closing.list-type'] === 'advisor-captain' ? true : false}
                closing={true}
                disableFetch={!!params.code}
                filterButtons={[
                    {
                        key: 'is_telesales',
                        defaultValue: null,
                        options: [
                            {
                                value: null,
                                label: 'All',
                            },
                            {
                                value: '1',
                                label: 'Telesales',
                            },
                            {
                                value: '0',
                                label: 'Non Telesales',
                            },
                        ]
                    },
                ]}
            />

            {modalCode !== "" ?
                <ClosingModalDetail code={modalCode}
                    open={modalCode !== ""}
                    permissions={permissions}
                    onClose={() => {
                        navigate(`/closing`)
                        setModalCode("")
                        window.history.pushState('', '', `/closing`)
                    }} /> :
                null
            }

        </Root>
    );
}

export default ClosingList;