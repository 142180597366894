import React, { ReactElement, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

/**
 * Context
 */
import { PermissionContext } from "../_contexts/PermissionContext";

import { Link, useNavigate } from "react-router-dom";

/**
 * List
 */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CollapsibleList from './CollapsibleList';

/**
 * Icon
 */
import AddIcon from '@mui/icons-material/Add';
// import ListAltIcon from '@mui/icons-material/ListAlt';
// import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import VpnKeyIcon from '@mui/icons-material/VpnKey';
// import ApartmentIcon from '@mui/icons-material/Apartment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';
import StorageIcon from '@mui/icons-material/Storage';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import CommuteIcon from '@mui/icons-material/Commute';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

/**
 * Jendela Logo
 */
import logo from '../Assets/Images/Logo-Jendela-360-horizontal.png';

// import * as DefaultNotification from "../_utils/DefaultNotification";
// import DefaultEcho from '../_utils/DefaultEcho';
// import { checkNotification } from '../_utils/Helper';
import { InputAdornment, TextField, Theme, useMediaQuery } from '@mui/material';
import MyUnitList from '../_pages/_myUnit/MyUnitList';
import DefaultAxios from '../_utils/DefaultAxios';
import { unSubscibeNotification } from '../_utils/DefaultNotification';
import { WorkHistory } from '@mui/icons-material';
import useLongPress from '../_hooks/useLongPress';
import { generalErrorHandler } from '../_utils/Helper';
import ToDoPopOver from './ToDoPopOver';
import { generateStyle } from '../_utils/DefaultStyle';

interface Links extends Array<LinksItem | false> { }

interface LinksItem {
  url: string;
  type?: string;
  label: string;
  logo: ReactElement | null;
  children?: Links | false;
  exact?: boolean;
}

interface Props {
  title: string;
  container: any;
  children?: any;
  searchable?: boolean
  search_placeholder?: string
  component_name?: string
  backButton?: string | boolean;
}

function generateLinks(links: Links) {
  return links.map((data, index) => {
    if (!data) {
      return null;
    }
    if (data.type === 'parent' && data.children) {
      const childrenLength = data.children.filter(item => item).length;
      if (childrenLength) {
        return (
          <CollapsibleList
            label={data.label}
            logo={data.logo}
            lists={data.children}
            key={index}
          />
        )
      } else {
        return null;
      }
    } else {
      return (
        <Link to={data.url} style={{ textDecoration: 'none', color: 'black' }} key={data.label}>
          <ListItem>
            {data.logo &&
              <ListItemIcon>
                {data.logo}
              </ListItemIcon>
            }
            <ListItemText primary={data.label} />
          </ListItem>
        </Link>
      )
    }
  })
}


const drawerWidth = 270;

const useStyles = generateStyle((theme: Theme) => ({
  root: {
    display: 'flex',
    '& .MuiListItemIcon-root': {
      minWidth: '45px'
    },
    '& .MuiIconButton-root': {
      marginRight: 0,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    boxShadow: '0px 3px 15px #0000001A',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  closeSearch: {
    marginRight: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menubarHeaderContainer: {
    height: '64px',
    // backgroundColor: '#009be5',
    // color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #eee'
  },
  logo: {
    height: '50px'
  },
  hideTitle: {
    width: "0%",
    transition: "width 0.25s",
    transitionTimingFunction: "linear"
  },
  searchText: {
    float: "right",
    width: "0%",
    borderRadius: "4px",
    backgroundColor: "white",
    transition: "width 0.25s",
    transitionTimingFunction: "linear",
    '& *': {
      border: 'none'
    }
  },
  searchTextActive: {
    width: "100%",
    transition: "width 0.25s",
    transitionTimingFunction: "linear"
  }
}), "Menubar");

// function Notif() {
//   const navigate =  useNavigate();

//   return <NotificationContext.Consumer>
//       {context => (
//           <IconButton
//               color="inherit"
//               onClick={() => navigate('/notification')}
//           >
//               <Badge variant="dot" color="error" overlap="circle" invisible={!context.isUnread}>
//                   <NotificationsIcon />
//               </Badge>
//           </IconButton>
//       )}
//   </NotificationContext.Consumer>
// }

function ResponsiveDrawer(props: Props) {
  const permissions = useContext(PermissionContext);
  const navigate = useNavigate();
  const { container } = props;
  const { Root, classes } = useStyles();
  const theme = useTheme<Theme>();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const isUnread = false;
  const [isUnread, setIsUnread] = React.useState(false);

  const [showClearButton, setShowClearButton] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [showSearch, setShowSearch] = React.useState(false);
  const searchRefContainer = React.useRef<HTMLInputElement>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const submitQuery = (e: any) => {
    if (e.target.value !== "") {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }

    if (e.key === 'Enter') {
      setQuery(e.target.value);
    }
  }

  const resetSearch = () => {
    setQuery("");
    setShowSearch(false);
  }


  const clearSearch = () => {
    if (null !== searchRefContainer.current) {
      searchRefContainer.current.value = "";
      setShowClearButton(false);
    }
  }

  // const connectEcho = () => {
  //   if (process.env.REACT_APP_WEBSOCKET_URL as string !== 'false') {
  //     DefaultEcho.private(`App.Models.User.${localStorage.getItem("user_id")}`)
  //       .notification((notification: any) => {
  //         navigator.serviceWorker.ready.then(function (registration) {
  //           registration.showNotification(notification.title, notification);
  //         });
  //         setIsUnread(true)
  //       });
  //   }
  // }

  // useEffect(() => {
  //   if (localStorage.getItem("jwt_token") !== null) {
  //     // if (process.env.REACT_APP_WEBSOCKET_URL as string !== 'false') {
  //     //   connectEcho()
  //     // }
  //     // checkNotification()
  //     //   .then(res => {
  //     //     setIsUnread(res as boolean)
  //     //   })
  //   }
  // }, [])

  useEffect(() => {
    const unreadDateData = localStorage.getItem('next_check_unread')
    const unreadDate = unreadDateData ? new Date(unreadDateData) : false
    const isNotificationPage = window.location.pathname === '/notification'

    if (!unreadDate || (new Date().getTime() > unreadDate.getTime()) || isNotificationPage) {
      DefaultAxios.get(`${process.env.REACT_APP_API_URL}/notification/unread`)
        .then(res => {
          setIsUnread(res.data);
          const nextUnreadDate = new Date(new Date().setHours(new Date().getHours() + 1))
          localStorage.setItem('next_check_unread', nextUnreadDate.toString())
          localStorage.setItem('is_unread', res.data ? '1' : '0')
        });
    } else {
      const isUnreadLS = localStorage.getItem('is_unread')
      setIsUnread(prev => isUnreadLS ? (isUnreadLS === '1') : prev)
    }
  }, [])

  const hr_links: Links = [
    { url: '/career', type: 'parent', label: 'Career Page', logo: <BusinessCenterIcon /> },
    { url: '/user-list', label: 'Manage User', logo: <PeopleIcon /> },
    { url: '/profile-setting', label: 'Profile Setting', logo: <SettingsApplicationsIcon /> },
  ];

  const url_links: Links = [
    /**
     * Dashboard
     */
    { url: '/', label: 'Dashboard', logo: <DashboardIcon />, exact: true },
    permissions['dashboard-pm.view'] ? { url: '/my-performance', label: 'My Performance', logo: <AnalyticsIcon /> } : false,

    /**
     * Listing Inventory
     */
    {
      url: '#', type: 'parent', label: 'Listing Inventory', logo: <EventNoteIcon />, children: [
        permissions['listing-inventory.full'] ? { url: '/unit-list', label: 'List Unit', logo: <EventNoteIcon /> } : false,
        permissions['listing-inventory.full'] ? { url: '/listing-inventory', label: 'Listing Inventory', logo: <EventNoteIcon /> } : false,
      ]
    },

    /**
     * Report
     */
    {
      url: '#', type: 'parent', label: 'Report', logo: <AssessmentIcon />, children: [
        // permissions['enquiry.chart-report'] && { url: '/enquiry/chart', label: 'Enquiry', logo: null },
        // permissions['submission.chart'] && { url: '/listing-submission-chart', label: 'Listing Submission', logo: null },
        // permissions['closing-chart.view'] && { url: '/closing/chart', label: 'Closing', logo: null },
        // permissions['closing-chart.view'] && { url: '/closing/chart-monthly', label: 'Closing Monthly', logo: null },

        // /**
        //  * Unit Portfolio List
        //  */
        // permissions['assigned-unit-list.manage'] ? { url: '/assigned-unit-list', label: 'Unit Portfolio List', logo: null } : false,

        /**
         * Advisor Performance
         */
        permissions['pm-performance.list'] ? { url: '/advisor-performance', label: 'Advisor Performance', logo: null } : false,

        // /**
        //  * Entrusted Key Report
        //  */
        // permissions['entrusted-key.report'] ? { url: '/entrusted-key-report', label: 'Entrusted Key Report', logo: null } : false,

        /**
         * Top Apartment Closing
         */
        permissions['top-closing-apartment.view'] ? { url: '/top-apartment-closing', label: 'Top Apartment Closing', logo: null } : false,

        /**
         * Funnel Report
         */
        permissions['funnel-report.view'] && { url: '/funnel-report', label: 'Funnel Report', logo: null },

        /**
         * Funnel Report Listing Acquisition
         */
        permissions['funnel-report-listing.view'] && { url: '/funnel-report-listing', label: 'Funnel Report Listing', logo: null },

        /**
         * Apartment Performance
         */
        permissions['apartment-performance.manage'] && { url: '/apartment-performance', label: 'Apartment Performance', logo: null },

        /**
         * General Performance
         */
        permissions['general-performance.view'] && { url: '/general-performance', label: 'General Performance', logo: null },

        /**
         * Enquiry Report
         */
        permissions['report.enquiry-breakdown'] && { url: '/enquiry-breakdown', label: 'Enquiry Breakdown', logo: null },

        /**
       * Ranking Agent
       */
        permissions['ranking-agent.see-all'] && { url: '/ranking-agent', label: 'Ranking Agent', logo: null },
      ]
    },

    /**
     * Schedule
     */
    {
      url: '#', type: 'parent', label: 'Schedule', logo: <WorkHistory />, children: [

        /**
         * FA Team Schedule
         */
        permissions['activity.see-schedule'] ? { url: '/activity-schedule', label: 'FA Schedule', logo: null, exact: true } : false,


        permissions['activity.see-schedule'] ? { url: '/jhs-schedule', label: 'JHS Schedule', logo: null, exact: true } : false,
        permissions['showing-schedule.view'] ? { url: '/showing-schedule', label: 'Showing Schedule', logo: null, exact: true } : false,
      ],
    },

    /**
     * Operation
     */
    {
      url: '#', type: 'parent', label: 'Operation', logo: <BuildIcon />, children: [
        /**
         * Enquiry
         */
        permissions['enquiry.manage'] || permissions['enquiry.manage-advisor'] ? { url: '/enquiry', label: 'Enquiry', logo: null } : false,

        /**
        * Enquiry Buy
        */
        permissions['enquiry-buy.manage'] || permissions['enquiry-buy.manage'] ? { url: '/enquiry-buy', label: 'Enquiry Buy', logo: null } : false,

        /**
         * Activity
         */
        { url: '/activity', label: 'Activity', logo: null, exact: true },
        permissions['activity-showing.view'] ? { url: '/activity-showing', label: 'Activity Showing', logo: null, exact: true } : false,

        /**
         * Closing
         */
        permissions['closing.view'] && { url: '/closing', label: 'Closing', logo: null },

        /**
      * Closing Sell
      */
        permissions['enquiry-buy.manage'] ? { url: '/closing-sell', label: 'Closing (Sell)', logo: null, exact: true } : false,

        /**
       * Available Apartment Unit
       */
        permissions['apartment-unit-info.view'] ? { url: '/list-unit-available', label: 'List Unit Available', logo: null } : false,

        /**
      * Entrusted Key (Titip Kunci)
      */
        permissions['list-unit-sl.manage'] ? { url: '/list-entrusted-key', label: 'List Unit Titip Kunci', logo: null } : false,

        /**
         * Manage Extend
         */
        permissions['extend.manage'] ? { url: '/manage-extend', label: 'Extend', logo: null } : false,

        /**
         * Billing Collection
         */
        permissions['billing-collection.view'] ? { url: '/billing-collection', label: 'Billing Collection', logo: null } : false,

        /**
         * Ticket
         */
        permissions['ticket.manage'] ? { url: '/ticket/list', label: 'Ticket', logo: null } : false,

        /**
         * Borrowed Keys
         */
        permissions['unit.key-manage'] ? { url: '/manage-keys', label: 'Manage Keys', logo: null } : false,

        /**
         * Listing Submission
         */
        permissions['submission.manage'] ? { url: '/listing-submission', label: 'Listing Submission', logo: null } : false,

        /**
         * Listing Submission Apartment
         */
        permissions['submission-apartment.list'] ? { url: '/listing-submission-apartment', label: 'Listing Submission Apartment', logo: null } : false,

        /**
       * Listing Submission Sell
       */
        permissions['enquiry-buy.manage'] ? { url: '/listing-submission-sell', label: 'Listing Submission (Sell)', logo: null } : false,

        /**
         * Transportation Allowance
         */
        (permissions['reimbursement.recap-pm'] || permissions['reimbursement.recap-jhs']) ? { url: '/reimbursement-recap-daily', label: 'Transportation Allowance', logo: null } : false,


        /**
         * Quality Control Unit List
         */
        permissions['unit.quality-control-list'] ? { url: '/qc-unit-list', label: 'QC Unit List', logo: null, exact: true } : false,

        /**
         * JHS Order
         */
        permissions['order-jhs.manage'] ? { url: '/jhs-order', label: 'JHS Order', logo: null, exact: true } : false,

        /**
         * Telesales
         */
        permissions['telesales.manage'] ? { url: '/telesales-area', label: 'Telesales Area', logo: null, exact: true } : false,

        /**
         * Sell Advisor
         */
        permissions['area-advisor-sell.manage'] ? { url: '/sell-advisor-area', label: 'Sell Advisor Area', logo: null, exact: true } : false,

        /**
         * Listing Ticket
         */
        permissions['listing-ticket.manage'] ? { url: '/listing-ticket', label: 'Listing Ticket', logo: null, exact: true } : false,

        /**
        * Listing Ticket
        */
        permissions['lead-listing-fb.manage'] ? { url: '/lead-listing-fb', label: 'Lead Listing FB Instant Form', logo: null, exact: true } : false,
      ]

    },

    /**
     * Master Data
     */
    {
      url: '#', type: 'parent', label: 'Master Data', logo: <StorageIcon />, children: [
        /**
         * Account
         */
        permissions['account.manage'] ? { url: '/account', label: 'Account', logo: null } : false,

        /**
         * Apartment
         */
        permissions['apartment.list'] ? { url: '/apartment', label: 'Apartment', logo: null } : false,

        /**
         * Apartment Notes
         */
        permissions['apartment.manage-note'] ? { url: '/apartment-notes', label: 'Apartment Notes', logo: null } : false,

        /**
         * Unit
         */
        permissions['unit.manage'] ? { url: '/unit', label: 'Unit', logo: null } : false,

          /**
         * Unit Sell
         */
          permissions['unit.sell-manage'] ? { url: '/unit-sell', label: 'Unit (Sell)', logo: null } : false,

        /**
         * Unit Review
         */
        permissions['unit-review.manage'] ? { url: '/unit-review', label: 'Unit Review', logo: null } : false,

        /**
         * Unit Requested Delete
         */
        permissions['unit.delete'] ? { url: '/unit-delete', label: 'Unit Requested Delete', logo: null } : false,

        /**
         * Unit Photo Checklist
         */
        permissions['unit-photo-checklist.manage'] ? { url: '/unit-photo-checklist', label: 'Unit Photo Checklist', logo: null } : false,

        /**
         * Other Listing
         */
        permissions['listing.see-detail'] ? { url: '/listingAdmin/list', label: 'Other Listing', logo: null } : false,

        /**
         * PIC Landlord
         */
        permissions['pic-landlord.show'] ? { url: '/pic-landlord', label: 'PIC Landlord', logo: null } : false,

        /**
         * Landlord
         */
        permissions['landlord.admin'] || permissions['landlord.manage'] ? { url: '/landlord', label: 'Landlord', logo: null } : false,

        /**
         * Agent
         */
        permissions['agent.list'] ? { url: '/agent', label: 'Agent Partner', logo: null } : false,
        permissions['agent-office.manage'] ? { url: '/agent-office', label: 'Agent Office', logo: null } : false,
        permissions['user.agent-list'] ? { url: '/agent-list', label: 'Agent List', logo: null } : false,
        /**
         * User Manage
         */
        permissions['user.manage'] || permissions['user.pm-manage'] || permissions['user.hr-manage'] || permissions['user.supervisor-manage'] || permissions['user.operation-manager-manage'] || permissions['user.lead-listing-acquisition-manage'] || permissions['user.tenant-relation-manager-manage'] ? { url: '/user-list', label: 'User', logo: null } : false,

        /**
         * Customer Manage
         */
        permissions['customer.admin'] || permissions['customer.list'] ? { url: '/customer', label: 'Customer', logo: null } : false,

        /**
         * Advisor Group
         */
        permissions['advisor-group.manage'] ? { url: '/advisor-group', label: 'Advisor Group', logo: null } : false,
        // permissions['advisor-group.manage'] ? { url: '/advisor-manage-member', label: 'Advisor Group Member', logo: null } : false,

        /**
         * Bank Vendor
         */
        permissions['bank-vendor.manage'] ? { url: '/bank-vendor', label: 'Bank Vendor', logo: null } : false,

        /**
         * BankOthers
         */
        permissions['bank-others.manage'] ? { url: '/bank-others', label: 'Bank Others', logo: null } : false,
      ]
    },

    /**
     * Escrow Related
     */
    {
      url: '#', type: 'parent', label: 'Escrow', logo: <AttachMoneyIcon />, children: [
        /**
         * Statement
         */
        permissions['statement.manage'] ? { url: '/statement', label: 'Statement', logo: null, exact: true } : false,

        /**
         * Verify Transaction
         */
        permissions['closing-transaction.list'] ? { url: '/verify-transaction', label: 'Verify Transaction', logo: null, exact: true } : false,

        /**
         * Closing Transaction Export
         */
        permissions['closing-transaction.export'] ? { url: '/closing-transaction-export', label: 'Export List', logo: null, exact: true } : false,
      ]
    },

    /**
     * Deposit Related
     */
    {
      url: '#', type: 'parent', label: 'Deposit', logo: <AccountBalanceWalletIcon />, children: [
        /**
         * Statement
         */
        permissions['statement.manage'] ? { url: '/statement-deposit', label: 'Statement', logo: null, exact: true } : false,

        /**
         * Verify Closing
         */
        permissions['closing-transaction.list'] ? { url: '/verify-transaction-deposit', label: 'Verify Transaction', logo: null, exact: true } : false,

        /**
         * Closing Transaction Export
         */
        permissions['closing-transaction.export'] ? { url: '/closing-transaction-export-deposit', label: 'Export List', logo: null, exact: true } : false,
      ]
    },

    /**
     * Tech / Digital Marketing
     */
    {
      url: '#', type: 'parent', label: 'Tech / Digital Marketing', logo: <ImportantDevicesIcon />, children: [
        /**
         * Career Page
         */
        permissions['career.manage'] ? { url: '/career', label: 'Career Page', logo: null, exact: true } : false,

        /**
         * Apartment Mapping Page
         */
        permissions['apartment-mapping.manage'] ? { url: '/map-file-form', label: 'Mapping Apartment', logo: null, exact: true } : false,

        /**
         * Listing Article
         */
        permissions['listing.article'] ? { url: '/landing-listing-page', label: 'Listing Landing Article', logo: null } : false,
        permissions['listing.article'] ? { url: '/listing-location-article', label: 'Listing Location Article', logo: null } : false,

        /**
        * Add Landing Page
        */
        permissions['article-landing-page.manage'] ? { url: '/article-landing-page', label: 'Article Landing Page', logo: null } : false,

        /**
         * Location Manage CMS
         */
        permissions['province.manage'] ? { url: '/province', label: 'Manage Province', logo: null } : false,
        permissions['area.manage'] ? { url: '/area', label: 'Manage Area', logo: null } : false,
        permissions['district.manage'] ? { url: '/district', label: 'Manage District', logo: null } : false,

        /**
         * Campaign Setting
         */
        permissions['email-campaign.manage'] ? { url: '/campaign-email', label: 'Campaign Email', logo: null } : false,

        /**
         * Apartment Review
         */
        permissions['apartment-review.manage'] ? { url: '/apartment-review', label: 'Apartment Review', logo: null } : false,

        /**
         * Project Developer
         */
        permissions['project-developer.manage'] ? { url: '/project-developer', label: 'Project Developer', logo: null } : false,
      ]
    },

    /**
     * Keys
     */
    // {
    //   url: '#', type: 'parent', label: 'Unit Key', logo: <VpnKeyIcon />, children: [
    //     { url: '/unit-key-manage', label: 'Manage', logo: null },
    //     { url: '/unit-key-manage/borrow', label: 'Borrow Key', logo: null },
    //     { url: '/unit-key-manage/history', label: 'Check Key Status', logo: null },
    //     { url: '/unit-key-manage/request', label: 'Request Transfer', logo: null },
    //     permissions['unit.key-logs'] && { url: '/unit-key-logs', label: 'Logs', logo: null },
    //     permissions['unit.key-logs'] && { url: '/unit-key/borrow-request', label: 'Manage Key Request', logo: null }
    //   ]
    // },

    /**
     * Tools
     */
    {
      url: '#', type: 'parent', label: 'Tools', logo: <SettingsIcon />, children: [
        /**
         * Unit Download Image
         */
        permissions['unit.access-original-image'] ? { url: '/unit-download-image', label: 'Download Unit Image', logo: null } : false,

        /**
         * Apartment Download Image
         */
        permissions['apartment.access-original-image'] ? { url: '/apartment-download-image', label: 'Download Apartment Image', logo: null } : false,

        /**
         * JendelaPay Simulator
         */
        // { url: '/pay-simulator', label: 'Jendela Pay Simulator', logo: null, exact: true },

        /**
         * Credit Memo
         */
        permissions['credit-memo.list'] ? { url: '/credit-memo', label: 'Credit Memo', logo: null } : false,
        permissions['credit-memo.send'] ? { url: '/credit-memo/add', label: 'Create Credit Memo', logo: null } : false,

        /**
         * Asset Upload
         */
        permissions['asset.upload'] ? { url: '/asset-upload', label: 'Asset Upload', logo: null } : false,

        /**
         * Login Log
         */
        permissions['login.log'] ? { url: '/login-log', label: 'Login Log', logo: null } : false,

        /**
         * Unit Marketplace Log
         */
        //  { url: '/unit-marketplace-log', label: 'Unit Marketplace Log', logo: null, exact: true },

        /**
         * Holiday
         */
        permissions['holiday.manage'] ? { url: '/holiday', label: 'Hari Libur', logo: null } : false,

        /* 
        * Bank Name Verifier
        */
        permissions['bank-name-verifier.manage'] ? { url: '/bank-name-verifier', label: 'Bank Name Verifier', logo: null } : false,

        /* 
        * Download Report Support Supervisor
        */
        permissions['report-support-supervisor.download'] ? { url: '/download-report-support-supervisor', label: 'Download Report Support Supervisor', logo: null } : false,

        /**
         * Download Report General
         */
        permissions['report.download'] ? { url: '/download-report', label: 'Download Report', logo: null } : false,

        /**
         * Priority Unit Homepage
         */
        permissions['sl-order.manage'] ? { url: '/homepage-sl', label: 'Homepage SL Order', logo: null } : false,

        /**
         * Merge Customer menu
         */
        permissions['customer.merge'] ? { url: '/customer-merge', label: 'Merge Tenant Data', logo: null } : false,

        /**
         * Broadcast Qiscus
         */
        permissions['qiscus-broadcast.broadcast'] ? { url: '/broadcast-qiscus', label: 'Broadcast Qiscus', logo: null } : false,

        /**
         * Product Knowledge
         */
        permissions['apartment-product-knowledge.view'] ? { url: '/product-knowledge', label: 'Product Knowledge', logo: null } : false,
        /**
         * Whatsapp Check By Phone
         */
        permissions['whatsapp.check-by-phone'] ? { url: '/whatsapp-checker', label: 'Whatsapp Chat Checker', logo: null } : false,

        /**
         * Bulk Update Extend PIC
         */
        permissions['closing.bulk-change-pic'] ? { url: '/bulk-update-pic', label: 'Bulk Update Extend PIC', logo: null } : false,

        /**
         * Scan KTP
         */
        permissions['ktp.scan'] ? { url: '/scan-ktp', label: 'Scan KTP', logo: null } : false,
      ]
    },

    /**
     * Add Activity from CMS
     */
    permissions['activity.add-from-cms'] ? { url: '/activity/add/mobile', label: 'Activity Add Mobile', logo: <AddIcon />, exact: true } : false,


    /**
     * Enquiry Advisor
     */
    // permissions['enquiry.advisor'] ? { url: '/my-enquiry', label: 'My Enquiry', logo: <ListAltIcon /> } : false,

    /**
     * Search Unit
     */
    permissions['unit-advisor'] ? { url: '/unit-search-agent', label: 'Search Unit', logo: <SearchIcon /> } : false,

    /**
     * Unit Management
     */
    permissions['unit-management.manage'] ? { url: '/unit-management', label: 'Unit Management', logo: <HomeWorkIcon /> } : false,

    /**
     * Portfolio Unit
     */
    // permissions['unit.advisor-portfolio'] ? { url: '/my-unit', label: 'My Unit', logo: <ApartmentIcon /> } : false,

    /**
     * Handover unit
     */
    // permissions['handover.full'] ? { url: '/handover-unit', label: 'Handover Unit', logo: <MarkunreadMailboxIcon /> } : false,

    /**
     * Transport Reimbursement
     */
    permissions['transport-reimburse.see-detail'] ? { url: '/transport-reimbursement', label: 'Transport Reimbursement', logo: <CommuteIcon /> } : false,

    /**
     * Profile Setting
     */
    { url: '/profile-setting', label: 'Profile Setting', logo: <SettingsApplicationsIcon /> },

    // Stay360
    // permissions['stay360.manage']
    //   ? {
    //     url: '#', type: 'parent', label: 'Stay360', logo: <BookIcon />, children: [
    //       { url: '/stay360-property', label: 'Stay360 Property', logo: null },
    //       { url: '/stay360-unit', label: 'Stay360 Unit', logo: null },
    //       permissions['cico.manage'] ? { url: '/stay360-cico', label: 'Stay360 CICO', logo: null } : false,
    //       { url: '/calendar-booking', label: 'Calendar Booking', logo: null },
    //       { url: '/stay360-booking/report', label: 'Booking Report', logo: null },
    //     ]
    //   }
    //   : false,

    // permissions['cico.manage'] && !permissions['stay360.manage'] ? { url: '/stay360-cico', label: 'Stay360 CICO', logo: <EventNoteIcon /> } : false,

    // {
    //   url: '#', type: 'parent', label: 'Listing', logo: <HomeWorkIcon />, children: [
    //     { url: '/listing/list', label: 'Listing List', logo: null },
    //     { url: '/listing/add', label: 'Add Listing', logo: null },
    //   ]
    // },
    // permissions['dashboard.demand-supply'] ? { url: '/demand-supply', label: 'Demand Supply', logo: <DashboardIcon /> } : false,
  ];
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleBackButton = () => {
    if (typeof props.backButton !== 'undefined' && props.backButton !== false) {
      if (props.backButton === true || props.backButton === '') {
        navigate(-1)
      } else {
        navigate(props.backButton);
      }
    }
  }

  const longPressEvent = useLongPress(() => {
    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/test-notification`)
      .then(() => {
        console.log('notif test send')
      })
      .catch(err => {
        generalErrorHandler(err)
        console.log('notif test failed')
      })
  }, () => { });

  const drawer = (
    <div>
      <div
        className={classes.menubarHeaderContainer}
        {...longPressEvent}
      >
        {/* <Typography variant="h3" align="center">
          JD360
        </Typography> */}
        <img src={logo} className={classes.logo} alt="logo" />
      </div>
      {/* <Divider></Divider> */}
      <List>
        {/* {generateLinks(url_links)} */}
        {localStorage.getItem('role') === 'human-resource' ? generateLinks(hr_links) : generateLinks(url_links)}
      </List>
    </div>
  );

  const onLogout = () => {
    // if (process.env.REACT_APP_WEBSOCKET_URL as string !== 'false') {
    //   DefaultEcho.disconnect();
    // }
    unSubscibeNotification()
    localStorage.clear();
    navigate('/login');
  }

  const renderChildComponent = () => {
    if (props.component_name) {
      switch (props.component_name) {
        case 'MyUnitList':
          return <MyUnitList permissions={permissions} search={query} />
      }
    } else {
      return props.children
    }
  }

  return (
    <Root>
      <div className={classes.root}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={showSearch ? resetSearch : (typeof props.backButton !== 'undefined' && props.backButton !== false ? handleBackButton : handleDrawerToggle)}
              className={classes.menuButton}
            >
              {
                showSearch || (typeof props.backButton !== 'undefined' && props.backButton !== false) ?
                  <ArrowBackIcon />
                  :
                  <MenuIcon />
              }
            </IconButton>
            {
              (props.searchable && showSearch) &&
              <IconButton
                color="inherit"
                aria-label="close search"
                edge="start"
                onClick={resetSearch}
                className={classes.closeSearch}
              >
                <CloseIcon />
              </IconButton>
            }
            <Typography variant="h6" noWrap style={{ flexGrow: 1, marginRight: 'auto' }} className={showSearch ? classes.hideTitle : ""}>
              {props.title}
            </Typography>
            {
              (props.searchable && !showSearch) &&
              <IconButton
                color="inherit"
                edge="end"
                // style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setShowSearch(true)
                  searchRefContainer.current!.focus()
                }}
              >
                <SearchIcon />
              </IconButton>
            }
            {
              !showSearch &&
              <>
                <ToDoPopOver />
                <IconButton
                  color="inherit"
                  edge="end"
                  // style={{ marginLeft: !props.searchable ? 'auto' : undefined }}
                  onClick={() => navigate('/notification')}
                >
                  <Badge variant="dot" color="error" overlap="circular" invisible={!isUnread}>
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  color="inherit"
                  edge="end"
                  onClick={onLogout}
                // style={{ marginLeft: 'auto' }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </>
            }

            {
              !!props.searchable &&
              <TextField
                defaultValue=""
                onKeyDown={submitQuery}
                variant="outlined"
                size="small"
                placeholder={props.search_placeholder}
                // className={classes.searchText}
                className={showSearch ? `${classes.searchText} ${classes.searchTextActive}` : classes.searchText}
                InputProps={showClearButton ? {
                  endAdornment: <InputAdornment position="start" style={{ cursor: "pointer", marginRight: "-5px" }} onClick={clearSearch}><CancelIcon></CancelIcon></InputAdornment>,
                } : {}}
                inputRef={searchRefContainer}
                fullWidth
              />
            }
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {
            isMobile ?
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                component={Root}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
              :
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                component={Root}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
          }
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {renderChildComponent()}
        </main>
      </div>
    </Root>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;