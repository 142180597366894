import { Box, Button, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'

/**
 * Components
*/
import GeneralPerformanceCard from './_components/GeneralPerformanceCard'

/**
 * Utils
*/
import { generateStyle } from '../../_utils/DefaultStyle'
import { addMonths, format } from 'date-fns'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, numberToCurrency } from '../../_utils/Helper'


/**
 * Icons
 */
import TotalRevenueIcon from '../../_components/_icons/TotalRevenueIcon'
import TotalNOCIcon from '../../_components/_icons/TotalNOCIcon'
import NewListingIcon from '../../_components/_icons/NewListingIcon'
import ExtendRateIcon from '../../_components/_icons/ExtendRateIcon'
import LoadingScreen from '../../_components/LoadingScreen'
import { PermissionContext } from '../../_contexts/PermissionContext'
import EnquiryBreakdown, { BreakdownItem } from '../_reportScreen/EnquiryBreakdown'

type Props = {
    renderMiddleSection?: React.ReactNode
}

interface IGeneralReport {
    sum_revenue: number,
    sum_revenue_new: number,
    sum_revenue_extend: number,
    total_closing: number,
    total_closing_new: number,
    total_closing_extend: number,
    closing_extend: number,
    closing_expired: number,
    new_listing: number,
    new_submission: number,
    entrusted_key_listing: number,
    enquiry_direct: number,
    enquiry_direct_wa_ads: number,
    enquiry_facebook_ads: number,
    enquiry_instagram: number,
    enquiry_google_ads: number,
    enquiry_seo: number,
    enquiry_tiktok: number,
    enquiry_marketplace: number,
    enquiry_breakdown: BreakdownItem[]
    total_enquiry: number
    last_updated?: string
}

const initialState = {
    sum_revenue: 0,
    sum_revenue_new: 0,
    sum_revenue_extend: 0,
    total_closing: 0,
    total_closing_new: 0,
    total_closing_extend: 0,
    closing_extend: 0,
    closing_expired: 0,
    new_listing: 0,
    new_submission: 0,
    entrusted_key_listing: 0,
    enquiry_direct: 0,
    enquiry_direct_wa_ads: 0,
    enquiry_facebook_ads: 0,
    enquiry_instagram: 0,
    enquiry_google_ads: 0,
    enquiry_seo: 0,
    enquiry_tiktok: 0,
    enquiry_marketplace: 0,
    enquiry_breakdown: [],
    total_enquiry: 0,
    last_updated: ''
}

const useStyles = generateStyle(theme => ({
    title: {
        fontSize: 20,
        fontWeight: '700',
    },
    toolbarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dot: {
        display: 'inline-block',
        width: 11,
        height: 11,
        borderRadius: 6,
        marginRight: 10
    },
    enquiryDetailContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 16
    },
    enquiryDetailCard: {
        paddingLeft: 'auto',
        paddingRight: 'auto'
    },
    separator: {
        borderRight: "1px solid #ababab",
        height: '80%'
    },
    lastUpdated: {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(1)
    }
}), 'GeneralPerformance')


const GeneralPerformance = (props: Props) => {
    const { Root, classes } = useStyles()
    const permissions = useContext(PermissionContext)

    const [month, setMonth] = useState(0)
    const [state, setState] = useState<IGeneralReport>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const generateMonths = useMemo(() => {
        const newDate = new Date()
        return Array.from({ length: 12 }).map((_, index) => ({
            label: `${format(addMonths(newDate, -index), 'MMMM yyyy')}`,
            value: index
        }))
    }, [])

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/general-performance`, {
            params: {
                month
            }
        })
            .then(res => res.data)
            .then(res => {
                setState(res)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleRefresh = () => {
        setIsLoading(true);

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/general-performance/reset-today`)
            .then(() => {
                fetchData();
            })
            .catch((error) => {
                generalErrorHandler(error);
                setIsLoading(false)
            })
        // .finally(() => {
        //     setIsLoading(false);
        // });
    };

    useEffect(() => {
        fetchData()

        // eslint-disable-next-line
    }, [month])

    const getExtendRatePercentage = useMemo(() => {
        if (!state.closing_extend && !state.closing_expired) return 0
        if (!state.closing_expired) return 100
        const result = state.closing_extend / state.closing_expired * 100

        if (result > 100) {
            return 100
        }

        return result % 1 ? result.toFixed(2) : result
    }, [state])

    const renderEnquiryDetail = (title: string, enquiry: number, total: number) => {
        let percentage: string | number = 0
        if (!enquiry || !total) {
            percentage = 0
        } else {
            percentage = (enquiry / total) * 100

            if (percentage % 1) {
                percentage = percentage.toFixed(2)
            }
        }

        return (
            <Box component={'div'} className={classes.enquiryDetailCard}>
                <Typography sx={{ mb: 1 }}>{title}</Typography>
                <Typography>
                    <strong>
                        {percentage}%
                    </strong>
                </Typography>
            </Box>
        )
    }

    const formatLastUpdated = (dateString: string) => {
        if (!dateString) return '-';

        const date = new Date(dateString);
        // untuk mengecek apakah dformat date nya sesuai 
        if (isNaN(date.getTime())) {
            return '-';
        }
        return format(date, 'dd MMMM yyyy HH:mm:ss');
    };

    const separator = <div className={classes.separator}></div>
    const totalEnquiryScoreDetail = useMemo(() => {
        return Object.entries(state)
            .filter(([name, value]) => {
                return name.startsWith('enquiry_') && typeof value !== 'object'
            })
            .map(([name, value]) => value)
            .reduce((prev, curr) => prev + curr, 0)
    }, [state])

    return (
        <>
            <Root>
                <Grid container>
                    <Grid item xs={12} className={classes.toolbarContainer}>
                        <Typography className={classes.title}>Jendela360 Performance</Typography>
                        <Box>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="flex-start"
                            >
                                {permissions["dashboard-type"] === "operation-manager" ?
                                    month === 0 && (
                                        <div style={{ display: 'flex', marginRight: state.last_updated !== undefined ? '-152px' : '0', }} >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleRefresh}
                                                size="medium"
                                                sx={{
                                                    height: 40,
                                                }}
                                            >
                                                Refresh Data
                                            </Button>
                                        </div>
                                    ) : null
                                }

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: state.last_updated !== undefined ? 'flex-end' : 'flex-start',
                                    alignItems: state.last_updated !== undefined ? 'end' : 'start',
                                }}>
                                    <TextField
                                        select
                                        onChange={(e) => setMonth(Number(e.target.value))}
                                        value={month}
                                        size="small"
                                    >
                                        {generateMonths.map((month) => (
                                            <MenuItem key={month.value} value={month.value}>
                                                {month.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {permissions["dashboard-type"] === "operation-manager" && (
                                        <Typography
                                            sx={{
                                                marginTop: 1,
                                                color: 'text.secondary',
                                            }}
                                        >
                                            Last Updated: {formatLastUpdated(state.last_updated ?? '')}
                                        </Typography>
                                    )}
                                </div>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <LoadingScreen open={isLoading} />
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                        <GeneralPerformanceCard
                            details={[
                                { label: 'Revenue New Closing', value: numberToCurrency(state.sum_revenue_new, 'Rp. ') },
                                { label: 'Revenue Extend Closing', value: numberToCurrency(state.sum_revenue_extend, 'Rp. ') }
                            ]}
                            icon={<TotalRevenueIcon />}
                            title='Total Revenue'
                            value={numberToCurrency(state.sum_revenue, 'Rp. ')}
                        />
                    </Grid>
                    <Grid item xs={8 / 3}>
                        <GeneralPerformanceCard
                            details={[
                                { label: 'NoC New Closing', value: state.total_closing_new },
                                { label: 'NoC Extend Closing', value: state.total_closing_extend }
                            ]}
                            icon={<TotalNOCIcon />}
                            title='Total NoC'
                            value={state.total_closing}
                        />
                    </Grid>
                    <Grid item xs={8 / 3}>
                        <GeneralPerformanceCard
                            details={[
                                { label: 'Rental Submission', value: state.new_submission },
                                { label: 'New Key', value: state.entrusted_key_listing }
                            ]}
                            icon={<NewListingIcon />}
                            title='New Listing'
                            value={state.new_listing}
                        />
                    </Grid>
                    <Grid item xs={8 / 3}>
                        <GeneralPerformanceCard
                            details={[
                                {
                                    label: (
                                        <>
                                            <div className={classes.dot} style={{ background: '#FF462D' }}></div>
                                            <span>Expired Tenant</span>
                                        </>
                                    ), value: state.closing_expired
                                },
                                {
                                    label: (
                                        <>
                                            <div className={classes.dot} style={{ background: '#81BC11' }}></div>
                                            <span>Extend Tenant</span>
                                        </>
                                    ), value: state.closing_extend
                                }
                            ]}
                            icon={<ExtendRateIcon />}
                            title='Extend Rate'
                            value={`${getExtendRatePercentage} %`}
                        />
                    </Grid>
                </Grid>
                {
                    typeof props.renderMiddleSection !== 'undefined' ?
                        props.renderMiddleSection :
                        null
                }
                {
                    permissions['report.enquiry-breakdown'] ?
                        <Grid container sx={{ background: '#F6F8F9', mt: 3, p: 3 }}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Enquiry Source</Typography>
                                        <Typography variant='body1'><span style={{ fontWeight: '500' }}>Total Enquiry:</span> {state.total_enquiry}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.enquiryDetailContainer}>
                                        {renderEnquiryDetail('Direct Web', state.enquiry_direct, totalEnquiryScoreDetail)}
                                        {separator}
                                        {renderEnquiryDetail('Direct WA Ads', state.enquiry_direct_wa_ads, totalEnquiryScoreDetail)}
                                        {separator}
                                        {renderEnquiryDetail('Facebook Ads', state.enquiry_facebook_ads, totalEnquiryScoreDetail)}
                                        {separator}
                                        {renderEnquiryDetail('Instagram', state.enquiry_instagram, totalEnquiryScoreDetail)}
                                        {separator}
                                        {renderEnquiryDetail('Google Ads', state.enquiry_google_ads, totalEnquiryScoreDetail)}
                                        {separator}
                                        {renderEnquiryDetail('SEO', state.enquiry_seo, totalEnquiryScoreDetail)}
                                        {separator}
                                        {renderEnquiryDetail('Tiktok', state.enquiry_tiktok, totalEnquiryScoreDetail)}
                                        {separator}
                                        {renderEnquiryDetail('Marketplace', state.enquiry_marketplace, totalEnquiryScoreDetail)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <EnquiryBreakdown
                                    withoutFilter
                                    initialData={state.enquiry_breakdown}
                                />
                            </Grid>
                        </Grid>
                        : null}
            </Root>
        </>
    )
}

export default GeneralPerformance