import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface Props {
    closingId: string;
    orders: OrderJHS[];
}

export interface OrderJHS {
    id: number
    created_at: string
    code: string
    invoice_code: string
    requester_name: string
    paid_by: string
    status_label: string
    verified_at: string
    activity_type_label: string
}

const OrderJHSList = (props: Props) => {
    const renderLogs = () => {
        if (props.orders.length > 0) {
            return props.orders.map((order: OrderJHS, index: number) => {
                return <TableRow key={index}>
                    <TableCell>{order.created_at}</TableCell>
                    <TableCell>
                        <a
                            href={`/jhs-order/${order.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {order.code}
                        </a>
                    </TableCell>
                    <TableCell>{order.activity_type_label || '-'}</TableCell>
                    <TableCell>{order.invoice_code || '-'}</TableCell>
                    <TableCell>{order.requester_name}</TableCell>
                    <TableCell>{order.paid_by || '-'}</TableCell>
                    <TableCell>{order.status_label}</TableCell>
                    <TableCell>{order.verified_at || '-'}</TableCell>
                </TableRow>
            });
        } else {
            return <TableRow>
                <TableCell colSpan={7}>No Order JHS</TableCell>
            </TableRow>
        }
    }

    return (
        <>
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Order Date
                            </TableCell>
                            <TableCell>
                                Code
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Invoice code
                            </TableCell>
                            <TableCell>
                                Requester
                            </TableCell>
                            <TableCell>
                                Paid By
                            </TableCell>
                            <TableCell>
                                Status
                            </TableCell>
                            <TableCell>
                                Done Date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLogs()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default OrderJHSList;