import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { CircularProgress, MenuItem, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { generateStyle } from '../../_utils/DefaultStyle'

interface ListUnitAvailable {
    name: string
    entrusted_key_unit_active: number
    reguler_unit_active: number
    total_unit_active: number
}

const areaNames = [
    "all",
    "jakarta-barat",
    "jakarta-selatan",
    "jakarta-timur",
    "jakarta-utara",
    "jakarta-pusat",
    "tangerang",
    "tangerang-selatan",
    "depok",
    "bekasi"
];
type AreaOption = typeof areaNames[number];

const sortOptions = ['total_unit_active_paling_sedikit', 'total_unit_active_paling_banyak', 'entrusted_key_unit_active_paling_sedikit', 'entrusted_key_unit_active_paling_banyak'];
type SortOption = typeof sortOptions[number];

const AvailableApartmentUnit = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/apartment-unit-info`
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)

    const [sortBy, setSortBy] = useState<SortOption>(sortOptions[0]);
    const [selectedArea, setSelectedArea] = useState<AreaOption>(areaNames[0])
    const [items, setItems] = useState<ListUnitAvailable[]>([])

    const sortedData = useMemo(() => {
        let sortedArray = [...items];

        switch (sortBy) {
            case 'total_unit_active_paling_sedikit':
                sortedArray.sort((a, b) => (a.total_unit_active - b.total_unit_active));
                break;
            case 'total_unit_active_paling_banyak':
                sortedArray.sort((a, b) => (b.total_unit_active - a.total_unit_active));;
                break;
            case 'entrusted_key_unit_active_paling_sedikit':
                sortedArray.sort((a, b) => (a.entrusted_key_unit_active - b.entrusted_key_unit_active));
                break;
            case 'entrusted_key_unit_active_paling_banyak':
                sortedArray.sort((a, b) => (b.entrusted_key_unit_active - a.entrusted_key_unit_active));
                break;
        }
        return sortedArray;
    }, [items, sortBy]);

    const totals = useMemo(() => {
        return sortedData.reduce((acc, item) => ({
            entrusted_key_unit_active: acc.entrusted_key_unit_active + item.entrusted_key_unit_active,
            reguler_unit_active: acc.reguler_unit_active + item.reguler_unit_active,
            total_unit_active: acc.total_unit_active + item.total_unit_active
        }), {
            entrusted_key_unit_active: 0,
            reguler_unit_active: 0,
            total_unit_active: 0
        });
    }, [sortedData]);

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [selectedArea])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(API_URL, {
            params: {
                area_slug: selectedArea === 'all' ? null : selectedArea,
            }
        })
            .then(res => res.data)
            .then(data => {
                setItems(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        borderBottom: 'none',
        padding: theme.spacing(1),
        '&.header': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.action.hover,
        },
        '&.subheader': {
            borderTop: theme.palette.action.hover,
            textAlign: 'right',
        },
        '&.data': {
            textAlign: 'right',
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        '&.dataName': {
            // textAlign: 'right',
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`
        },
    }));

    return (
        <Root>
            <div className={classes.headerContainer}>
                <div className={classes.headerLeft}>
                    <div className={classes.headerFilter}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Sort By"
                            name="sortBy"
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value as SortOption)}
                        >
                            <MenuItem value="total_unit_active_paling_sedikit">Total Unit Paling Sedikit</MenuItem>
                            <MenuItem value="total_unit_active_paling_banyak">Total Unit Paling Banyak</MenuItem>
                            <MenuItem value="entrusted_key_unit_active_paling_sedikit">Titip Kunci Paling Sedikit</MenuItem>
                            <MenuItem value="entrusted_key_unit_active_paling_banyak">Titip Kunci Paling Banyak</MenuItem>
                        </TextField>
                    </div>
                </div>
                <div className={classes.headerRight}>
                    <div className={classes.headerFilter}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Area"
                            name="selectedArea"
                            value={selectedArea}
                            onChange={(e) => setSelectedArea(e.target.value as AreaOption)}
                        >
                            {areaNames.map((area) => (
                                <MenuItem key={area} value={area}>
                                    {area === "all" ? "All" : area.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className="header">Apartment</StyledTableCell>
                            <StyledTableCell className="header" colSpan={3} style={{ textAlign: 'center' }}>Available Unit</StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell className="subheader">Star Listing + Verified</StyledTableCell>
                            <StyledTableCell className="subheader">Regular</StyledTableCell>
                            <StyledTableCell className="subheader">Total</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <StyledTableCell colSpan={4} style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </StyledTableCell>
                            </TableRow>
                        ) : items.length > 0 ? (
                            <>
                                {sortedData.map((item, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell className='dataName'>{item?.name}</StyledTableCell>
                                        <StyledTableCell className="data">{item.entrusted_key_unit_active}</StyledTableCell>
                                        <StyledTableCell className="data">{item.reguler_unit_active}</StyledTableCell>
                                        <StyledTableCell className="data">{item.total_unit_active}</StyledTableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <StyledTableCell style={{ fontWeight: '500' }}>Total</StyledTableCell>
                                    <StyledTableCell className="data" style={{ fontWeight: '500' }}>
                                        {totals.entrusted_key_unit_active}
                                    </StyledTableCell>
                                    <StyledTableCell className="data" style={{ fontWeight: '500' }}>
                                        {totals.reguler_unit_active}
                                    </StyledTableCell>
                                    <StyledTableCell className="data" style={{ fontWeight: '500' }}>
                                        {totals.total_unit_active}
                                    </StyledTableCell>
                                </TableRow>
                            </>
                        ) : (
                            <TableRow>
                                <StyledTableCell colSpan={4} style={{ textAlign: 'center' }}>
                                    Tidak ada data
                                </StyledTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    headerContainer: {
        display: 'flex',
        marginBottom: 20
    },
    headerLeft: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    headerRight: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    headerFilter: {
        maxWidth: '100%',
        width: 240,
        margin: '16px 0px'
    },
}), "AvailableApartmentUnit")

export default AvailableApartmentUnit
