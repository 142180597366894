import React, { useState } from 'react';
import { Theme, Tooltip, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency, generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';
import { Download } from '@mui/icons-material';
import UnitModalDetail from '../_unit/UnitModalDetail';


interface IParams extends Record<string, string | undefined> { code: string | undefined }

const useStyles = generateStyle((theme: Theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    addButton: {
        marginBottom: '20px',
        marginLeft: '10px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    actionContainer: {
        display: 'flex',
        '& > *': {
            marginLeft: 16,
        },
        '& > *:first-of-type': {
            marginLeft: 0,
        }
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}), "ListUnitEntrustedKey"
)

const ListUnitEntrustedKey = () => {
    const [isLoading, setIsLoading] = useState(false);
    const API_URL = `${process.env.REACT_APP_API_URL}/entrusted-unit-key`;
    const params = useParams<IParams>();
    const { Root, classes } = useStyles();
    const [modalState, setModalState] = useState(0);
    const [unitCode, setUnitCode] = useState('')

    const getColorCode = (status: string) => {
        switch (Number(status)) {
            case 0:
                return 'rgba(179, 173, 173, 0.87)';
            case 1:
                return 'black';
            case 2:
                return '#009be5';
            case 3:
                return 'red';
            case 4:
                return 'green';
            default:
                return '';
        }
    }

    const handleDownload = (id: number, code: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/unit/download-image/${id}`, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ORIGINAL_IMAGE_APARTMENT_${code}.zip`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleCloseDetail = () => {
        setModalState(0);
        setUnitCode('')
    };

    const generateColumn = () => {
        /**
         * Available Columns
         */
        const columns = [
            {
                name: 'code',
                label: 'Kode',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}>
                                <span
                                    className={classes.link}
                                    onClick={() => {
                                        setUnitCode(row.code)
                                        setModalState(1)
                                    }}
                                >
                                    {data}
                                </span>
                            </div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>  <span
                                className={classes.link}
                                onClick={() => {
                                    setUnitCode(row.code)
                                    setModalState(1)
                                }}
                            >
                                {data}
                            </span>
                            </div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'apartment_name',
                label: 'Apartment',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'vacant_days',
                label: 'Vacant Days',
                filterable: true,
                type: 'string',
                defaultOption: '',
                render: (data: any) => {
                    return +data === -1 ? '-' : (data === 'rented' ? 'Rented' : data);
                }
            },
            {
                name: 'basic_price_12',
                label: 'Basic Price Tahunan',
                filterable: true,
                sortable: true,
                type: 'number',
                render: (data: string, row: any) => {
                    if ([0, 1].includes(row.status)) {
                        return (<div style={{ color: getColorCode(row.status) }}>Rp. 0 ({numberToCurrency(data, 'Rp')})</div>);
                    } else if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>Rp. 0 ({numberToCurrency(data, 'Rp')})</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{numberToCurrency(data, 'Rp')}</div>);
                    }
                }
            },
            {
                name: 'price_per_12_months',
                label: 'Web Price Tahunan',
                filterable: true,
                sortable: true,
                type: 'number',
                render: (data: string, row: any) => {
                    if ([0, 1].includes(row.status)) {
                        return (<div style={{ color: getColorCode(row.status) }}>Rp. 0 ({numberToCurrency(data, 'Rp')})</div>);
                    } else if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>Rp. 0 ({numberToCurrency(data, 'Rp')})</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{numberToCurrency(data, 'Rp')}</div>);
                    }
                }
            },
            {
                name: 'margin',
                label: 'Margin',
                filterable: true,
                sortable: true,
                type: 'number',
                render: (data: string, row: any) => {
                    if (data === null) {
                        return <div style={{ color: getColorCode(row.status) }}>{data}</div>;
                    }
                    const numValue = Number(data);
                    const formattedData = numValue % 1 === 0 ? numValue.toFixed(0) : numValue.toFixed(2);
                    const displayValue = formattedData + '%';
                    if ([0, 1].includes(row.status)) {
                        return (<div style={{ color: getColorCode(row.status) }}>{displayValue}</div>);
                    } else if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{displayValue}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{displayValue}</div>);
                    }
                }
            },
            {
                name: 'EXTRA',
                label: 'Action',
                sortable: false,
                render: (row: any) => {
                    return (
                        <>
                            <div className={classes.actionContainer}>
                                {
                                    <>
                                        <Tooltip title="Download Image">
                                            <IconButton
                                                color="success"
                                                component="span"
                                                // disabled={!row.name}
                                                onClick={() => handleDownload(row.id, row.code)}
                                            >
                                                <Download fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            </div>
                        </>
                    )
                }
            }
        ];

        return columns
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <UnitModalDetail
                open={modalState === 1}
                unitCode={unitCode}
                onModalClose={handleCloseDetail}
            />
            <DataTable
                url={API_URL}
                columns={generateColumn()}
                // reDraw={reDrawDatatable}
                disableFetch={!!params.code}
                ListUnitEntrustedKey={true}
                filterButtons={[
                    {
                        key: 'filter_type',
                        defaultValue: null,
                        options: [
                            {
                                value: null,
                                label: 'All',
                            },
                            {
                                value: '1',
                                label: 'Star Listing',
                            },
                            {
                                value: '2',
                                label: 'Verified',
                            },
                        ]
                    },
                ]}
            />
        </Root>
    );
}

export default ListUnitEntrustedKey;