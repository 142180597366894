import { Button, ButtonGroup, Grid } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FAQForm from '../../_components/_form/FAQForm'
import { PermissionContext } from '../../_contexts/PermissionContext';
import ArticleBasicInfoForm from './_forms/ArticleBasicInfoForm'

interface IParams extends Record<string, string | undefined> {
    id?: string
    type?: 'basic_info' | 'faq'
}

type FormType = 'basic_info' | 'faq'

const ArticleLandingPageForm = () => {
    const navigate = useNavigate()
    const params = useParams<IParams>()
    const permissions = useContext(PermissionContext)
    const [type, setType] = useState<FormType>(typeof params.type !== 'undefined' ? params.type : 'basic_info')

    useEffect(() => {
        if (!permissions['article-landing-page.manage']) {
            navigate('/article-landing-page')
        } else if (typeof params.type !== 'undefined' && !['basic_info', 'faq'].includes(params.type)) {
            navigate('/article-landing-page')
        }
    }, [permissions, navigate, params.type])

    const renderPage = () => {
        if (params.id) {
            switch (type) {
                case 'basic_info':
                    return (
                        <ArticleBasicInfoForm
                            id={params.id}
                        />
                    )

                case 'faq':
                    return (
                        <FAQForm
                            url={`${process.env.REACT_APP_API_URL}/article-landing-page/${params.id}/faq`}
                            articleLandingPage={true}
                        />
                    )
                default:
                    return null
            }
        } else {
            return (
                <ArticleBasicInfoForm
                    id={params.id}
                />
            )
        }
    }

    const handleNavigation = (route: FormType) => {
        if (route !== type) {
            if (params.id) {
                if (route === 'basic_info') {
                    navigate(`/article-landing-page/edit/${params.id}`);
                } else {
                    navigate(`/article-landing-page/edit/${params.id}/faq`);
                }
                setType(route);
            }
        }
    }

    return (
        <Grid container spacing={3}>
            {
                params.id ?
                    <Grid item xs={12}>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            fullWidth
                        >
                            <Button
                                variant={type === 'basic_info' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('basic_info')}
                            >
                                Basic Info
                            </Button>
                            <Button
                                variant={type === 'faq' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('faq')}
                            >
                                FAQ
                            </Button>

                        </ButtonGroup>
                    </Grid>
                    : null
            }
            {renderPage()}
        </Grid>
    )
}

export default ArticleLandingPageForm
