import React, { useEffect, useState } from 'react'
import { Link as LinkDom } from 'react-router-dom';
import { Button, Grid, IconButton, Theme, Tooltip, Typography } from '@mui/material';

/**
 * Components
 */
import ModalSection from '../../../_components/_modalDetail/ModalSection';
import ModalDetail from '../../../_components/_modalDetail/ModalDetail';
import { IChats } from '../../../_components/WhatsappPreview';
import LoadingScreen from '../../../_components/LoadingScreen';
import Chip from '../../../_components/Chip';
import WhatsappPreviewModal from '../../../_components/_modal/WhatsappPreviewModal';

/**
 * Icon
 */
import DeleteIcon from '../../../_components/_icons/DeleteIcon';
import DescriptionIcon from '@mui/icons-material/Description';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import PhoneIcon from '@mui/icons-material/Phone';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

/**
 * Utils
 */
import { generateStyle } from '../../../_utils/DefaultStyle';
import { dateFormat, generalErrorHandler, numberToCurrency, renderSuccessButton } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';

/**
 * Modals
 */
import DeleteClosingModal from './Modal/DeleteClosingModal';
import VerifyClosingModal from './Modal/VerifyClosingModal';
import CompleteClosingModal from './Modal/CompleteClosingModal';
import NoteModal from './Modal/NoteModal';
import LandlordEditModal from './Modal/LandlordEditModal';
import QiscusTemplateModal from './Modal/QiscusTemplateModal';
import ChangeEnquiryModal from './Modal/ChangeEnquiryModal';

import { IMTO } from './MTOForm';
import Swal from 'sweetalert2';
import { IQiscusData, IUnit } from './ClosingModalDetail';



interface Props {
    open: boolean;
    permissions: any;
    code: string;
    onClose: Function;
}

interface IDetail {
    id: string,
    enquiry_id: number | null,
    enquiry_code: string | null,
    wa_id: number | null,
    old_closing_id: string,
    cobroke_type: number,
    code: string,
    extend_status: number,
    is_sell: number,
    is_star: number,
    customer_id: number,
    agent_id?: number,
    listing_id?: number,
    unit_id: string,
    payment_type: string,
    start_date: string,
    end_date: string,
    duration: number,
    unit: IUnit,
    holded_deposit_owner: number,
    holded_deposit_office: number,
    gmv_transaction: number,
    gmv_financing: string,
    revenue_financing: number,
    cogs_financing: number,
    gmv_property_management?: number,
    revenue_transaction: number,
    discount_revenue: number,
    cobroke_fee: number,
    revenue_jendela: number,
    referral_commission: number,
    payment_per_month_mto: IMTO[],
    notes?: string,
    created_at: string,
    payment_to_financing_service: number,
    status: number,
    handover_check_in_activity_id?: number,
    canceled_reason?: string,
    finished_payment_date?: string,
    dpp_value: number,
    ppn_value: number,
    first_payment_date?: string,
    is_include_maintenance: number,
    basic_price: number,
    stop_edit_label: string,
    termin_code_label: string,
    status_label: string,
    listing_information: string,
    owner_id_label: number,
    owner_name_label: string,
    owner_phone_label: string,
    owner_bank_information_label: string,
    agent_name_label: string,
    agent_phone_label: string,
    agent_office_label: string,
    confirmed_by_label: string,
    confirmed_at_label: string,
    verified_by_label: string,
    verified_at_label: string,
    completed_by_label: string,
    completed_at_label: string,
    canceled_by_label: string,
    canceled_at_label: string,
    cobroke_label: string,
    pic_landlord_id: number,
    pic_landlord_name: string,
    pic_landlord_phone: string,
    extended_closing_code?: string,
    apartment_name?: string,
    transaction_amount: string,
    deposit_amount: number,
    deposit_type: number,
    commission: number,
    old_closing_code?: string,
    owner_price: number,
    customer_price: number
}

const useStyles = generateStyle((theme: Theme) => ({
    cancel_overlay: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        zIndex: 1100,
        marginTop: "64px"
    },
    cancel_card: {
        textAlign: "center",
        width: "50%",
        marginLeft: "25%",
        marginTop: "10%"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    h6_title: {
        color: theme.palette.primary.main,
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        overflow: 'auto',
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
    extendActionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    },
    footer: {
        display: 'flex',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
}), "ClosingModal_Detail"
)

const initialDetailValue = {
    id: "",
    enquiry_code: null,
    wa_id: null,
    old_closing_id: "",
    cobroke_type: 0,
    code: "",
    extend_status: 0,
    is_sell: 0,
    is_star: 0,
    customer_id: 0,
    agent_id: 0,
    listing_id: 0,
    unit_id: "",
    enquiry_id: 0,
    payment_type: "",
    start_date: "",
    end_date: "",
    duration: 0,
    holded_deposit_owner: 0,
    holded_deposit_office: 0,
    gmv_transaction: 0,
    gmv_financing: "",
    revenue_financing: 0,
    cogs_financing: 0,
    gmv_property_management: 0,
    revenue_transaction: 0,
    discount_revenue: 0,
    cobroke_fee: 0,
    unit: {} as IUnit,
    revenue_jendela: 0,
    referral_commission: 0,
    owner_price: 0,
    notes: "",
    created_at: "",
    payment_to_financing_service: 0,
    status: 0,
    handover_check_in_activity_id: 0,
    canceled_reason: "",
    finished_payment_date: "",
    dpp_value: 0,
    ppn_value: 0,
    first_payment_date: "",
    is_include_maintenance: 0,
    basic_price: 0,
    payment_per_month_mto: [] as IMTO[],
    stop_edit_label: "",
    transaction_amount: "",
    termin_code_label: "",
    status_label: "",
    listing_information: "",
    owner_id_label: 0,
    owner_name_label: "",
    owner_phone_label: "",
    owner_bank_information_label: "",
    agent_name_label: "",
    agent_phone_label: "",
    pic_landlord_id: 0,
    pic_landlord_name: "",
    pic_landlord_phone: "",
    agent_office_label: "",
    confirmed_by_label: "",
    confirmed_at_label: "",
    verified_by_label: "",
    verified_at_label: "",
    completed_by_label: "",
    completed_at_label: "",
    canceled_by_label: "",
    canceled_at_label: "",
    cobroke_label: "",
    commission: 0,
    deposit_amount: 0,
    deposit_type: 0,
    customer_price: 0,
}

const ClosingModalDetailSell = (props: Props) => {
    const { permissions, code } = props;
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState<IDetail>(initialDetailValue);
    const [landlordEditModal, setLandlordEditModal] = useState(false)
    const [modalState, setModalState] = useState<string | number>(0);

    const [chats, setChats] = useState<IChats[]>([])
    const [chatCode, setChatCode] = useState<string | null>(null)
    const [qiscusData, setQiscusData] = useState<IQiscusData | null>(null)

    useEffect(() => {
        if (code) {
            fetchDetail();
        }
        // eslint-disable-next-line
    }, [code]);

    const handleNoteModal = () => {
        setModalState(21)
    }

    const handleVerifyClosing = () => {
        setModalState(8)
    }

    const handleCompleteClosing = () => {
        setModalState(12);
    }

    const fetchDetail = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/new-closing/code/${code}`)
            .then(res => {
                setDetail(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleWhatsappPreview = (id: number) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead`, { params: { id: id } })
            .then(res => res.data)
            .then(res => {
                setChats(res)
                setChatCode(detail.enquiry_code || null)
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleWhatsappPreviewOwner = (id: number) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead-owner`, { params: { id: id } })
            .then(res => res.data)
            .then(res => {
                setChats(res)
                setChatCode(detail.enquiry_code || null)
            })
            .catch(err => generalErrorHandler(err))
    }

    const changeCustomerClosing = (closingId: number | string, customerId: number) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/change-customer`, {
            id: closingId,
            customer_id: customerId
        })
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Customer Closing Changed!')
                setModalState(0)
                fetchDetail()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleOpenQiscus = (phone: string, type: string, data_id: string) => {
        setQiscusData({
            phone,
            type,
            data_id,
            closing_id: detail.id
        })
        setModalState(22)
    }

    const renderPhone = (phone: string, type: string, dataId: string) => {
        if (phone && phone !== "-" && phone !== "[UNDEFINED]") {
            return (
                <>
                    HP: {phone}
                    <IconButton href={`tel:${phone}`} size="small" style={{ marginRight: "5px" }}>
                        <PhoneIcon />
                    </IconButton>
                    {
                        type ?
                            <IconButton size='small' onClick={() => handleOpenQiscus(phone, type, dataId)}>
                                <WhatsAppIcon />
                            </IconButton>
                            : null
                    }
                </>
            )
        } else {
            return `HP: -`;
        }
    }

    const getDepositLabel = (type: number) => {
        let deposit_text = '';
        switch (type) {
            case 0:
                deposit_text = 'Full Owner';
                break;
            case 1:
                deposit_text = '50% Jendela 50% Owner';
                break;
            case 2:
                deposit_text = 'Full Jendela';
                break;
            case 3:
                deposit_text = '50% Jendela 50% Agent';
                break;
            case 4:
                deposit_text = 'Full Agent';
                break;
            default:
                deposit_text = 'undefined'
                break;
        }
        return deposit_text;
    }


    const generateBasicInformation = () => {
        let listDetail: Array<any> = [];

        if (detail.old_closing_id !== null && detail.status !== 3) {
            listDetail = listDetail.concat([
                detail.extended_closing_code &&
                ['Extend From', (
                    <LinkDom to={{ pathname: `/closing/${detail.extended_closing_code}` }}>
                        {detail.extended_closing_code}
                    </LinkDom>
                )]
                ,
                detail.old_closing_code &&
                [
                    'Old Closing Code',
                    (
                        <a href={`${process.env.REACT_APP_MAIN_URL}/admin-cms/closing?c=${detail.old_closing_code}`} target="_blank" rel="noopener noreferrer">
                            {detail.old_closing_code}
                        </a>
                    )]
                ,
                [`Jenis Transaksi`, detail.is_sell ? 'Jual' : 'Sewa'],
                [3, 4].includes(detail.cobroke_type) === false && detail.is_sell === 0 && [
                    'Periode Sewa',
                    `${dateFormat(detail.start_date, 'DD MMM YYYY')} - ${dateFormat(detail.end_date, 'DD MMM YYYY')} (${detail.duration} hari)`
                ],
                [
                    (
                        <>
                            Informasi Listing
                            <IconButton>
                                <DescriptionIcon />
                            </IconButton>
                        </>
                    )
                    , detail.listing_information]
            ]);
        }
        if (detail.old_closing_id === null) {
            listDetail = listDetail.concat([
                ...(detail.extended_closing_code ?
                    [[
                        `Extended From`,
                        <a href={`/closing/${detail.extended_closing_code}`} target="_blank" rel="noopener noreferrer">{detail.extended_closing_code}</a>
                    ]] : []),
                [(
                    <>
                        Kode Enquiry
                        {
                            (!permissions['closing.force-change-enquiry'] && (permissions['closing.change-enquiry'] && detail.status !== 4)) ?
                                <IconButton
                                    color='primary'
                                    onClick={() => setModalState(26)}
                                >
                                    <ChangeCircleIcon />
                                </IconButton>
                                : null
                        }
                    </>
                ), <>
                    {detail.enquiry_code}
                    {(permissions['whatsapp.manage'] && detail.wa_id !== null) &&
                        <>
                            <Tooltip title="Whatsapp Client">
                                <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(Number(detail.enquiry_id))} color='primary'>
                                    <WhatsAppIcon />
                                </IconButton>
                            </Tooltip>

                            {detail.unit_id &&
                                <Tooltip title="Whatsapp Owner">
                                    <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreviewOwner(Number(detail.enquiry_id))} color='primary'>
                                        <WhatsAppIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    }
                </>],
                [`Jenis Transaksi`, detail.is_sell ? 'Jual' : 'Sewa'],
                [`Metode Pembayaran`, detail.payment_type],
                typeof detail.apartment_name !== 'undefined' && [`Nama Apartemen`, detail.apartment_name],
                detail.cobroke_type !== 0 && [`Cobroke Type`, detail.cobroke_label],
                [
                    (
                        <>
                            Informasi Listing
                            <IconButton
                                color='primary'
                                onClick={() => handleNoteModal()}
                            >
                                <DescriptionIcon />
                            </IconButton>
                        </>
                    )
                    , detail.listing_information],
                [3, 4].includes(detail.cobroke_type) === false && detail.is_sell === 0 && [`Periode Sewa`, `${dateFormat(detail.start_date, 'DD MMM YYYY')} - ${dateFormat(detail.end_date, 'DD MMM YYYY')} (${detail.duration} hari)`],
                [`Tanggal Pembayaran Pertama`, detail.first_payment_date ? dateFormat(detail.first_payment_date, 'DD MMM YYYY') : "-"],
                [`Tanggal Pelunasan Closing`, detail.finished_payment_date ? dateFormat(detail.finished_payment_date, 'DD MMM YYYY') : "-"],
                [`Kunci sudah di Handover`, detail.handover_check_in_activity_id ? "Ya" : "Tidak"],
            ])
        }
        return listDetail;
    }

    const generateTransactionInformation = () => {
        if (detail.old_closing_id !== null) {
            return [
                ["Transaction Amount", numberToCurrency(detail.transaction_amount, "Rp.")],
                ["Basic Price", `${numberToCurrency(detail.basic_price, "Rp.")} [NOTE: Bila Basic KOSONG Price Kemungkinan Closing menggunakan perhitungan lama (tanpa basic price)]`],
                ["Komisi Amount", numberToCurrency(detail.commission, "Rp.")],
                ["Deposit", `${numberToCurrency(detail.deposit_amount, "Rp.")} (${getDepositLabel(detail.deposit_type)})`],
                detail.is_star !== 0 && ["Owner Price", numberToCurrency(detail.owner_price, "Rp.")],
                detail.is_star !== 0 && ["Customer Price", numberToCurrency(detail.customer_price, "Rp.")],
            ];
        } else {
            let array: Array<any> = [];

            array = array.concat([
                ["Deposit Owner", detail.holded_deposit_owner ? numberToCurrency(detail.holded_deposit_owner, "Rp.") : "-"],
                ["Deposit Jendela", detail.holded_deposit_office ? numberToCurrency(detail.holded_deposit_office, "Rp.") : "-"],
            ]);

            if (detail.payment_type === "cash") {
                array = array.concat([
                    ["GMV Transaction", `${numberToCurrency(detail.gmv_transaction, "Rp.")} (${detail.is_include_maintenance ? "Inc." : "Excl."} Maintenance)`],
                ]);
            } else if (detail.payment_type === "monthly-owner") {
                array = array.concat([
                    [
                        "GMV Transaction",
                        <>
                            {`${numberToCurrency(detail.gmv_transaction, "Rp.")} (${detail.is_include_maintenance ? "Inc." : "Excl."} Maintenance)`}
                            {detail.payment_per_month_mto?.length ? (
                                <ul>
                                    {detail.payment_per_month_mto.map((mto: IMTO, key: number) => (
                                        <li key={key}>
                                            Pembayaran {key + 1}: Tanggal {dateFormat(mto.date, "D MMMM YYYY")} senilai{" "}
                                            {numberToCurrency(mto.transaction_amount, "Rp.")}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p><strong>NOTE: Harap isi Pembayaran per Bulannya</strong></p>
                            )}
                        </>,
                    ],
                ]);
            } else if (["installment-blibli", "installment-modalku", "jendelapay"].includes(detail.payment_type)) {
                array = array.concat([
                    ["GMV Transaction", `${numberToCurrency(detail.gmv_transaction, "Rp.")} (${detail.is_include_maintenance ? "Inc." : "Excl."} Maintenance)`],
                    ["GMV Financing", numberToCurrency(detail.gmv_financing, "Rp.")],
                ]);
            } else {
                array = array.concat([
                    ["GMV Transaction", `${numberToCurrency(detail.gmv_transaction, "Rp.")} (${detail.is_include_maintenance ? "Inc." : "Excl."} Maintenance)`],
                    ["Jumlah Pembayaran Cash", numberToCurrency(detail.gmv_transaction - detail.payment_to_financing_service, "Rp.")],
                    ["Jumlah Pembayaran Financing", numberToCurrency(detail.payment_to_financing_service, "Rp.")],
                    ["GMV Financing", numberToCurrency(detail.gmv_financing, "Rp.")],
                    ["COGS Financing", numberToCurrency(detail.cogs_financing, "Rp.")],
                ]);
            }

            array = array.concat([
                ["Basic Price", numberToCurrency(detail.basic_price, "Rp.")],
            ]);

            if (permissions["closing.detail-type"] === "finance") {
                array = array.concat([
                    ["Revenue Transaksi", numberToCurrency(detail.revenue_transaction, "Rp.")],
                    detail.payment_type !== "cash" && ["Revenue Financing", numberToCurrency(detail.revenue_financing, "Rp.")],
                    detail.cobroke_type !== 0 && ["(-) Cobroke Fee", numberToCurrency(detail.cobroke_fee, "Rp.")],
                    ["Total", numberToCurrency(detail.revenue_jendela, "Rp.")],
                ]);

                if (detail.status === 4) {
                    array = array.concat([
                        ["PPN Komisi Sewa", numberToCurrency(detail.ppn_value, "Rp.")],
                        ["DPP Komisi Sewa", numberToCurrency(detail.dpp_value, "Rp.")],
                    ]);
                }

                array = array.concat([
                    ["Include Maintenance", detail.is_include_maintenance ? <Chip color="primary" label="Yes" /> : <Chip label="No" />],
                ]);
            } else {
                array = array.concat([
                    ["Komisi", numberToCurrency(detail.revenue_jendela, "Rp.")],
                ]);
            }

            if (detail.referral_commission !== 0) {
                array = array.concat([
                    ["Referral Komisi", numberToCurrency(detail.referral_commission, "Rp.")],
                ]);
            }

            const revertButton = permissions["closing.revert-status"] ? (
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ marginLeft: "10px" }}
                    onClick={() => setModalState(17)}
                >
                    Revert
                </Button>
            ) : null;

            array = array.concat([
                detail.status === 1 && ["Status", `${detail.status_label} by ${detail.confirmed_by_label} at ${detail.confirmed_at_label}`],
                detail.status === 2 && ["Status", <>
                    {`${detail.status_label} by ${detail.verified_by_label} at ${detail.verified_at_label}`}
                    {revertButton}
                </>],
                detail.status === 4 && ["Status", <>
                    {`${detail.status_label} by ${detail.completed_by_label} at ${detail.completed_at_label}`}
                    {revertButton}
                </>],
                detail.status === 3 && ["Status", `${detail.status_label} by ${detail.canceled_by_label} at ${detail.canceled_at_label}`],
                detail.status === 3 && ["Canceled Reason", detail.canceled_reason],
            ]);

            return array;
        }
    };


    const generateAgentInformation = () => {
        return (
            <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                <Grid item>
                    <Typography variant="h6" className={classes.h6_title}>
                        Agent Information

                        {permissions['agent.manage'] &&
                            <LinkDom target="_blank" to={`/agent/${detail.agent_id}`} >
                                <IconButton color="primary">
                                    <VisibilityIcon />
                                </IconButton>
                            </LinkDom>
                        }
                        {
                            permissions['agent.manage'] &&
                            <LinkDom target='_blank' to={`/agent/edit/${detail.agent_id}`}>
                                <IconButton color="primary">
                                    <EditIcon />
                                </IconButton>
                            </LinkDom>
                        }
                        {
                            permissions['closing.change-agent'] &&
                            <IconButton color='primary' onClick={() => setModalState(25)}>
                                <SwitchAccountIcon />
                            </IconButton>
                        }
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <WhatsappPreviewModal
                open={!!chats.length}
                chats={chats}
                code={chatCode}
                created_at={detail.created_at}
                onClose={() => {
                    setChats([])
                    setChatCode(null)
                }}
            />

            <ModalDetail
                open={props.open}
                title={`${detail.code} [${detail.agent_id ? 'AGENT' : (detail.cobroke_type === 3 ? 'WINGS' : 'OWNER')}] ${detail.termin_code_label ? `- ${detail.termin_code_label} ` : ""}`}
                appBarProps={{
                    color: detail.status === 3 ? 'secondary' : 'primary',
                }}
                onCloseModal={() => props.onClose()}
                isLoading={isLoading}
                stickyHeader
            >
                <>
                    <Grid item md={6} xs={12}>
                        <ModalSection
                            title="Informasi Dasar"
                            titleContent={
                                (
                                    <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.h6_title}>
                                                Informasi Dasar

                                                {permissions['closing.delete'] &&
                                                    <IconButton onClick={() => setModalState(24)} color="secondary" style={{ width: 40, height: 40 }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            content={generateBasicInformation()}
                        />

                        <ModalSection
                            title="Informasi Transaksi"
                            content={generateTransactionInformation()}
                            additionalContent={(
                                <>
                                    {(permissions['closing.verify'] && (detail.status === 0)) &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={handleVerifyClosing}
                                        >
                                            Verify Closing
                                        </Button>
                                    }

                                    {(permissions['closing.complete'] && (detail.status === 2)) &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={handleCompleteClosing}
                                        >
                                            Complete Closing
                                        </Button>
                                    }
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ModalSection
                            title={`${[0, 2].includes(detail.cobroke_type) === false ? 'Agent' : 'Owner'} Information`}
                            titleContent={[0, 2].includes(detail.cobroke_type) ?
                                (
                                    <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.h6_title}>
                                                Owner Information

                                                {(permissions['landlord.manage'] || permissions['landlord.admin']) &&
                                                    <LinkDom target="_blank" to={`/landlord/${detail.owner_id_label}`} >
                                                        <IconButton color="primary">
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </LinkDom>
                                                }
                                                {(permissions['landlord.edit'] || permissions['landlord.admin']) &&
                                                    <IconButton color="primary" onClick={() => setLandlordEditModal(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                                :
                                generateAgentInformation()
                            }
                            content={[0, 2].includes(detail.cobroke_type) === false ?
                                [
                                    [`Name`, detail.agent_name_label],
                                    [`Phone`, renderPhone(detail.agent_phone_label, 'agent', detail.agent_id?.toString() || '')],
                                    [`Kantor Agency`, detail.agent_office_label]
                                ]
                                :
                                [
                                    ...((detail.cobroke_type !== 3 && detail.unit.is_owner) ?
                                        [[
                                            <>
                                                <span>PIC Owner Information</span>
                                                {
                                                    permissions['pic-landlord.show'] && detail.pic_landlord_id ?
                                                        <IconButton
                                                            title='Show PIC Detail Information'
                                                            onClick={() => window.open(`/pic-landlord/${detail.pic_landlord_id}`)}
                                                        >
                                                            <VisibilityIcon color='primary' />
                                                        </IconButton>
                                                        : null
                                                }
                                            </>,
                                            detail.pic_landlord_name && detail.pic_landlord_phone ?
                                                <>
                                                    <span>{detail.pic_landlord_name}</span> - <span>{renderPhone(detail.pic_landlord_phone, 'pic_landlord', detail.pic_landlord_id?.toString() || '')}</span>
                                                </> :
                                                '-'
                                        ]] : []
                                    ),
                                    [`Ownership Name`, detail.owner_name_label],
                                    [`Bank Info`, detail.owner_bank_information_label ? detail.owner_bank_information_label : "-"]
                                ]
                            }
                        />
                    </Grid>
                </>
            </ModalDetail>

            <LandlordEditModal
                open={landlordEditModal}
                id={detail.owner_id_label}
                onClose={(fetch) => {
                    setLandlordEditModal(false)
                    if (fetch) {
                        fetchDetail()
                    }
                }}
            />

            <VerifyClosingModal
                open={modalState === 8}
                closingId={detail.id}
                handleClose={() => {
                    setModalState(0)
                    fetchDetail();
                }}
            />
            <CompleteClosingModal
                open={modalState === 12}
                closingId={detail.id}
                handleClose={() => {
                    setModalState(0)
                    fetchDetail();
                }}
            />

            <QiscusTemplateModal
                open={modalState === 22 && qiscusData !== null}
                onClose={() => setModalState(0)}
                onSubmitSuccess={() => [setModalState(0), setQiscusData(null)]}
                data={qiscusData}

            />

            <ChangeEnquiryModal
                id={detail.id}
                onClose={() => setModalState(0)}
                open={modalState === 26}
                onSubmitSuccess={() => {
                    setModalState(0)
                    fetchDetail()
                }}
                onEnquiryExistCallback={(data) => {
                    Swal.fire({
                        title: 'Data tenant ditemukan!',
                        icon: 'warning',
                        html: `
                        Closing : ${data.closing_code} <br />
                        Nama : ${data.customer_name} <br /></br />
                        Lanjutkan memindahkan data enquiry? <br /></br>
                        <b>(NOTE: Data customer akan terhubung dengan closing ${data.closing_code})</b>`,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ya'
                    })
                        .then(res => {
                            if (res.value) {
                                changeCustomerClosing(detail.id, data.customer_id)
                            }
                        })
                }}
            />

            {detail.cobroke_type !== 3 &&
                <NoteModal
                    open={modalState === 21}
                    onClose={(refresh) => {
                        setModalState(0)
                        if (refresh) {
                            fetchDetail()
                        }
                    }}
                    id={detail.unit.apartment_id}
                    name={detail.unit.apartment_name}
                />
            }

            <DeleteClosingModal
                open={modalState === 24}
                code={detail.code}
                id={detail.id}
                onClose={() => setModalState(0)}
                onSubmitSuccess={() => {
                    setModalState(0)
                    window.location.href = '/closing'
                }}
            />
        </Root>
    )
}

export default ClosingModalDetailSell