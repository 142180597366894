import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { reasonCheckoutList } from './HandOverCheckOutModal'
import PhotoPreview from '../../../../_components/PhotoPreview'
import LoadingScreen from '../../../../_components/LoadingScreen'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderErrorButton, renderSuccessButton } from '../../../../_utils/Helper'
import { validateData } from '../../../../_utils/Validation'

type Props = {
    closingId: string
    open: boolean
    onClose: (refresh?: boolean) => void
}

const initialState = {
    checkout_reason: '',
    checkout_proof_file: null
}

interface IState {
    checkout_reason: string,
    checkout_proof_file: File | null
}

const CheckoutReasonModal = (props: Props) => {
    const [state, setState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: string = target.value;
        let name = target.name;
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files, name } = e.target
        if (files && files.length) {
            setState(prev => ({
                ...prev,
                [name]: files[0] as File
            }))
        }
    }

    const handleSubmit = () => {
        const {isValid} = validateData(state, {checkout_reason: 'required', checkout_proof_file: 'required'})
        if (!isValid) {
            renderErrorButton('Form Alasan dan Bukti Wajib Diisi!')
            return false
        }
        
        const formData = new FormData()
        formData.append('id', props.closingId)
        formData.append('checkout_reason', state.checkout_reason || "")
        formData.append('checkout_proof_file', state.checkout_proof_file || "")
        
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/edit-checkout-reason`, formData)
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Checkout Reason Changed!')
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
                props.onClose(true)
            })
    }

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() =>props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} />
            <DialogTitle>Change Checkout Reason</DialogTitle>
            <DialogContent>
                <Grid container justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            id="select"
                            label="Alasan Checkout"
                            name="checkout_reason"
                            value={state.checkout_reason}
                            onChange={handleChanged}
                            variant="outlined"
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                            fullWidth
                            select
                        >
                            <MenuItem value="" disabled>-- Pilih Alasan Checkout --</MenuItem>
                            {
                                reasonCheckoutList.map(reason => (
                                    <MenuItem key={reason.value} value={reason.value}>{reason.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <p><strong>Bukti Checkout</strong></p>
                        {
                            (state.checkout_proof_file instanceof File) ?
                                <PhotoPreview
                                    style={{ marginTop: 10 }}
                                    index={0}
                                    onRemoveFile={() => setState(prev => ({ ...prev, checkout_proof_file: null }))}
                                    src={URL.createObjectURL(state.checkout_proof_file)}
                                />
                                : <input style={{ marginTop: 10 }} type='file' accept='image/*' name="checkout_proof_file" onChange={handleChangeFile} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button sx={{float: 'right'}} variant='contained' onClick={handleSubmit}>Submit</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CheckoutReasonModal