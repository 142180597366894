import { Button, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, styled, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderErrorButton, renderSuccessButton, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';

// Icons
import EditIcon from '@mui/icons-material/Edit';

import moment from 'moment';
import ChangePICModal from '../_listingSubmissionApartment/_components/ChangePICModal';
import GeneralFormModal from '../../_components/_modal/GeneralFormModal';

interface IProps {
    isDeposit?: boolean;
}
export interface StatementFormState {
    id: number
    is_other: 0 | 1
    type: string
    date: string
    trailer: string
    amount: string
    description: string
    closing_id: string
    closing_label: string
    reason: string
    transaction_type: string
}

const StyledSelect = styled(Select)(({ theme }) => ({
    minWidth: '130px', // Adjust this value as needed
    '& .MuiSelect-select': {
        paddingRight: '32px', // Ensures consistent padding for all options
    },
}));

const LeadListingFacebook = (props: IProps) => {
    const API_URL = process.env.REACT_APP_API_URL + '/lead-listing-facebook/import';
    const importRef = useRef<null | HTMLInputElement>(null)

    const [modalState, setModalState] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [picListingState, setPICListingState] = useState({
        id: 0,
        pic_id: 0,
        name: "",
        notes: ''
    })


    const submitChangeStatus = (
        type: number,
        name: string,
        row: any,
        columnName: string,
        options: any[],
        setRows: Function,
        rows: any[]
    ) => {
        const payload = {
            id: row.id,
            status: type,
        };

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead-listing-facebook/status`, payload)
            .then((response) => {
                // Update rows dengan status baru
                const updatedRows = rows.map((r) => (r.id === row.id ? { ...r, [columnName]: type } : r));
                setRows(updatedRows); // Set state dengan rows yang diperbarui
                renderSuccessButton('Status updated successfully'); // Tampilkan pesan sukses
            })
            .catch((error) => {
                generalErrorHandler(error); // Tangani kesalahan
            });
    };

    const handleChangeStatus = (
        name: string,
        row: any,
        columnName: string,
        options: any[],
        setRows: Function,
        rows: any[]
    ) => (event: SelectChangeEvent<any>) => {
        const type = parseInt(event.target.value, 10);
        let text = ' | Do you want to proceed?';
        let needConfirmation = true;

        switch (type) {
            case 0:
                text = 'Changing to On process' + text;
                break;
            case 1:
                text = 'Changing to Done' + text;
                needConfirmation = true; // perlu konfirmasi untuk Done
                break;
            case 2:
                text = 'Changing to Failed' + text;
                needConfirmation = true; // perlu konfirmasi untuk Failed
                break;
            default:
                text = 'undefined';
                break;
        }

        // Jika perlu konfirmasi, tampilkan dialog konfirmasi
        if (needConfirmation) {
            renderWarningButton(text).then((res) => {
                if (res.value) {
                    submitChangeStatus(type, name, row, columnName, options, setRows, rows);
                }
            });
        } else {
            // Jika tidak perlu konfirmasi, langsung kirim perubahan status
            submitChangeStatus(type, name, row, columnName, options, setRows, rows);
        }
    };

    const handleSubmit = async (state: any, onFinal: any) => {
        try {
            setIsLoading(true);

            const result = await renderWarningButton(`Anda akan merubah notes ini. Lanjutkan?`);

            if (result.value) {
                await DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead-listing-facebook/notes`, {
                    id: picListingState.id,
                    notes: state?.notes, // Menggunakan 'notes' sesuai dengan nama field di form
                });

                setReDrawDatatable(prev => prev + 1);
                renderToastSuccess('Berhasil merubah notes.');
                setModalState(0);
            }
        } catch (error: any) {
            if (error.response?.status === 404) {
                renderErrorButton("Mohon isi dengan benar");
            } else {
                generalErrorHandler(error);
            }
        } finally {
            setIsLoading(false);
            onFinal();
        }
    };

    const columns = [
        {
            name: 'datetime',
            label: 'Date',
            type: 'date',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    `${moment(data).format("DD MMM YYYY")}`
                )
            }
        },
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true
        },
        {
            name: 'notes',
            label: "Notes",
            type: 'string',
            filterable: false,
            render: (data: string, row: any) => (
                <>
                    {data || '-'}
                    <>
                        <Tooltip title="Change Notes">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    setPICListingState({
                                        id: row.id,
                                        name: row.pic_name,
                                        pic_id: row.pic_id,
                                        notes: row.notes
                                    })
                                    setModalState(1)
                                }}
                                size="small"
                            >
                                <EditIcon style={{ fontSize: "inherit" }} />
                            </IconButton>
                        </Tooltip>
                    </>
                </>
            )
        },
        {
            name: 'pic_name',
            label: 'PIC',
            filterable: true,
            sortable: true,
            width: "200",
            render: (data: string, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return (
                    <>
                        {data === null ? "-" : data}
                        <>
                            <Tooltip title="Change PIC Listing">
                                <IconButton color="primary" onClick={() => {
                                    setPICListingState({
                                        id: row.id,
                                        pic_id: row.pic_id,
                                        name: row.pic_name,
                                        notes: row.notes,
                                    })
                                    setModalState(3)
                                }} size="small">
                                    <EditIcon style={{ fontSize: "inherit" }} />
                                </IconButton>
                            </Tooltip>
                        </>
                    </>
                )
            }
        },
        {
            name: 'status',
            label: 'Status',
            type: 'string',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All',
                },
                {
                    key: 0,
                    value: 'On process',
                },
                {
                    key: 1,
                    value: 'Done',
                },
                {
                    key: 2,
                    value: 'Failed',
                },
            ],
            render: (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
                return (
                    <FormControl>
                        <StyledSelect
                            value={row[columnName]}
                            onChange={handleChangeStatus(name, row, columnName, options, setRows, rows)}
                        >
                            <MenuItem value={0}>On Process</MenuItem>
                            <MenuItem value={1}>Done</MenuItem>
                            <MenuItem value={2}>Failed</MenuItem>
                        </StyledSelect>
                    </FormControl>
                )
            },
        }
    ];

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target

        if (files && files[0]) {
            setIsLoading(true)

            const fd = new FormData()
            fd.append('file', files[0])

            DefaultAxios.post(`${API_URL}`, fd)
                .then(res => res.data)
                .then(data => {
                    renderToastSuccess('Berhasil import data')
                    setReDrawDatatable(prev => prev + 1)
                })
                .catch((err: any) => {
                    generalErrorHandler(err, { render400ErrorList: true })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            renderErrorButton('Belum ada file yang terpilih')
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    importRef.current?.click()
                }}
                style={{ float: 'right' }}
                sx={{ mr: 1 }}
            >
                Import
            </Button>
            <input
                ref={importRef}
                type="file"
                accept='.csv'
                name='importFile'
                onChange={handleFile}
                style={{ width: 0, height: 0, display: 'none' }}
            />
            <br /><br />
            <div style={{ marginBottom: 15 }} />
            <DataTable
                url={process.env.REACT_APP_API_URL + '/lead-listing-facebook'}
                columns={columns}
                reDraw={reDrawDatatable}
            />
            <GeneralFormModal
                title='Change Notes'
                key={picListingState.id}
                open={modalState === 1}
                onClose={() => setModalState(0)}
                onSubmit={(state, onFinal) => handleSubmit(state, onFinal)}
                forms={[
                    {
                        name: 'notes',
                        type: 'textArea',
                        defaultValue: picListingState.notes,
                        label: 'Notes',
                        required: true,
                        alias: 'Notes',
                    },
                ]}
            />
            <ChangePICModal
                open={modalState === 3}
                id={picListingState.id}
                initialPICName={picListingState?.name}
                onClose={(refresh: boolean) => {
                    setModalState(0);
                }}
                onFinished={() => { setReDrawDatatable(prev => prev + 1) }}
                leadFacebook={true}
            />
        </>
    );
}

export default LeadListingFacebook;

